/* eslint-disable default-case */
import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col, Card, CardBody } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, Edit, Toolbar, ToolbarItems } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { HtmlEditor, Image, Inject, Count, Link, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { Context } from '../../helpers/Context';

export class FAQs extends Component {
    static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.fullToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', '|', 'CreateLink', 'Image', '|', 'ClearFormat',
        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
      ],
      type: 'Expand'
    };

    this.simpleToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', '|', 'ClearFormat', 'Undo', 'Redo', '|', 'SourceCode'],
      type: 'Expand'
    };


    this.state = {
      editData: [], ParentList: [], FilterHeadingId: 0, loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      Title: '',
      Copy: '',
      ParentId: 0,
      Type: 1,
      DisplayOrder: 0,
      DateAdded: new Date(),
      AddedBy: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,

    };
    this.toggle = this.toggle.bind(this);
    this.renderDataTable = this.renderDataTable.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "FAQs Administration";
    this.loadData();
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id === id });
      console.log(data);
      this.setState({ Id: data.id, Title: data.title, Copy: data.copy, ParentId: data.parentId, DateAdded: data.dateAdded, AddedBy: data.addedBy, DateModified: data.dateModified, ModifiedBy: data.modifiedBy, Status: data.status, });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Title: '',
        Copy: '',
        ParentId: 0,
        DisplayOrder: 0,
        DateAdded: new Date(),
        AddedBy: getUserDetails().id,
        DateModified: new Date(),
        ModifiedBy: getUserDetails().id,
        Status: 0,
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;

    valid &= fieldRequired(this.state.Title, "errTitle", "* Required");

    if (valid) {
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    console.log(args)
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  renderDataTable(data, type, gridCommands, gridToolbar, commandClick) {

    let FilteredData = data.filter(c => c.type === type);

    if (type > 1)
      FilteredData = FilteredData.filter(c => c.parentId === this.state.FilterHeadingId);

    const HeadingFilterData = [{ id: 0, title: "No Heading" }, ...data.filter(c => c.type === 1)]
    return (
      <>
        <Card className='my-3'>
          <CardBody>
            <div className="row">
              <div className="col-md-6">
                <label>Filter by Type</label>
                <select className='form-control form-control-sm' defaultValue={type} onChange={ec => this.setState({ Type: parseInt(ec.target.value), FilterHeadingId: 0 })}>
                  <option value="1" >Headings</option>
                  <option value="2" >Questions</option>
                </select>
              </div>
              <div className="col-md-6" hidden={type === 1}>
                <label htmlFor="">Filter By Heading</label>
                <DropDownListComponent dataSource={HeadingFilterData} fields={{ value: "id", text: "title" }} className='form-control' value={this.state.FilterHeadingId} select={e => this.setState({ FilterHeadingId: e.itemData.id, ParentId: e.itemData.id })} />
              </div>
            </div>
          </CardBody>
        </Card>

        {type === 1 ?
          <GridComponent dataSource={FilteredData} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
            <ColumnsDirective>
              <ColumnDirective field='Title' width='100' headerText="Title" />
              <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
            </ColumnsDirective>
            <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
          </GridComponent>
          :
          <GridComponent dataSource={FilteredData} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
            <ColumnsDirective>
              <ColumnDirective field='Title' width='100' headerText="Title" />
              <ColumnDirective width='100' headerText="Heading" valueAccessor={(field, colData, column) => FAQs.GetHeading(data, colData)} />
              <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
            </ColumnsDirective>
            <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
          </GridComponent>
        }
      </>
    );
  }

  static GetHeading(data, colData) {

    if (colData.parentId > 0)
      return data.find(c => c.id === colData.parentId).title;
    else
      return "No Heading";
  }

    render() {
        const { handleNavigate } = this.context;
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(this.state.editData, this.state.Type, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);

    return (
      <>
        <div className="container">
          <Row>
            <Col xs={6}>
              <h1>FAQs Administration</h1>
            </Col>
                    <Col xs={6} className="text-end align-self-center">
                        <Button className="mx-2" color="dark" outline size="sm" data-recordid="0" onClick={e => handleNavigate(-1)}><i className="fas fa-chevron-left mr-2"></i> Back To Admin</Button>
              <Button color="primary" size="sm" data-recordid="0" onClick={this.editItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit FAQs</ModalHeader>
          <ModalBody>
            <div className='mb-3'>
              <Label for='tbTitle'>Title</Label>
              {/* <RichTextEditorComponent id='tbTitle' name='tbTitle' height={100} placeholder='Title' toolbarSettings={this.simpleToolbarSettings} showCharCount={true} value={this.state.Title} change={e => this.setState({ Title: e.value })} >
                <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Count]} />
              </RichTextEditorComponent><div id='tbTitleError' className='error-message' /> */}

              <Input type='text' value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} className='form-control form-control-sm' />
              <div id="errTitle" className='ErrorText' />
            </div>

            <div className='mb-3' hidden={this.state.Type === 1}>
              <Label >Heading</Label>
              <DropDownListComponent dataSource={this.state.ParentList} fields={{ value: "id", text: "title" }} className='form-control' value={this.state.ParentId} select={e => this.setState({ ParentId: e.itemData.id })} />
            </div>
            <div className='mb-3'>
              <Label for='tbCopy'>Copy</Label>
              <RichTextEditorComponent id='tbCopy' name='tbCopy' height={500} placeholder='Copy' toolbarSettings={this.fullToolbarSettings} showCharCount={true} value={this.state.Copy} change={e => this.setState({ Copy: e.value })} >
                <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Count]} />
              </RichTextEditorComponent><div id='tbCopyError' className='error-message' />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/faqs', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();

        const ParentList = [{ id: 0, title: "- None -" }, ...data.filter(c => c.type === 1)];
        this.setState({ editData: data, ParentList, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Title: this.state.Title, Copy: this.state.Copy, ParentId: this.state.ParentId, Type: this.state.Type, DisplayOrder: this.state.DisplayOrder, DateAdded: this.state.DateAdded, AddedBy: this.state.AddedBy, DateModified: this.state.DateModified, ModifiedBy: this.state.ModifiedBy, Status: this.state.Status, }

    try {
      const response = await fetch('api/faqs', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'FAQs', content: 'The faqs was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
        else
          ToastUtility.show({
            title: 'FAQs', content: 'There was an error saving faqs!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
          });
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'FAQs', content: 'There was an error saving the faqs!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/faqs/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'FAQs', content: 'The faqs was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
        ToastUtility.show({
          title: 'FAQs', content: 'There was an error deleting the faqs!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'FAQs', content: 'There was an error deleting the faqs!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

