import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { fieldRequired } from '../helpers/validation';
import { setAuthToken, setUserDetails } from '../helpers/authentication';
import ThrobbleHelper from '../helpers/ThrobbleHelper';
import { showToast } from '../helpers/ToastHelper';
import { setIsAdmin } from '../helpers/Lookup';
import { Context } from '../helpers/Context';
//import { setIsPCLogin } from '../helpers/Lookup';

export class Login extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            Email: '',
            Password: '',
            Checked: false,
        };
        this.Login = this.Login.bind(this);
    }

    componentDidMount() {
        document.title = "TriggerCraft :: Login";
    }

    render() {
        return (
            <div className='container mt-5 '>
                <div className="row">
                    <div className="col-md-6 mx-auto">
                        <div className="mb-5">
                            <img src="/assets/imgs/Trigger-Craft-Logo.png" className="img-fluid w-100" alt="Trigger-Craft Logo" />
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h3 className='mb=3 text-uppercase'>Login</h3>

                                <div className="mb-3">

                                    <label>Email
                                        <span className="required-icon">*</span>
                                        <span id="errEmail" className="required-icon" />
                                    </label>

                                    <input type='text' className='form-control form-control-sm' placeholder='Email' onChange={e => this.setState({ Email: e.target.value }, () => fieldRequired(this.state.Email, "errEmail", "required"))} />
                                </div>

                                <label>Password
                                    <span className="required-icon">*</span>
                                    <span id="errPassword" className="required-icon" />
                                </label>

                                <div className="input-group mb-3">
                                    <input type={this.state.Checked ? 'text' : 'password'} className='form-control form-control-sm' aria-describedby="basic-addon" placeholder='Password' onChange={e => this.setState({ Password: e.target.value }, () => fieldRequired(this.state.Password, "errPassword", "required"))} />
                                    <span className="input-group-text bg-dark text-light" style={{ cursor: "pointer" }} onClick={() => { this.setState({ Checked: !this.state.Checked }) }} id="basic-addon">{this.state.Checked ? <i className="fas fa-eye-slash mx-0" title="Hide password"></i> : <i className="fas fa-eye mx-0" title="Show password"></i>}</span>
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <Button color='dark' size='sm' outline onClick={this.Login}>Login &nbsp;{!this.state.loading ? <i className="fas fa-chevron-right ms-2"></i> : <i className='fas fa-spinner fa-spin me-2'></i>}</Button>
                                </div>
                                {/*<hr />*/}
                                <div className="d-flex justify-content-between">
                                    <small className="align-self-end login">
                                        No account? <Link to="/register">Register</Link>
                                    </small>
                                    <small className="align-self-start login">
                                        <Link to="/forgot-password">Forgot password? </Link>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ThrobbleHelper />
            </div>
        );
    }

    async Login() {

        let isValid = true;
        isValid &= fieldRequired(this.state.Email, "errEmail", "required");
        isValid &= fieldRequired(this.state.Password, "errPassword", "required");
        const { getCart, postToCart, cart } = this.context;
        //ThrobbleHelper.toggleThrobble(true, "Logging in")
        if (isValid) {
            this.setState({ loading: true });

            try {

                const response = await fetch(`api/login/UserLogin?username=${this.state.Email}&password=${this.state.Password}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setAuthToken(data.item1, data.item2);
                    setIsAdmin(data.item3);
                    setUserDetails(data.item4);
                    const loadCart = await getCart(data.item4.id);
                    showToast("success", "Login", "Login is successful");
                    this.setState({ loading: false });
                    
                    if (loadCart.id == 0 && cart.cartItems.length) {
                        cart.userId = data.item4.id;
                        postToCart(cart);
                        localStorage.setItem('cart', JSON.stringify(cart));
                        //console.log("first one ", cart, cart.cartItems);
                    } else if (loadCart.id && cart.cartItems) {
                        //loadCart.cartItems = [...loadCart.cartItems, ...cart.cartItems]
                        loadCart.amount = loadCart.amount + cart.amount;
                        loadCart.numItems = loadCart.numItems + cart.numItems;
                        postToCart(loadCart);
                        //console.log("second one ", loadCart, [...loadCart.cartItems, ...cart.cartItems]);
                        localStorage.setItem('cart', JSON.stringify(loadCart));
                    } else if (loadCart.id && !cart.cartItems) {
                        localStorage.setItem('cart', JSON.stringify(loadCart));
                        //console.log("last one ", loadCart);
                    }
                    //console.log(loadCart, cart, cart.cartItems);
                    //console.log(cart, data.item4.id)
                    if (data.item3) {
                        window.location.href = "/admin";
                    } else {
                        window.location.href = "/";
                    }
                }
                else {
                    this.setState({ loading: false });
                    if (response.status === 400)
                        showToast("info", "Login", "Your account has been locked out");
                    else
                        showToast("error", "Login", "Email/Password is invalid");
                }
            } catch (e) {
                this.setState({ loading: false });
                console.error(e);
                showToast("error", "Login", "Email/Password is invalid");
            }

        }
    }
}