import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FixedMessenger from './FixedMessenger';
import { getCurrentDomain } from '../helpers/Lookup';

export class AboutUs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            PageContent: null,
            Banners: [],
            loading: true
        }
    }

    componentDidMount() {
        document.title = "About Us :: TriggerCraft";

        this.loadData();
    }

    async loadData() {

        try {
            const response = await fetch('api/pagecontent/LoadPageContent/about-us', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const PageContent = await response.json();
                this.setState({ PageContent, loading: false });

            } else {
                console.log(response.status + ":", response.statusText);
                this.setState({ loading: false });
            }
        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
        }

        // try {
        //     const bannerResponse = await fetch("api/banners/GetBannersForPage/about", {
        //         method: 'GET',
        //         withCredentials: true,
        //         credentials: 'include',
        //         headers: {
        //             'Content-Type': 'application/json'
        //         }
        //     });

        //     if (bannerResponse.ok) {
        //         const Banners = await bannerResponse.json();
        //         this.setState({ Banners, })
        //     }
        // } catch (e) {
        //     console.error(e);
        //     // this.setState({ loading: false });
        // }

    }

    RenderPageContent(Content) {
        console.log(Content);
        return (
            <div>
                <h1 className='text-uppercase'>{Content.title}</h1>
                <div className="mb-3" hidden={!Content.banners || Content.banners === ""}>
                    <img src={Content.banners ? (getCurrentDomain() + Content.banners) : "/placeholder"} className='img-fluid w-100' alt="No Banner" />
                </div>
                <div dangerouslySetInnerHTML={{ __html: Content.copy }}></div>
            </div>
        )
    }

    render() {

        const content = this.state.loading ? <em> Loading...</em> : this.RenderPageContent(this.state.PageContent);
        return (
            <>
                <nav className="mb-3 crumbs">
                    <Link to="/" className="breadcrumb-active"><small>Home</small>
                    </Link>
                    <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
                    <small className="breadcrumb-not-active">About Us</small>
                </nav>
                <div className="container">
                    <div className="row">
                        <div className="col-12 mx-auto">
                            {content}
                        </div>
                    </div>

                    {/*  {/* <div className="mb-5">
                        <img src="/assets/imgs/Trigger-Craft-Logo.png" className="img-fluid w-100" alt="Trigger-Craft Logo" />
                    </div>
                    <p className="text-center">
                        Welcome to TriggerCraft, your new favourite South African electronics supplier. Our mission is to bring you the latest and greatest in tech gear, to suit all of your gifting, gaming and lifestyle needs. We understand the importance of value, and that's why our pricing is designed to be friendly to your budget. Whenever you need us, we're just an email away, ready to service you with a smile.
                        At TriggerCraft, our team consists of industry veterans who share a common goal: to provide our customers with top-notch products in a variety of shapes and sizes, all at unbeatable value. We stand behind our offerings with warranties and an exceptional post-purchase support team.
                        Having successfully operated through various online retailers for the past few years, we've honed our expertise in sourcing and importing the finest audio equipment, gaming peripherals, office accessories, and a wide array of other cool gadgets that we know you'll love.
                        Now, we're taking it a step further by delivering the best products directly from our warehouse to your doorstep, ensuring a personalized and seamless shopping experience. Thank you for choosing TriggerCraft as your trusted tech destination.
                    </p> */}
                </div>
                <FixedMessenger />
            </>
        );
    }


}