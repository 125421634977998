import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export class PayFastCancelled extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = "Payment Cancelled :: TriggerCraft";
  }

  render() {
    return (
      <div className='col-md-8 mx-auto ' >
        <div className="card">
          <div className="card-body text-center"><i className='far fa-times-circle fa-5x mb-4 mt-3 text-danger'></i>
            <h3> PayFast Payment Cancelled!</h3>
            {/* <Link to={"/order-completion"} color='dark' className='btn btn-outline-dark btn-sm mt-3' >Go to Checkout</Link> */}
          </div>
        </div>
      </div>
    );
  }


}