import React, { Component } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { getAuthToken, getUserDetails } from '../helpers/authentication';
import { getIsAdmin } from '../helpers/Lookup';

const ProtectedRoute = ({ element: Element, ...rest }) => {
    const isLoggedIn = getAuthToken();
    const isAdmin = getIsAdmin();
    return (
        <Routes>
        <Route
            {...rest}
                element={isLoggedIn && isAdmin ? Element : <Navigate to="/login" replace />}
            />
        </Routes>
    );
};

export default ProtectedRoute;