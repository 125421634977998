export const getAuthToken = () => {
  if (window.localStorage.getItem("AuthToken") != null) {
    var data = JSON.parse(window.localStorage.getItem("AuthToken"));
    //todo: check expiry
    return data.authToken;
  }
  else
    return null;
};

export const setAuthToken = (token, expiry) => {
  const data = { authToken: token, authExpires: expiry }
  try {
    window.localStorage.setItem("AuthToken", JSON.stringify(data));
    return true;
  } catch (e) {
    console.error(e)
    return false;
  }
};

export const getUserDetails = () => {
  if (window.localStorage.getItem("CurrentUser") != null)
    return JSON.parse(window.localStorage.getItem("CurrentUser"));
  else
    return null;
};

export const setUserDetails = (props) => {
  try {
    window.localStorage.setItem("CurrentUser", JSON.stringify(props));
    return true;
  } catch (e) {
    console.error(e)
    return false;
  }

};

export const getUserLevel = () => {
  if (window.localStorage.getItem("AuthLevel") != null)
    return window.localStorage.getItem("AuthLevel");
  else
    return null;
};

export const setUserLevel = (level) => {
  try {
    window.localStorage.setItem("AuthLevel", level);
    return true;
  } catch (e) {
    console.error(e)
    return false;
  }
};

export const clearUserDetails = () => {
  try {
    //window.localStorage.removeItem("CurrentUser");
    //window.localStorage.removeItem("AuthToken");
    //window.localStorage.removeItem("AuthLevel");
    window.localStorage.clear();
    window.sessionStorage.clear();
  } catch (e) {
    console.error(e)
  }

};

export const GetUserHubConnection = () => {
  try {
    return JSON.parse(window.sessionStorage.getItem("HubConnection"));
  } catch (e) {
    console.error(JSON.parse(e));
  }
}

export const setUserHubConnection = (connection) => {
  try {
    window.sessionStorage.setItem("HubConnection", JSON.stringify(connection));
  } catch (e) {
    console.error(JSON.parse(e));
  }
}

export const IsUserAuthorized = async () => {
  if (getAuthToken()) {
    let bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/userlogin/IsUserAuthorized', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          "Content-Type": "application/json"
        }
      });

      if (response.ok) {
        const status = await response.json();
        return status;
      }
      else {
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  } else {
    return false;
  }
}

export const IsUserAdmin = async () => {
  let bearer = 'Bearer ' + getAuthToken();
  try {
    const response = await fetch('api/userlogin/IsAdmin', {
      method: 'GET',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json'
      }
    });
    if (response.ok) {
      const isAdmin = await response.json();
      return isAdmin;
    }
    else {
      return false;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
}