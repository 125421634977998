import { CaretDownIcon } from '@radix-ui/react-icons';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import classNames from 'classnames';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { clearUserDetails, getAuthToken } from '../helpers/authentication';
import { Context } from '../helpers/Context';
import { getCurrentDomain, getIsAdmin } from '../helpers/Lookup';
import Cart from './Cart';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;
  static contextType = Context;

  constructor(props) {
    super(props);


    this.state = {
      collapsed: true,
      CategoryList: [],
      SubcategoryList: [],
      isSticky: false,
      isLoggedIn: false,
      dropdownOpen: false,
      menuOpen: false,
      size: {
        width: undefined,
        height: undefined,
      },
    };
  }

  componentDidMount() {
    const { getCategories, getSubcategories } = this.context;
    getCategories();
    getSubcategories();
    this.handleScroll();
    const handleResize = () => {
      this.setState({
        size: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      });
    };
    window.addEventListener("resize", handleResize);
    this.handleResize = handleResize;
  }

  componentDidUpdate(prevProps, prevState) {
    const { categories, subcategories } = this.context;
    if (prevState.CategoryList !== categories) {
      this.setState({ CategoryList: categories });
    }
    if (prevState.SubcategoryList !== subcategories) {
      this.setState({ SubcategoryList: subcategories });
    }
    if (this.state.size.width > 768 && this.state.menuOpen) {
      this.setState({ menuOpen: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  }

  menuToggleHandler = () => {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }));
  };

  handleScroll = () => {
    const { isSticky } = this.state;
    const scrollPosition = window.pageYOffset;

    if (scrollPosition > 60 && !isSticky) {
      this.setState({ isSticky: true });
    } else if (scrollPosition <= 60 && isSticky) {
      this.setState({ isSticky: false });
    }

    requestAnimationFrame(this.handleScroll);
  };

  async handleLogout() {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to log out?", confirmStyles);
    if (result) {
      window.location.href = "/login";
      clearUserDetails();
    }
  }

  render() {
    const { isSticky, menuOpen, size, CategoryList, SubcategoryList } = this.state;
    const { cartCount, wishCount, showCart, setShowCart, getCategories, categories, setSelectedBrands } = this.context;
    //console.log(CategoryList, SubcategoryList);
    const renderedCategories = CategoryList
      .filter(category => SubcategoryList.some(sub => sub.categoryId === category.id) && category.showOnMenu === 1)
      .map((category, index) => {
        const matchingSubcategories = SubcategoryList.filter(sub => sub.categoryId === category.id);

        return (
          <NavigationMenu.Item key={index}>
            <NavigationMenu.Trigger className="NavigationMenuTrigger">
              <Link onClick={() => { localStorage.removeItem('selectedBrands1'); setSelectedBrands([]); }} to={`/products/category/${category.slug}`}>{category.title}</Link>
              <CaretDownIcon className="CaretDown" aria-hidden />
            </NavigationMenu.Trigger>
            <NavigationMenu.Content className="NavigationMenuContent">
              <img
                src={category.categoryImages ? getCurrentDomain() + category.categoryImages : "/android-chrome-192x192.png"}
                alt="logo"
              />
              <ul className="List two">
                {matchingSubcategories.map((sub, id) => (
                  <Link key={id} onClick={() => { localStorage.removeItem('selectedBrands1'); setSelectedBrands([]); }} to={`/products/category/${sub.slug}`}>
                    {sub.title}
                  </Link>
                ))}
              </ul>
            </NavigationMenu.Content>
          </NavigationMenu.Item>
        );
      });

    const navigationContent = renderedCategories.length > 0 ? renderedCategories : <span>Loading...</span>;

    //console.log(CategoryList, SubcategoryList)
    return (
      <header>
        <div className="bg-dark">
          <div className="container row mx-auto">
            <span className="col-6 py-3 hoverLinks px-0">
              <a target="_blank" href="tel:+27 10 220 5737" rel="noreferrer"><span className="number-hide">Support Hotline: </span>+27 10 220 5737</a>
            </span>
            <div className="col-6 py-3 social-icons text-end px-0 hoverLinks">
              <a className="fab px-1 fa-facebook" target="_blank" rel="noreferrer" href="https://www.facebook.com/triggercraft1"></a>
              <a className="fab px-1" target="_blank" href="https://twitter.com/triggercraft1" rel="noreferrer"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg></a>
              {/*<a className="fab px-1 fa-whatsapp desktop" target="_blank" href="https://web.whatsapp.com/send?phone=919876543210"></a>*/}
              <a className="fab px-1 fa-linkedin" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/triggercraft"></a>
              <a className="fab px-1 fa-instagram" target="_blank" rel="noreferrer" href="https://www.instagram.com/triggercraft1/?hl=en"></a>
              <i className="fa-brands fa-x-twitter"></i>
            </div>
          </div>
        </div>

        <div className={isSticky ? "sticky mb-3" : "mb-3"}>
          <header className="header">
            <div className="header__content">
              <Link to="/" className="header__content__logo">
                <img src="/assets/imgs/Trigger-Craft-Logo.png" alt="logo" />
              </Link>
              <nav className={`header__content__nav ${menuOpen && size.width < 800 ? "isMenu" : ""}`} >
                <ul>
                  <li>
                    <Link>
                      Shop &nbsp;<small><i className="fa-light fa-angle-down"></i></small>
                    </Link>
                    <ul>
                      <li>
                        <Link to="/products" onClick={this.menuToggleHandler}>
                          Products
                        </Link>
                      </li>
                      <li>
                        <Link to="/brands" onClick={this.menuToggleHandler}>
                          Brands
                        </Link>
                      </li>
                      <li>
                        <Link to="/categories" onClick={this.menuToggleHandler}>
                          Categories
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/promotions" onClick={this.menuToggleHandler}>
                      Promotions
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" onClick={this.menuToggleHandler}>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us" onClick={this.menuToggleHandler}>
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/faqs" onClick={this.menuToggleHandler}>
                      FAQs
                    </Link>
                  </li>
                  {getAuthToken() ?
                    <li>
                      <Link>
                        My Account &nbsp;<small><i className="fa-light fa-angle-down"></i></small>
                      </Link>
                      <ul>
                        <li>
                          <Link to={getAuthToken() ? "/personal-infomation" : "/login"} onClick={this.menuToggleHandler}>
                            Personal Information
                          </Link>
                        </li>
                        <li>
                          <Link to={getAuthToken() ? "/order-history" : "/login"} onClick={this.menuToggleHandler}>
                            Order History
                          </Link>
                        </li>
                        <li>
                          <Link to={getAuthToken() ? "/customer-delivery-addresses" : "/login"} onClick={this.menuToggleHandler}>
                            Delivery Details
                          </Link>
                        </li>
                        <li>
                          <Link onClick={this.handleLogout}>
                            Log Out
                          </Link>
                        </li>
                        {getIsAdmin() == "true" && <li>
                          <Link to="/admin/administration" onClick={this.menuToggleHandler}>
                            Site Admin
                          </Link>
                        </li>}
                      </ul>
                    </li> :
                    <li>
                      <Link to="/login" onClick={this.menuToggleHandler}>
                        Login/Register
                      </Link>
                    </li>}
                  <NavItem>
                    <NavLink tag={Link} onClick={this.menuToggleHandler} className="text-dark cart-icon" to="/product-search"><i className="fa-regular fa-magnifying-glass ms-2"></i></NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} onClick={this.menuToggleHandler} className="text-dark cart-icon" to="/wish-list"> {window.location.href.includes("/wish-list") ? <i className="fa-solid fa-heart ms-2"></i> : <i className="fa-regular fa-heart ms-2"></i>}
                      {!!wishCount && <span className="text-light">{wishCount}</span>}</NavLink>
                  </NavItem>
                  <NavItem onClick={this.menuToggleHandler}>
                    <NavLink className="text-dark cart-icon" onClick={() => setShowCart(true)}>{window.location.href.includes("/cart") ? <i className="fa-solid fa-shopping-cart ms-2"></i> : <i className="fa-regular fa-shopping-cart ms-2"></i>}
                      {!!cartCount && <span className="text-light">{cartCount}</span>}</NavLink>
                  </NavItem>
                </ul>
              </nav>
              <div className="header__content__toggle">
                {!menuOpen ? (
                  <i className="fa-solid fa-bars" onClick={this.menuToggleHandler}></i>
                ) : (
                  <i className="fa-solid fa-xmark" onClick={this.menuToggleHandler}></i>
                )}
              </div>
            </div>
          </header>
          <NavigationMenu.Root className="NavigationMenuRoot">
            <NavigationMenu.List className="NavigationMenuList align-items-center text-center">
              {navigationContent}
              <NavigationMenu.Indicator className="NavigationMenuIndicator">
                <div className="Arrow" />
              </NavigationMenu.Indicator>
            </NavigationMenu.List>

            <div className="ViewportPosition">
              <NavigationMenu.Viewport className="NavigationMenuViewport" />
            </div>
          </NavigationMenu.Root>
        </div>
        {showCart && <Cart setShowCart={setShowCart} />}
      </header>
    );
  }
}

const ListItem = React.forwardRef(({ className, children, title, ...props }, forwardedRef) => (
  <li>
    <NavigationMenu.Link asChild>
      <a className={classNames('ListItemLink', className)} {...props} ref={forwardedRef}>
        <div className="ListItemHeading">{title}</div>
        <p className="ListItemText">{children}</p>
      </a>
    </NavigationMenu.Link>
  </li>
));