import React, { useContext, useEffect } from "react";
//import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { Context } from "../helpers/Context";
import { getCurrentDomain } from "../helpers/Lookup";

const ShopFor = () => {
    const navigate = useNavigate();
    const { getCategories, categories } = useContext(Context);

    const goToCategory = (data) => {
        navigate(data);
    };

    useEffect(() => {
        getCategories();
    }, [])
    //console.log(categories)
    return (
        <div>
            <h2>Shop For</h2>
            <div className="row mx-2 text-light text-center justify-content-evenly mb-3">
                {categories && categories.filter(item => item.isRestricted).map((category, index) => (
                    <div key={index} className="col-md-6 col-lg-4 overlay p-2" onClick={e => goToCategory(`/products/category/${category.slug.trim().toLowerCase()}`)} style={{ cursor: "pointer" }} >
                        <span className="">{category.title}</span>
                        <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={category.categoryBanners ? getCurrentDomain() + category.categoryBanners : "/android-chrome-192x192.png"} alt={category.title } />
                </div>
                    
                    ))}
            </div>
        </div>
    );
};

export default ShopFor;