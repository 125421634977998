import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { Context } from '../helpers/Context';
import { getCurrentDomain } from '../helpers/Lookup';
import FixedMessenger from './FixedMessenger';
import { ProductBrandCard } from './ProductBrandCard';

const BrandSpecificPage = () => {
    const { products, getProducts, brands, getBrands } = useContext(Context);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [isHovered, setIsHovered] = useState(false);
    const [selectedOption, setSelectedOption] = useState('lowest');
    const [showOutOfStock, setShowOutOfStock] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const location = useLocation();
    const productsPerPage = 12;
    const { slug } = useParams();
    const parts = window.location.href.split("/");
    const lastWord = parts[parts.length - 1];
    const brandSlug = slug || lastWord.trim();
    const selectedBrand = brands.find(item => item.slug == brandSlug);
    let filteredProducts = products.filter(item => { return item.productBrandId == selectedBrand.id });

    if (selectedOption === 'lowest') {
        filteredProducts = filteredProducts.sort((a, b) => {
            const aPrice = a.sPrice || a.price;
            const bPrice = b.sPrice || b.price;
            return aPrice - bPrice;
        });
    } else if (selectedOption === 'highest') {
        filteredProducts = filteredProducts.sort((a, b) => {
            const aPrice = a.sPrice || a.price;
            const bPrice = b.sPrice || b.price;
            return bPrice - aPrice;
        });
    } else if (selectedOption === 'a-z') {
        filteredProducts = filteredProducts.sort((a, b) => a.title.localeCompare(b.title));
    } else if (selectedOption === 'z-a') {
        filteredProducts = filteredProducts.sort((a, b) => b.title.localeCompare(a.title));
    }

    if (showOutOfStock) {
        filteredProducts = filteredProducts.filter(item => item.stock > 0);
    } else if (!showOutOfStock) {
        filteredProducts = filteredProducts.filter(item => item);
    }

    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
    const visiblePageCount = Math.min(7, totalPages);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getStartPage = () => {
        if (currentPage <= Math.ceil(visiblePageCount / 2)) {
            return 1;
        } else if (currentPage >= totalPages - Math.floor(visiblePageCount / 2)) {
            return totalPages - visiblePageCount + 1;
        } else {
            return currentPage - Math.floor(visiblePageCount / 2);
        }
    };

    const first20Items = filteredProducts.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)).slice(0, 20);

    const startPage = getStartPage();

    const beforePseudoStyle = {
        //zIndex: "-1",
        //position: "absolute",
        //height: "100%",
        //width: "100%",
        //content: "",
        //top: 0,
        //left: 0,
        backgroundColor: selectedBrand && selectedBrand.accentBrandColour,
        borderRadius: "24px",
        //transform: "scale(0)",
        transition: "all 0.2s",
    };

    const goToCategory = (data) => {
        navigate(data);
    };

    const hoverStyle = {
        transform: "scale(1)",
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const toggleStock = () => {
        setShowOutOfStock((prevShowOutOfStock) => !prevShowOutOfStock);
    };

    const dynamicGridStyleNew = {
        display: 'grid',
        gridTemplateColumns: `repeat(${first20Items.length}, 1fr)`,
        gap: '18px',
        fontSize: 0,
        listStyle: 'none',
        marginBottom: '30px',
        overflowX: 'auto',
        scrollbarWidth: 'none',
    };

    const dynamicGridStyle = {
        display: 'grid',
        gridTemplateColumns: `repeat(${filteredProducts.length}, 1fr)`,
        gap: '18px',
        fontSize: 0,
        listStyle: 'none',
        marginBottom: '30px',
        overflowX: 'auto',
        scrollbarWidth: 'none',
    };

    useEffect(() => {
        document.title = "Product Brands :: TriggerCraft";
        getBrands();
        getProducts();
    }, []);

    useEffect(() => {
        const initSlider = () => {
            const imageListNew = document.querySelector(".slider-wrapper-new .image-list-new");
            const imageList = document.querySelector(".slider-wrapper .image-list");
            const slideButtonsNew = document.querySelectorAll(".slider-wrapper-new .slide-button-new");
            const slideButtons = document.querySelectorAll(".slider-wrapper .slide-button");
            const maxScrollLeft = imageList.scrollWidth - imageList.clientWidth;

            slideButtonsNew.forEach(button => {
                button.addEventListener("click", () => {
                    let direction;
                    if (button.id === "prev-slide-new")
                        direction = -1;
                    if (button.id === "next-slide-new")
                        direction = 1;
                    
                    const scrollAmount = 1104 * direction;
                    imageListNew.scrollBy({ left: scrollAmount, behavior: "smooth" });
                });
            });

            const handleNewSlideButtons = () => {
                slideButtonsNew[0].style.display = imageListNew.scrollLeft <= 0 ? "none" : "flex";
                slideButtonsNew[1].style.display = imageListNew.scrollLeft >= maxScrollLeft ? "none" : "flex";
            };
            imageListNew.addEventListener("scroll", () => {
                handleNewSlideButtons();
            });

            slideButtons.forEach(button => {
                button.addEventListener("click", () => {
                    let direction;
                    if (button.id === "prev-slide")
                        direction = -1;
                    if (button.id === "next-slide")
                        direction = 1;
                    
                    const scrollAmount = 1104 * direction;
                    imageList.scrollBy({ left: scrollAmount, behavior: "smooth" });
                });
            });

            const handleSlideButtons = () => {
                slideButtons[0].style.display = imageList.scrollLeft <= 0 ? "none" : "flex";
                slideButtons[1].style.display = imageList.scrollLeft >= maxScrollLeft ? "none" : "flex";
            };
            imageList.addEventListener("scroll", () => {
                handleSlideButtons();
            });
        };

        initSlider();

        return () => {
            window.removeEventListener("resize", initSlider);
            window.removeEventListener("load", initSlider);
        };
    }, []);

    /*const first20Items = filteredProducts.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)).slice(0, 20);*/

    const textColor = selectedBrand && selectedBrand.accentBrandColour;
    //console.log(filteredProducts)
    return (
        <>
            <div className="background-container"
                style={{
                    backgroundImage:
                        selectedBrand && selectedBrand.primaryBrandColour.includes("linear-gradient")
                            ? selectedBrand.primaryBrandColour
                            : 'none',
                    backgroundColor: selectedBrand
                        ? selectedBrand.primaryBrandColour
                        : 'white',
                }}
            >
            </div>
            {<nav className="mb-3 crumbs text-light">
                {/*<Link to="/" className="breadcrumb-active text-light"><small>Home</small>*/}
                {/*</Link>*/}
                {/*<span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>*/}
                {/*<Link to="/brands" className={location.pathname.startsWith("/brands") ? "breadcrumb-active text-light" : "breadcrumb-not-active"}><small>Brands</small>*/}
                {/*</Link>*/}
                {/*<span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>*/}
                <small className={location.pathname === "/brands" ? "breadcrumb-active" : "breadcrumb-not-active"}>{selectedBrand && selectedBrand.title}</small>
            </nav>}
            <div className="row">

                {selectedBrand && selectedBrand.brandBannerImages ?
                    <div className="text-center brand-Banner mb-3">
                        <img src={selectedBrand && selectedBrand.brandBannerImages ? getCurrentDomain() + selectedBrand.brandBannerImages : "/assets/imgs/Trigger-Craft-Logo.png"} alt={selectedBrand && selectedBrand.title} className="" />
                    </div>
                    :
                    <div className="text-center brand-image mb-3">
                        <img src={selectedBrand && selectedBrand.brandImages ? getCurrentDomain() + selectedBrand.brandImages : "/android-chrome-192x192.png"} alt={selectedBrand && selectedBrand.title} className="brand-logo" />
                    </div>
                }
                {/*</div>*/}
                <div className="text-center razer-categories">
                    <div onClick={() => goToCategory(`/categories/razer/${"Accessories".replace(" ", "_").toLowerCase()}`)}>
                        <img src="/assets/Category-Images-Razer/Assessories.png" />
                        <h6>Accessories</h6>
                    </div>
                    <div onClick={() => goToCategory(`/categories/razer/${"Audio".replace(" ", "_").toLowerCase()}`)}>
                        <img src="/assets/Category-Images-Razer/Audio.png" />
                        <h6>Audio</h6>
                    </div>
                    <div onClick={() => goToCategory(`/categories/razer/${"Controllers".replace(" ", "_").toLowerCase()}`)}>
                        <img src="/assets/Category-Images-Razer/Controller.png" />
                        <h6>Controllers</h6>
                    </div>
                    <div onClick={() => goToCategory(`/categories/razer/${"Headsets".replace(" ", "_").toLowerCase()}`)}>
                        <img src="/assets/Category-Images-Razer/Headsets.png" />
                        <h6>Headsets</h6>
                    </div>
                    <div onClick={() => goToCategory(`/categories/razer/${"Keyboards".replace(" ", "_").toLowerCase()}`)}>
                        <img src="/assets/Category-Images-Razer/Keyboards.png" />
                        <h6>Keyboards</h6>
                    </div>
                    <div onClick={() => goToCategory(`/categories/razer/${"Mice and Mousepads".replaceAll(" ", "_").toLowerCase()}`)}>
                        <img src="/assets/Category-Images-Razer/Mice-and-Mousepad.png" />
                        <h6>Mice and Mousepads</h6>
                    </div>
                    <div onClick={() => goToCategory(`/categories/razer/${"Office Essentials".replace(" ", "_").toLowerCase()}`)}>
                        <img src="/assets/Category-Images-Razer/Office-essentials.png" />
                        <h6>Office Essentials</h6>
                    </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <div className="toggle-container">
                        <div className="d-none">
                            <strong>All Products &nbsp;</strong>
                            <div className="toggle-switch">
                                <input type="checkbox" className="checkbox" name="stock" id="stock" checked={showOutOfStock} onChange={toggleStock} />
                                <label className="label" htmlFor="stock">
                                    <span className="inner" />
                                    <span className="switch" />
                                </label>
                            </div>
                            <strong> &nbsp; In Stock Only</strong>
                        </div>
                    </div>
                    <div className="text-end d-none">
                        <form action="#">
                            <label htmlFor="sort">Sort:</label>
                            <select name="sort" id="sort" className="sort-selection--style" onChange={handleOptionChange} >
                                <option value="lowest">Price(lowest)</option>
                                <option value="highest">Price(highest)</option>
                                <option value="a-z">Title(a-z)</option>
                                <option value="z-a">Title(z-a)</option>
                            </select>
                        </form>
                    </div>
                </div>
                <hr />
                {/*<div className="d-flex justify-content-between mb-2">*/}
                <div className="razor-title mb-4">
                    <h1>THE LATEST AND GREATEST GAMING GEAR</h1>
                    <h3>Razer mice, keyboards, headsets, laptops & more</h3>
                </div>
                {/*</div>*/}
                <div className="razor-title mb-4">
                    <h2>FRESH OFF THE LINE</h2>
                    <h3>Check out our latest releases to secure the most up-to-date upgrades for your setup</h3>
                </div>

                <div className="razer-container2">
                    <div className="slider-wrapper-new">
                        <i id="prev-slide-new" className="slide-button-new fa-solid fa-circle-arrow-left"></i>
                        <ul className="image-list-new px-0" style={dynamicGridStyleNew}>
                            {first20Items.map((product) => (
                                <ProductBrandCard
                                    key={product.id}
                                    product={product}
                                    id={product.id}
                                    status={product.stock}
                                    accentColour={selectedBrand.accentBrandColour}
                                    mainColour={selectedBrand.primaryBrandColour}
                                    cardColour={selectedBrand.secondaryBrandColor}
                                    textColour={selectedBrand.textColour}
                                    imageURL={product.images ? product.images.split(", ")[0] : selectedBrand && selectedBrand.brandImages && JSON.stringify(selectedBrand.brandImages)}
                                    currPrice={product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date() ? product.sPrice : product.price}
                                    prevPrice={product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date() ? product.price : product.sPrice}
                                    title={product.title}
                                    description={product.shortDescription}
                                />
                            ))}
                        </ul>
                        <i id="next-slide-new" className="slide-button-new fa-solid fa-circle-arrow-right"></i>
                    </div>
                </div>

                <div className="razor-title mb-4">
                    <h2>BEST SELLERS</h2>
                    <h3>Join the hype train with the hottest products in our arsenal</h3>
                </div>

                <div className="razer-container">
                    <div className="slider-wrapper">
                        <i id="prev-slide" className="slide-button fa-solid fa-circle-arrow-left"></i>
                        <ul className="image-list px-0" style={dynamicGridStyle}>
                            {filteredProducts.map((product) => (
                                <ProductBrandCard
                                    key={product.id}
                                    product={product}
                                    id={product.id}
                                    status={product.stock}
                                    accentColour={selectedBrand.accentBrandColour}
                                    mainColour={selectedBrand.primaryBrandColour}
                                    cardColour={selectedBrand.secondaryBrandColor}
                                    textColour={selectedBrand.textColour}
                                    imageURL={product.images ? product.images.split(", ")[0] : selectedBrand && selectedBrand.brandImages && JSON.stringify(selectedBrand.brandImages)}
                                    currPrice={product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date() ? product.sPrice : product.price}
                                    prevPrice={product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date() ? product.price : product.sPrice}
                                    title={product.title}
                                    description={product.shortDescription}
                                />
                            ))}
                        </ul>
                        <i id="next-slide" className="slide-button fa-solid fa-circle-arrow-right"></i>
                    </div>
                </div>

                <div className="text-center">
                    <img src="/assets/Category-Images-Razer/Razer-banner.jpg" alt="Razer banner" style={{ width: "100%",  height: "450px", objectFit: "contain" } } />
                </div>
            </div>
            <FixedMessenger />
        </>
    );
};

export default BrandSpecificPage;