import React from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const FixedMessenger = () => {

    return (
        <div className="fixed">
            <TawkMessengerReact propertyId="64f87ca4a91e863a5c11fcf9" widgetId="1h9ladp0g" />
        </div>
    );
};

export default FixedMessenger;