import React, { Component } from 'react';
import { getCurrentDomain } from '../helpers/Lookup';

export class BannerThumbnails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            BannerUrls: Array.isArray(props.bannerUrls) ? props.bannerUrls : props.bannerUrls.split(',').map(url => url.trim()).filter(url => url !== ''),
        };
    }

    handleBannerUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const newImageUrl = e.target.result;
                this.setState({ BannerUrls: [...this.state.BannerUrls, newImageUrl] });
                this.props.onUpdateBannerUrls([...this.state.BannerUrls, newImageUrl]);
            };
            reader.readAsDataURL(file);
        }
    };

    openImageUploader = () => {
        this.uploadInputRef.click();
    }

    deleteImage = (index) => {
        const updatedImageUrls = [...this.state.BannerUrls];
        updatedImageUrls.splice(index, 1);
        this.setState({ BannerUrls: updatedImageUrls });
        this.props.onUpdateBannerUrls(updatedImageUrls);
    }

    render() {
        const { BannerUrls } = this.state;
        // console.log(this.state, BannerUrls.length, this.props.isSingleBanner)
        return (
            <div>
                <div className="Bannerthumbnails">
                    {BannerUrls.map((imageUrl, index) => (
                        <div key={index} className="thumbnail-container">
                            {/*{console.log(imageUrl, imageUrl.includes("\\")) }*/}
                            <img src={imageUrl.includes("\\") ? getCurrentDomain() + imageUrl.replace(/\\/g, "/") : imageUrl} alt={`Thumbnail ${index}`} className="thumbnail" />
                            <i onClick={() => this.deleteImage(index)} className="fa-solid fa-circle-minus" title="Remove Image"></i>
                        </div>
                    ))}
                    {this.props.isSingleBanner && BannerUrls.length === 0 && (<button onClick={this.openImageUploader}><i className="fa-solid fa-circle-plus fs-1" title="Add New Image"></i></button>)}
                    {!this.props.isSingleBanner && (<button onClick={this.openImageUploader}><i className="fa-solid fa-circle-plus fs-1" title="Add New Image"></i></button>)}
                </div>
                <div className="image-uploader thumbnail">
                    <input
                        ref={ref => this.uploadInputRef = ref}
                        type="file"
                        accept="image/*"
                        onChange={this.handleBannerUpload}
                        className="upload-input"
                    />
                </div>
            </div>
        );
    }
}