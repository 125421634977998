import React, { Component } from 'react';
import { Context } from '../helpers/Context';
import { getCurrentDomain } from '../helpers/Lookup';
import FixedMessenger from './FixedMessenger';
import Hero from './HeroSection';
import { ProductCard } from './ProductCard';
import ShopFor from './ShopFor';

export class Home extends Component {
  static displayName = Home.name;
  static contextType = Context;

  state = {
    randomNewProducts: [],
    randomDealProducts: [],
    randomTopProducts: [],
    randomBestProducts: [],
    BrandList: [],
  };

  componentDidMount() {
    document.title = "Home :: TriggerCraft";
    const { getProducts, getCategories, getBrands } = this.context;
    getBrands();
    //getCategories();
    getProducts();
  }

  handleNavigation(data) {
    const { handleNavigate } = this.context;
    handleNavigate(data);
  }

  getRandomObjectsFromArray(arr, numObjects) {
    if (numObjects >= arr.length) {
      return arr.slice();
    }
    const randomObjects = [];
    const usedIndices = new Set();

    while (randomObjects.length < numObjects) {
      const randomIndex = Math.floor(Math.random() * arr.length);

      if (!usedIndices.has(randomIndex)) {
        usedIndices.add(randomIndex);
        randomObjects.push(arr[randomIndex]);
      }
    }

    return randomObjects;
  }

  componentDidUpdate(prevProps, prevState) {
    const { products, brands } = this.context;

    if (prevState.randomNewProducts.length === 0 && products.length > 0) {
      const newProducts = products.filter(item => item.stock > 0).sort((a, b) => {
        const dateA = new Date(a.dateAdded);
        const dateB = new Date(b.dateAdded);

        return dateB - dateA;
      });
      const randomNewProducts = this.getRandomObjectsFromArray(newProducts, 4);
      this.setState({ randomNewProducts });
    }

    if (prevState.randomDealProducts.length === 0 && products.length > 0) {
      let randomDealProducts;
      const deals = products.filter(item => item.sPrice && item.stock && item.dateSPriceEnds)
      const noPriceDeals = products.filter(item => item.stock)
      if (deals.length)
        randomDealProducts = this.getRandomObjectsFromArray(deals, 4);
      else
        randomDealProducts = this.getRandomObjectsFromArray(noPriceDeals, 4);
      this.setState({ randomDealProducts });
    }

    if (prevState.randomTopProducts.length === 0 && products.length > 0) {
      const topRated = products.filter(item => item.avgRating > 3)
      const randomTopProducts = topRated.length ? this.getRandomObjectsFromArray(topRated, 4) : this.getRandomObjectsFromArray(products, 4);
      this.setState({ randomTopProducts });
    }

    if (prevState.randomBestProducts.length === 0 && products.length > 0) {
      const randomBestProducts = this.getRandomObjectsFromArray(products, 4);
      this.setState({ randomBestProducts });
    }

    if (brands.length > 0 && prevState.BrandList !== brands) {
      this.setState({ BrandList: brands });
    }
  }

  render() {
    const { randomNewProducts, randomDealProducts, randomTopProducts, randomBestProducts, BrandList } = this.state;
    //console.log(randomDealProducts)
    return (
      <div>
        <Hero />
        <hr />
        <ShopFor />
        <hr />
        <h2>Recently Added</h2>
        <div className="row px-2 mb-3">
          {randomNewProducts.map((product) => {
            return (
              <div className="col-md-6 col-lg-3 d-flex justify-content-center mb-3" key={product.id}>
                <ProductCard
                  product={product}
                  imageURL={product.images ? product.images.split(", ")[0] : "/assets/imgs/Trigger-Craft-Logo.png"}
                  currPrice={
                    product.sPrice &&
                      product.dateSPriceEnds &&
                      new Date(product.dateSPriceEnds) >= new Date()
                      ? product.sPrice
                      : product.price
                  }
                  prevPrice={
                    product.sPrice &&
                      product.dateSPriceEnds &&
                      new Date(product.dateSPriceEnds) >= new Date()
                      ? product.price
                      : product.sPrice
                  }
                />
              </div>
            )
          })}
        </div>
        <hr />
        <h2>Deals</h2>
        <div className="row px-2 mb-3">
          {randomDealProducts.filter(item => item.stock).map((product) => {
            return (
              <div className="col-md-6 col-lg-3 d-flex justify-content-center mb-3" key={product.id}>
                <ProductCard
                  product={product}
                  imageURL={product.images ? product.images.split(", ")[0] : "/assets/imgs/Trigger-Craft-Logo.png"}
                  currPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.sPrice : product.price}
                  prevPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.price : product.sPrice}
                />
              </div>
            )
          })}
        </div>
        <hr />
        <h2>Top Rated</h2>
        <div className="row px-2 mb-3">
          {randomTopProducts.map((product) => {
            return (
              <div className="col-md-6 col-lg-3 d-flex justify-content-center mb-3" key={product.id}>
                <ProductCard
                  product={product}
                  imageURL={product.images ? product.images.split(", ")[0] : "/assets/imgs/Trigger-Craft-Logo.png"}
                  currPrice={product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date() ? product.sPrice : product.price}
                  prevPrice={product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date() ? product.price : product.sPrice}
                />
              </div>
            )
          })}
        </div>
        <hr />
        <h2>Best Selling</h2>
        <div className="row px-2 mb-3">
          {randomBestProducts.map((product) => {
            return (
              <div className="col-md-6 col-lg-3 d-flex justify-content-center mb-3" key={product.id}>
                <ProductCard
                  product={product}
                  imageURL={product.images ? product.images.split(", ")[0] : "/assets/imgs/Trigger-Craft-Logo.png"}
                  currPrice={product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date() ? product.sPrice : product.price}
                  prevPrice={product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date() ? product.price : product.sPrice}
                />
              </div>
            )
          })}
        </div>
        <hr />
        <h2>Popular Brands</h2>
        <div className="row px-2 mb-3">
          {BrandList.filter(item => item.showOnHome).map((brand) => {
            return (
              <div className="col-md-6 col-lg-3 d-flex flex-column gap-2 align-items-center mb-3 brand-container" onClick={e => this.handleNavigation(`/brands/products/${brand.slug}`)} key={brand.id}>
                <img src={brand.brandImages ? getCurrentDomain() + brand.brandImages : "/android-chrome-192x192.png"} alt={brand.title} className="brand-logo" />
                <div className="brand-name">{brand.title}</div>
              </div>
            )
          })}
        </div>
        <FixedMessenger />
        
      </div>
    );
  }
}
