import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import { Context } from '../helpers/Context';
import { getCurrentDomain } from '../helpers/Lookup';
import FixedMessenger from './FixedMessenger';
import { ProductCard } from './ProductCard';

const ProductBrandPage = () => {
    const { products, getProducts, brands, getBrands } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [isHovered, setIsHovered] = useState(false);
    const [selectedOption, setSelectedOption] = useState('lowest');
    const [showOutOfStock, setShowOutOfStock] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 12;
    const { slug } = useParams();
    const location = useLocation();
    const selectedBrand = brands.find(item => item.slug == slug);
    let filteredProducts = products.filter(item => { return item.productBrandId == selectedBrand.id });

    if (selectedOption === 'lowest') {
        filteredProducts = filteredProducts.sort((a, b) => {
            const aPrice = a.sPrice || a.price;
            const bPrice = b.sPrice || b.price;
            return aPrice - bPrice;
});
    } else if (selectedOption === 'highest') {
        filteredProducts = filteredProducts.sort((a, b) => {
            const aPrice = a.sPrice || a.price;
            const bPrice = b.sPrice || b.price;
            return bPrice - aPrice;
});
    } else if (selectedOption === 'a-z') {
        filteredProducts = filteredProducts.sort((a, b) => a.title.localeCompare(b.title));
    } else if (selectedOption === 'z-a') {
        filteredProducts = filteredProducts.sort((a, b) => b.title.localeCompare(a.title));
    }

    if (showOutOfStock) {
        filteredProducts = filteredProducts.filter(item => item.stock > 0);
    } else if (!showOutOfStock) {
        filteredProducts = filteredProducts.filter(item => item);
    }

    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
    const visiblePageCount = Math.min(7, totalPages);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getStartPage = () => {
        if (currentPage <= Math.ceil(visiblePageCount / 2)) {
            return 1;
        } else if (currentPage >= totalPages - Math.floor(visiblePageCount / 2)) {
            return totalPages - visiblePageCount + 1;
        } else {
            return currentPage - Math.floor(visiblePageCount / 2);
        }
    };

    const startPage = getStartPage();

    const beforePseudoStyle = {
        borderRadius: "24px",
        transition: "all 0.2s",
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const toggleStock = () => {
        setShowOutOfStock((prevShowOutOfStock) => !prevShowOutOfStock);
    };

    useEffect(() => {
        document.title = "Product Brands :: TriggerCraft";
        getBrands();
        getProducts();
    }, []);
    //console.log(brandId, selectedBrand && selectedBrand)
    //const textColor = selectedBrand && selectedBrand.accentBrandColour;
    return (
        <>
            <div className="background-container" >
            </div>
            <nav className="mb-3 crumbs">
                <Link to="/" className="breadcrumb-active"><small>Home</small>
                </Link>
                <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
                <Link to="/brands" className={location.pathname.startsWith("/brands") ? "breadcrumb-active" : "breadcrumb-not-active"}><small>Brands</small>
                </Link>
                <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
                <small className={location.pathname === "/brands" ? "breadcrumb-active" : "breadcrumb-not-active"}>{selectedBrand && selectedBrand.title}</small>
            </nav>
            <div className="row">

                {selectedBrand && selectedBrand.brandBannerImages ?
                    <div className="text-center brand-Banner mb-3">
                        <img src={selectedBrand && selectedBrand.brandBannerImages ? getCurrentDomain() + selectedBrand.brandBannerImages : "/assets/imgs/Trigger-Craft-Logo.png"} alt={selectedBrand && selectedBrand.title} className="" />
                    </div>
                    :
                    <div className="text-center brand-image mb-3">
                        <img src={selectedBrand && selectedBrand.brandImages ? getCurrentDomain() + selectedBrand.brandImages : "/android-chrome-192x192.png"} alt={selectedBrand && selectedBrand.title} className="brand-logo" />
                    </div>
                }
                <div className="d-flex justify-content-between mb-2">
                    <div className="toggle-container">
                        <div className="d-none">
                        <strong>All Products &nbsp;</strong>
                    <div className="toggle-switch">
                            <input type="checkbox" className="checkbox" name="stock" id="stock" checked={showOutOfStock} onChange={toggleStock} />
                        <label className="label" htmlFor="stock">
                            <span className="inner" />
                            <span className="switch" />
                        </label>
                    </div>
                        <strong> &nbsp; In Stock Only</strong>
                </div>
                </div>
                <div className="text-end">
                    <form action="#">
                        <label htmlFor="sort">Sort:</label>
                        <select name="sort" id="sort" className="sort-selection--style" onChange={handleOptionChange} >
                            <option value="lowest">Price(lowest)</option>
                            <option value="highest">Price(highest)</option>
                            <option value="a-z">Title(a-z)</option>
                            <option value="z-a">Title(z-a)</option>
                        </select>
                    </form>
                    </div>
                </div>
                <hr />
                {filteredProducts.length === 0 ? (
                    <p>No products for <strong>{selectedBrand && selectedBrand.title}</strong></p>
                ) : (
                    currentProducts.map((product) => (
                        <div className="d-flex col-md-6 col-lg-3 justify-content-evenly mb-3" key={product.id}>
                            <ProductCard
                                product={product}
                                id={product.id}
                                status={product.stock}
                                imageURL={product.images ? product.images.split(", ")[0] : "/assets/imgs/Trigger-Craft-Logo.png"}
                                currPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date() && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.sPrice : product.price}
                                prevPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date() && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.price : product.sPrice}
                                title={product.title}
                                description={product.shortDescription}
                            />
                        </div>
                    )))}
                {filteredProducts.length > productsPerPage && (<div className="pagination-container pagination">
                    <button onClick={() => handlePageChange(currentPage - 1)} style={beforePseudoStyle} disabled={currentPage === 1} className="pagination-newer">
                        Previous
                    </button>
                    {Array.from({ length: Math.min(visiblePageCount, totalPages) }).map((_, index) => {
                        const pageNumber = startPage + index;
                        return (
                            pageNumber > 0 &&
                            pageNumber <= totalPages && (
                                <button
                                    style={beforePseudoStyle}
                                    key={pageNumber}
                                    onClick={() => handlePageChange(pageNumber)}
                                    disabled={currentPage === pageNumber}
                                    className={currentPage === pageNumber ? "pagination-active ellipsis-button" : "ellipsis-button"}
                                >
                                    {pageNumber}
                                </button>
                            )
                        );
                    })}

                    <button onClick={() => handlePageChange(currentPage + 1)} style={beforePseudoStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} disabled={currentPage === totalPages} className="pagination-older">
                        Next
                    </button>
                </div>)}
            </div>
            <FixedMessenger />
        </>
    );
};

export default ProductBrandPage;