import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../helpers/Context";
import { getCurrentDomain } from "../helpers/Lookup";
import FixedMessenger from "./FixedMessenger";

const BrandPage = () => {
    const navigate = useNavigate();
    const { getBrands, brands } = useContext(Context);
    const goToBrand = (data) => {
        navigate(data);
    };
    useEffect(() => {
        document.title = "Brands :: TriggerCraft";
        getBrands();
    }, [])
    //console.log(brands)
    return (
        <div>
            <nav className="mb-3 crumbs">
                <Link to="/" className="breadcrumb-active"><small>Home</small>
                </Link>
                <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
                <small className="breadcrumb-not-active">Brands</small>
            </nav>
            <h1 className="mb-4">Brands</h1>
            <hr className="bg-success"/>
            <div className="row px-2 mb-3">
                {brands.map((brand) => {
                    return (
                        <div className="col-md-6 col-lg-3 d-flex flex-column gap-2 align-items-center mb-3 brand-container" onClick={e => goToBrand(`/brands/products/${brand.slug}`)} key={brand.id}>
                            <img src={brand.brandImages ? getCurrentDomain() + brand.brandImages : "/android-chrome-192x192.png"} alt={brand.title} className="brand-logo" />
                            <div className="brand-name">{brand.title}</div>
                        </div>
                    )
                })}
            </div>
            <FixedMessenger />
        </div>
    );
};

export default BrandPage;