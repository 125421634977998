import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, Edit, Toolbar, ToolbarItems } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { HtmlEditor, Image, Inject, Count, Link, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { Context } from '../../helpers/Context';

export class ContactMessages extends Component {
    static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-eye e-icons' } },
    ];

    this.fullToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', '|', 'CreateLink', 'Image', '|', 'ClearFormat',
        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
      ],
      type: 'Expand'
    };

    this.simpleToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', '|', 'ClearFormat', 'Undo', 'Redo', '|', 'SourceCode'],
      type: 'Expand'
    };


    this.state = {
      editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      UserId: 0,
      ReasonId: 0,
      Firstname: '',
      Surname: '',
      Email: '',
      Mobile: '',
      IPAddress: '',
      DateSent: new Date(),
      DateAttended: new Date(),
      AttendedBy: 0,
      Status: 0,
      Message: '',
        Title: '',
        AdminName: '',

    };
    this.toggle = this.toggle.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "Contact Messages :: TriggerCraft";
    this.loadData();
  }

  editItem = (id) => {
    if (id > 0) {
        const data = this.state.editData.find((item) => { return item.id == id });
        console.log(data)
        this.setState({ Id: data.id, UserId: data.userId, ReasonId: data.reasonId, Firstname: data.firstname, Title: data.title, Surname: data.surname, AdminName: data.adminName, Email: data.email, Mobile: data.mobile, IPAddress: data.iPAddress, DateSent: data.dateSent, DateAttended: data.dateAttended, AttendedBy: data.attendedBy, Status: data.status, Message: data.message, });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        UserId: 0,
        ReasonId: 0,
        Firstname: '',
        Surname: '',
        Email: '',
        Mobile: '',
        IPAddress: '',
        DateSent: new Date(),
        DateAttended: new Date(),
        AttendedBy: 0,
        Status: 0,
        Message: '',
        Title: '',
          AdminName: '',
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    //valid &= fieldRequired(this.state.Firstname, 'tbFirstnameError', '* required');
    //valid &= fieldRequired(this.state.Surname, 'tbSurnameError', '* required');
    //valid &= fieldRequired(this.state.Email, 'tbEmailError', '* required');
    //valid &= fieldRequired(this.state.Mobile, 'tbMobileError', '* required');
    //valid &= fieldRequired(this.state.IPAddress, 'tbIPAddressError', '* required');
    //valid &= fieldRequired(this.state.DateSent, 'tbDateSentError', '* required');
    //valid &= fieldRequired(this.state.Message, 'tbMessageError', '* required');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.id == this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.state.AddedBy = data.addedBy;
        this.state.DateAdded = data.dateAdded;
        this.state.Id = data.id;
        this.state.Status = data.status;
      }
      else {
        this.state.Status = 1;
      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='Firstname'  width='100' headerText="First Name" />
          <ColumnDirective field='Surname' width='100' headerText="Last Name" />
          <ColumnDirective field='Title' width='100' headerText="Subject" />
          <ColumnDirective field="DateSent" width="100" headerText="Date Sent" template={(props) => { return (<span>{new Date(props.dateSent).toDateString()}</span>); }} />
          <ColumnDirective field='AdminName' width='100' headerText="Attended By" />
          <ColumnDirective field='DateAttended' width='100' headerText="Date Attended" template={(props) => { return (<span>{props.attendedBy ? new Date(props.dateAttended).toDateString() : ""}</span>); }} />
          <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : ContactMessages.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
      const { handleNavigate } = this.context;
      console.log(this.state)
    return (
      <>
        <div className="container">
          <Row>
            <Col xs={6}>
              <h1>Contact Messages</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
                <Button className="" color="dark" outline size="sm" data-recordid="0" onClick={e => handleNavigate(-1)}><i className="fas fa-chevron-left mr-2"></i> Back To Admin</Button>
              {/*<Button color="primary" size="sm" data-recordid="0" onClick={this.editItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>*/}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Customer Contact Message</ModalHeader>
          <ModalBody>

                    <div className="contact-details">
                        <div>
                            <strong>Contact Reason:</strong> {this.state.Title}
                        </div>
                        <div>
                            <strong>First Name:</strong> {this.state.Firstname}
                        </div>
                        <div>
                            <strong>Surname:</strong> {this.state.Surname}
                        </div>
                        <div>
                            <strong>Email Address:</strong> {this.state.Email}
                        </div>
                        <div>
                            <strong>Mobile Number:</strong> {this.state.Mobile}
                        </div>
                        <div>
                            <strong>IP Address:</strong> {this.state.IPAddress ? this.state.IPAddress : "N/A"}
                        </div>
                        <div>
                            <strong>Date Sent:</strong> {new Date(this.state.DateSent).toLocaleString()}
                        </div>
                        <div>
                            <strong>Date Attended:</strong> {this.state.DateAttended ? new Date(this.state.DateAttended).toLocaleString() : "N/A"}
                        </div>
                        <div>
                            <strong>Attended By:</strong> {this.state.AttendedBy ? this.state.AdminName : "N/A"}
                        </div>
                        <div>
                            <strong>Message:</strong>
                            <br/>
                            {this.state.Message}
                        </div>
                    </div>
            {/*<div className='mb-3'>*/}
            {/*  <NumericTextBoxComponent id='tbAttendedBy' name='tbAttendedBy' placeholder='Attended By' floatLabelType='Auto' showClearButton={true} value={this.state.AttendedBy} onChange={e => this.setState({ AttendedBy: e.target.value })} /> <div id='tbAttendedByError' className='error-message' />*/}
            {/*</div>*/}

          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
                    {!this.state.DateAttended && <Button style={{ background: "#74bc1f", color: "#fff" }} size="sm" onClick={this.saveItem}>Mark as attended <i className="far fa-check-circle ms-2"></i></Button> }
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/contactmessages', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });
      const userId = getUserDetails().id
    var bearer = 'Bearer ' + getAuthToken();
      var data = { Id: this.state.Id, Id: this.state.Id, UserId: this.state.UserId, ReasonId: this.state.ReasonId, Firstname: this.state.Firstname, Surname: this.state.Surname, Email: this.state.Email, Mobile: this.state.Mobile, IPAddress: this.state.IPAddress ? this.state.IPAddress : "", DateSent: this.state.DateSent, DateAttended: new Date(), AttendedBy: userId, Status: this.state.Status, Message: this.state.Message, }

    try {
      const response = await fetch('api/contactmessages', {
        method: 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Contact Messages', content: 'The contact messages was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Contact Messages', content: 'There was an error saving the contact messages!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/contactmessages/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Contact Messages', content: 'The contact messages was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Contact Messages', content: 'There was an error deleting the contact messages!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

