import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Context } from '../helpers/Context';
import FixedMessenger from './FixedMessenger';
import { ProductCard } from './ProductCard';

const ProductPage = (props) => {
    const [loading, setLoading] = useState(true);
    const [isDivVisible, setIsDivVisible] = useState(window.innerWidth >= 800);
    const [maxPrice, setMaxPrice] = useState(100000);
    const [selectedCategories, setSelectedCategories] = useState(JSON.parse(localStorage.getItem('selectedCategories')) || []);
    const [collapsedCategories, setCollapsedCategories] = useState(JSON.parse(localStorage.getItem('collapsedCategories')) || []);
    const [sort, setSort] = useState(null);
    const [showOutOfStock, setShowOutOfStock] = useState(true);
    const [selectedOption, setSelectedOption] = useState('lowest');
    const [productsPerPage] = useState(9);
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);

    const handleCategoryChange = (event) => {
        const categoryId = event.target.id;
        if (selectedCategories.includes(categoryId)) {
            localStorage.setItem('selectedCategories', JSON.stringify(selectedCategories.filter(id => id !== categoryId)));
            setSelectedCategories(selectedCategories.filter(id => id !== categoryId));
        } else {
            localStorage.setItem('selectedCategories', JSON.stringify([...selectedCategories, categoryId]));
            setSelectedCategories([...selectedCategories, categoryId]);
        }
    };

    const handleDeselectAll = () => {
        setSelectedCategories([]);
        setCollapsedCategories([]);
        localStorage.removeItem('selectedCategories');
        localStorage.removeItem('collapsedCategories');
        setMaxPrice(100000)
    };

    const toggleCategory = (categoryId) => {
        if (collapsedCategories.includes(categoryId)) {
            localStorage.setItem('collapsedCategories', JSON.stringify(collapsedCategories.filter(id => id !== categoryId)));
            setCollapsedCategories(collapsedCategories.filter((id) => id !== categoryId));
        } else {
            localStorage.setItem('collapsedCategories', JSON.stringify([...collapsedCategories, categoryId]));
            setCollapsedCategories([...collapsedCategories, categoryId]);
        }
    };

    const { products, categories, subcategories, getProducts, getCategories, getSubcategories } = useContext(Context);
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    let filteredProducts = products
        .filter(product => selectedCategories.length === 0 || selectedCategories.some(categoryId => product.selectedCategories.includes(categoryId)));

    filteredProducts = filteredProducts.filter(
        (product) => product.price <= maxPrice || (product.sPrice && product.sPrice <= maxPrice)
    );

    if (selectedOption === 'lowest') {
        filteredProducts = filteredProducts.sort((a, b) => {
            const aPrice = a.sPrice || a.price;
            const bPrice = b.sPrice || b.price;
            return aPrice - bPrice;
        });
    } else if (selectedOption === 'highest') {
        filteredProducts = filteredProducts.sort((a, b) => {
            const aPrice = a.sPrice || a.price;
            const bPrice = b.sPrice || b.price;
            return bPrice - aPrice;
        });
    } else if (selectedOption === 'a-z') {
        filteredProducts = filteredProducts.sort((a, b) => a.title.localeCompare(b.title));
    } else if (selectedOption === 'z-a') {
        filteredProducts = filteredProducts.sort((a, b) => b.title.localeCompare(a.title));
    }

    if (showOutOfStock) {
        filteredProducts = filteredProducts.filter(item => item.stock > 0);
    } else if (!showOutOfStock) {
        filteredProducts = filteredProducts.filter(item => item);
    }

    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
    const visiblePageCount = 7;

    const getStartPage = () => {
        if (currentPage <= Math.ceil(visiblePageCount / 2)) {
            return 1;
        } else if (currentPage >= totalPages - Math.floor(visiblePageCount / 2)) {
            return totalPages - visiblePageCount + 1;
        } else {
            return currentPage - Math.floor(visiblePageCount / 2);
        }
    };

    const startPage = getStartPage();

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const toggleDiv = () => {
        setIsDivVisible(!isDivVisible);
    };

    const toggleStock = () => {
        setShowOutOfStock((prevShowOutOfStock) => !prevShowOutOfStock);
    };
    useEffect(() => {
        document.title = "Products :: TriggerCraft";
        getProducts().then(() => setLoading(false));
        getCategories();
        getSubcategories();
        // getProducts().then(() => setLoading(false));

        const handleResize = () => {
            setIsDivVisible(window.innerWidth >= 768);
        };
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [])
    //console.log(selectedCategories)
    return (
        <>
            <nav className="mb-3 crumbs">
                <Link to="/" className="breadcrumb-active"><small>Home</small>
                </Link>
                <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
                <small className="breadcrumb-not-active">All Products</small>
            </nav>
            <h1 className="mb-4">Products</h1>
            <div className="products">
                <button onClick={toggleDiv} className="btn mb-3">Filters</button>
                {isDivVisible && (<div className="product-left">
                    <h3>Filter</h3>
                    <div className="filter-item">
                        <button onClick={handleDeselectAll}>Reset Filters</button>
                        <h5>Product Categories</h5>
                        {categories.map((category) => (
                            <div className="category" key={category.id}>
                                <div className="category-header d-flex justify-content-between align-items-center" onClick={() => toggleCategory(category.id)}>
                                    <strong>{category.title}</strong><span className="mx-5">{collapsedCategories.includes(category.id) ? '-' : '+'}</span>
                                </div>
                                <div className={`subcategory-list ${collapsedCategories.includes(category.id) ? 'collapsed' : ''}`}>
                                    {subcategories
                                        .filter((sub) => sub.categoryId === category.id)
                                        .map((sub) => (
                                            <div className="subcategory" key={sub.id}>
                                                <input
                                                    type="checkbox"
                                                    id={sub.id}
                                                    checked={selectedCategories.includes(sub.id.toString())}
                                                    onChange={(e) => handleCategoryChange(e)}
                                                />
                                                <label htmlFor={sub.id}>{sub.title}</label>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="filter-item">
                        <h5>Filter By Price</h5>
                        <div className="range-slider">
                            <span>0</span>
                            <input className="rs-range" type="range" min={0} max={100000} value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} />
                            <span>{maxPrice}</span>
                        </div>
                    </div>

                    

                </div>)}
                <div className="product-container row product-right col-lg-9 col-md-12 px-2">
                    <div className="d-flex justify-content-between mb-2">
                        <div className="toggle-container">
                            <div className="d-none">
                            <strong>All Products &nbsp;</strong>
                            <div className="toggle-switch">
                                <input type="checkbox" className="checkbox" name="stock" id="stock" checked={showOutOfStock} onChange={toggleStock} />
                                <label className="label" htmlFor="stock">
                                    <span className="inner" />
                                    <span className="switch" />
                                </label>
                            </div>
                            <strong> &nbsp; In Stock Only</strong>
                        </div>
                        </div>
                        <div className="text-end">
                            <form action="#">
                                <label htmlFor="sort">Sort:</label>
                                <select name="sort" id="sort" className="sort-selection--style" onChange={handleOptionChange} >
                                    <option value="lowest">Price(lowest)</option>
                                    <option value="highest">Price(highest)</option>
                                    <option value="a-z">Title(a-z)</option>
                                    <option value="z-a">Title(z-a)</option>
                                </select>
                            </form>
                        </div>
                    </div>
                    <hr />
                    {loading ? <span className="text-center"><i className="fa-solid fa-spinner"></i>&nbsp; Loading...</span> : filteredProducts.length ? currentProducts.map((product) => (
                        <div className="col-md-6 col-lg-4 d-flex flex-wrap justify-content-evenly mb-3" key={product.id}>
                            <ProductCard
                                product={product}
                                imageURL={product.images ? product.images.split(", ")[0] : "/assets/imgs/Trigger-Craft-Logo.png"}
                                currPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.sPrice : product.price}
                                prevPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.price : product.sPrice}
                            />
                        </div>
                    )) : <p>No Products Found</p>}
                    {!loading && filteredProducts.length ? <div className="pagination-container pagination">
                        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="pagination-newer">
                            Previous
                        </button>

                        {Array.from({ length: Math.min(visiblePageCount, totalPages) }).map((_, index) => {
                            const pageNumber = startPage + index;
                            return (
                                pageNumber > 0 &&
                                pageNumber <= totalPages && (
                                    <button
                                        key={pageNumber}
                                        onClick={() => handlePageChange(pageNumber)}
                                        disabled={currentPage === pageNumber}
                                        className={currentPage === pageNumber ? "pagination-active ellipsis-button" : "ellipsis-button"}
                                    >
                                        {pageNumber}
                                    </button>
                                )
                            );
                        })}

                        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="pagination-older">
                            Next
                        </button>
                    </div> : ""}
                </div>
            </div>
            <FixedMessenger />
        </>
    );
};

export default ProductPage;