import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, FormGroup, FormText, Input, Label } from "reactstrap";
import { getAuthToken, getUserDetails } from '../helpers/authentication';
import { fieldRequired } from '../helpers/validation';
import FixedMessenger from './FixedMessenger';
//import { setIsPCLogin } from '../helpers/Lookup';

export class PersonalInformation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      Id: 0,
      UserId: '',
      UserType: 0,
      FirstName: '',
      LastName: '',
      EmailAddress: '',
      EmailConfirmed: false,
      CurrentPassword: '',
      LastPassword: '',
      Mobile: '',
      Communication: 0,
      Credit: 0,
      Status: 0,
      DateAdded: new Date(),
      DateModified: new Date(),
      AddedBy: 0,
      DateVerified: new Date(),
      LastLogin: new Date(),
      FailedLogins: 0,
      LockedOut: false,
      LockOutDate: new Date(),
      ResetPassword: false,
      LastIP: '',
      LoginCookie: '',
      Checked: false,
      Checked2: false
    };
    this.saveData = this.saveData.bind(this);
  }

  componentDidMount() {
    document.title = "Pesonal Information :: TriggerCraft";
    this.loadData();
  }

  passwordFieldRequired = (condition, errorId, errorMessage) => {
    const errorElement = document.getElementById(errorId);
    if (errorElement) {
      errorElement.style.display = condition ? 'none' : 'inline';
      errorElement.textContent = condition ? '' : errorMessage;
    }
  };

  render() {
    //console.log(this.state)
    return (
      <div className='container'>
        <nav className="mb-3 crumbs">
          <Link to="/" className="breadcrumb-active"><small>Home</small>
          </Link>
          <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
          <small className="breadcrumb-not-active">Personal Information</small>
        </nav>
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className=" rounded mb-3">
              <div className="card-body">
                <h1 className="mb-4">Update Profile</h1>
                <div className="row">
                  <FormGroup className="col-md-6">
                    <Label>
                      First Name
                      <span className="required-icon">*</span>
                      <span id="errFirstName" className="required-icon" />
                    </Label>
                    <Input
                      type="text"
                      bsSize="sm"
                      value={this.state.FirstName}
                      onChange={(e) => {
                        this.setState({ FirstName: e.target.value })
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-6">
                    <Label>
                      Last Name
                      <span className="required-icon">*</span>
                      <span id="errLastName" className="required-icon" />
                    </Label>
                    <Input
                      type="text"
                      bsSize="sm"
                      value={this.state.LastName}
                      onChange={(e) => {
                        this.setState({ LastName: e.target.value })
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-8">
                    <Label>
                      Email
                    </Label>
                    <Input
                      type="email"
                      bsSize="sm"
                      value={this.state.EmailAddress}
                      onChange={(e) => {
                        this.setState({ EmailAddress: e.target.value })
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-4">
                    <Label>
                      Mobile
                    </Label>
                    <Input
                      type="text"
                      bsSize="sm"
                      value={this.state.Mobile}
                      onChange={(e) => {
                        this.setState({ Mobile: e.target.value })
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-6">
                    <Label>
                      Password
                    </Label>
                    <div className="input-group">
                      <Input
                        type={this.state.Checked ? 'text' : 'password'}
                        bsSize="sm"
                        onChange={(e) => {
                          this.setState({ CurrentPassword: e.target.value })
                        }}
                      />
                      <span className="input-group-text bg-dark text-light" style={{ cursor: "pointer" }} onClick={() => { this.setState({ Checked: !this.state.Checked }) }} id="basic-addon">{this.state.Checked ? <i className="fas fa-eye-slash mx-0" title="Hide password"></i> : <i className="fas fa-eye mx-0" title="Show password"></i>}</span>
                    </div>
                  </FormGroup>
                  <FormGroup className="col-md-6">
                    <Label>
                      Confirm Password
                      
                      {/*<span className="required-icon">*</span>*/}
                      <span id="errConfirmPassword" className="required-icon" />
                    </Label>
                    <div className="input-group">
                      <Input
                        type={this.state.Checked2 ? 'text' : 'password'}
                        bsSize="sm"
                        onChange={(e) => {
                          this.setState({ LastPassword: e.target.value === this.state.CurrentPassword });
                          this.passwordFieldRequired(e.target.value === this.state.CurrentPassword, "errConfirmPassword", "Password doesn't match");
                        }}
                      />
                      <span className="input-group-text bg-dark text-light" style={{ cursor: "pointer" }} onClick={() => { this.setState({ Checked2: !this.state.Checked2 }) }} id="basic-addon">{this.state.Checked2 ? <i className="fas fa-eye-slash mx-0" title="Hide password"></i> : <i className="fas fa-eye mx-0" title="Show password"></i>}</span>
                    </div>
                    {/*<span className="small text-muted">Password entered here should match the one in the above password field.</span>*/}
                    {/*<FormText color="muted">*/}
                    {/*  Password entered here should match the one in the above password field.*/}
                    {/*</FormText>*/}
                  </FormGroup>
                  
                </div>
                <hr></hr>
                <div className="text-end mb-3">
                  <Button color='success' size='sm' onClick={this.saveData} outline ><i className="far fa-check-circle me-2"></i> Save Changes </Button>
                </div>
                {/*<hr />*/}
              </div>
            </div>
          </div>
        </div>
        <FixedMessenger />
      </div>
    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/userdata/' + getUserDetails().id, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        console.log(data)
        /*data = data.find((item) => { return item.id == getUserDetails().id });*/

        this.setState({ Id: data.id, UserId: data.userId, UserType: data.userType, FirstName: data.firstName, LastName: data.lastName, EmailAddress: data.emailAddress, EmailConfirmed: data.emailConfirmed, Mobile: data.mobile, Communication: data.communication, Credit: data.credit, Status: data.status, DateAdded: data.dateAdded, DateModified: data.dateModified, AddedBy: data.addedBy, DateVerified: data.dateVerified, LastLogin: data.lastLogin, FailedLogins: data.failedLogins, LockedOut: data.lockedOut, LockOutDate: data.lockOutDate, ResetPassword: data.resetPassword, LastIP: data.lastIP, LoginCookie: data.loginCookie, loading: false });
        //this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData() {

    let isValid = true;
    isValid &= fieldRequired(this.state.FirstName, "errFirstName", " required");
    isValid &= fieldRequired(this.state.LastName, "errLastName", " required");

    if (isValid) {
      this.setState({ loading: true, showError: false, showSuccess: false });

      var bearer = 'Bearer ' + getAuthToken();
      var data = { Id: this.state.Id, UserId: this.state.UserId, UserType: this.state.UserType, FirstName: this.state.FirstName, LastName: this.state.LastName, EmailAddress: this.state.EmailAddress, EmailConfirmed: this.state.EmailConfirmed, CurrentPassword: this.state.CurrentPassword, LastPassword: this.state.LastPassword, Mobile: this.state.Mobile, Communication: this.state.Communication, Credit: this.state.Credit, Status: this.state.Status, DateAdded: this.state.DateAdded, DateModified: this.state.DateModified, AddedBy: this.state.AddedBy, DateVerified: this.state.DateVerified, LastLogin: this.state.LastLogin, FailedLogins: this.state.FailedLogins, LockedOut: this.state.LockedOut, LockOutDate: this.state.LockOutDate, ResetPassword: this.state.ResetPassword, LastIP: this.state.LastIP, LoginCookie: this.state.LoginCookie, }
      //console.log(data);
      try {
        const response = await fetch('api/userdata/updateuser', {
          method: 'PUT',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          await response.json();
          this.loadData();
          ToastUtility.show({
            title: 'Pesonal Information', content: 'The user data was successfully updated!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
          });
        }
        else {
          console.log(response.status + ": " + response.statusText);
          if (response.status === 401)
            this.props.history.push("/login");
        }

      } catch (e) {
        console.error(e);
        this.setState({ loading: false });
        ToastUtility.show({
          title: 'Pesonal Information', content: 'There was an error updating the user data!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
    }
  }
}