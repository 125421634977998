import React, { Component } from 'react'
import { Table } from 'reactstrap';
import { getAuthToken } from '../../../helpers/authentication';

export default class SalesPerfomance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.PerformanceData,
            loading: true
        }

        this.renderTableRows = this.renderTableRows.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.PerformanceData.length != this.props.PerformanceData.length)
            this.loadData();
    }

    loadData = async () => {
        this.setState({ data: this.props.PerformanceData, loading: false });
    }

    renderTableRows = (data) => {
        return (
            <div className="card mb-3">
                <div className="card-header bg-light text-center">
                    <strong>Sales Performance KPIs</strong>
                </div>
                <div className="px-4">
                    <div className="table-responsive">
                        <Table>
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col" className="fw-bold text-end">Product Detail Views</th>
                                    <th scope="col" className="fw-bold text-end">Unique Purchases</th>
                                    <th scope="col" className="fw-bold text-end">Quantity</th>
                                    <th scope="col" className="fw-bold text-end">Product Revenue</th>
                                    <th scope="col" className="fw-bold text-end">Avg. Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row" className="fw-bold">Value</th>
                                    <td className="text-end">{this.renderChangeStatus(0, data.totalViews)}</td>
                                    <td className="text-end">{this.renderChangeStatus(0, data.totalUniquePurchase)}</td>
                                    <td className="text-end">{this.renderChangeStatus(0, data.totalQuantity)}</td>
                                    <td className="text-end">{this.renderChangeStatus(0, data.totalProductRevenue.toFixed(2), true)}</td>
                                    <td className="text-end">{this.renderChangeStatus(0, data.totalAvgPrice.toFixed(2), true)}</td>
                                </tr>

                                <tr className='d-none'>
                                    <th scope="row" className="fw-bold">Value</th>
                                    <td className="text-end">{this.renderChangeStatus(data.productViewsTotalChange, data.startProductViews)}</td>
                                    <td className="text-end">{this.renderChangeStatus(data.productViewsTotalChange, data.startUniquePurchases)}</td>
                                    <td className="text-end">{this.renderChangeStatus(data.quantityTotalChange, data.startQuantity)}</td>
                                    <td className="text-end">{this.renderChangeStatus(data.productRevenueTotalChange, data.startProductRevenue.toFixed(2), true)}</td>
                                    <td className="text-end">{this.renderChangeStatus(data.avgPriceTotalChange, data.startAvgPrice.toFixed(2), true)}</td>
                                </tr>

                                <tr className='d-none'>
                                    <th scope="row" className="fw-bold">Percentage change</th>
                                    <td className="text-end"> {this.renderChangeStatus(data.productViewsTotalChange, data.productViewsPercentage)}</td>
                                    <td className="text-end">{this.renderChangeStatus(data.productViewsTotalChange, data.uniquePurchasesPercentage)}</td>
                                    <td className="text-end">{this.renderChangeStatus(data.quantityTotalChange, data.quantityPercentage)}</td>
                                    <td className="text-end">{this.renderChangeStatus(data.productRevenueTotalChange, data.productRevenuePercentage)}</td>
                                    <td className="text-end">{this.renderChangeStatus(data.avgPriceTotalChange, data.avgPricePercentage)}</td>
                                </tr>

                                <tr className='d-none'>
                                    <th scope="row" className="fw-bold">Absolute change</th>
                                    <td className="text-end">{this.renderChangeStatus(data.productViewsTotalChange, data.productViewsTotalChange)}</td>
                                    <td className="text-end">{this.renderChangeStatus(data.productViewsTotalChange, data.productViewsTotalChange)}</td>
                                    <td className="text-end">{this.renderChangeStatus(data.quantityTotalChange, data.quantityTotalChange)}</td>
                                    <td className="text-end">{this.renderChangeStatus(data.productRevenueTotalChange, data.productRevenueTotalChange.toFixed(2), true)}</td>
                                    <td className="text-end">{this.renderChangeStatus(data.avgPriceTotalChange, data.avgPriceTotalChange.toFixed(2), true)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        );
    };

    renderChangeStatus(value, text, isMoney) {
        if (value > 0)
            return <span className='text-success'><i className="fa fa-caret-up"></i> {isMoney && <b>R</b>}{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
        else if (value < 0)
            return <span className='text-danger'><i className="fa fa-caret-down"></i> {isMoney && <b>R</b>}{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
        else
            return <span className='text-dark'>{isMoney && <>R</>}{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
    }

    render() {
        const content = this.state.loading ? <em>Loading..</em> : this.renderTableRows(this.state.data);
        return (
            content
        )
    }
}
