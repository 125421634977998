import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import { Context } from '../helpers/Context';
import { getCurrentDomain } from '../helpers/Lookup';

const Hero = () => {
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const { getHomeBanners, heroData } = useContext(Context);

    const items = [
        {
            src: 'https://triggercraft.co.za/wp-content/uploads/2022/01/Ad-blocks-Lucid-1.png',
            altText: 'Slide 1',
            caption: 'Slide 1'
        },
        {
            src: 'https://triggercraft.co.za/wp-content/uploads/2021/01/PDP-SWT-BUNDLE01_01.png',
            altText: 'Slide 2',
            caption: 'Slide 2'
        },
        {
            src: 'https://triggercraft.co.za/wp-content/uploads/2022/02/BIO-Z490GTA-EVO_04-1.png',
            altText: 'Slide 3',
            caption: 'Slide 3'
        },
        {
            src: 'https://triggercraft.co.za/wp-content/uploads/2021/01/basilisk-x-hyperspeed-2019-render01_optimized-e1612269788625.png',
            altText: 'Slide 3',
            caption: 'Slide 3'
        }
    ];

    const onExiting = () => {
        setAnimating(true);
    };

    const onExited = () => {
        setAnimating(false);
    };

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === heroData.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? heroData.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = heroData.map((item) => (
        <CarouselItem
            onExiting={onExiting}
            onExited={onExited}
            key={item.id}
        >
            <div className="d-flex hero-parent align-items-center row">
                <div onClick={() => item.actionType && item.siteSection === 1 ? navigate(`/brands/products/${item.actionType}`) : item.actionType && item.siteSection === 2 ? navigate(`/products/category/${item.actionType}`) : navigate("/products")} style={{ cursor: "pointer" }} className="col-md-12 text-center">
                    <div className="image-container">
                        <img src={getCurrentDomain() + item.filename} alt="" />
                        {item.actionData && <div className="overlay"></div>}
                    </div>
                </div>
                {item.actionData && (
                    <div className="col-md-6 mb-3 text-light hero-child text-start">
                        <h3>{item.title}</h3>
                        <div className="mb-3 row">
                            <span className="col-md-10" style={{ letterSpacing: "1px" }}>{item.actionData}</span>
                            <div className="col-md-2 hide"><span>&nbsp;</span></div>
                        </div>
                        <button className="hero-button" onClick={() => item.actionType && item.siteSection === 1 ? navigate(`/brands/products/${item.actionType}`) : item.actionType && item.siteSection === 2 ? navigate(`/products/category/${item.actionType}`) : navigate("/products")}>Shop Now</button>
                    </div>
                )}
            </div>
        </CarouselItem>
    ));
    useEffect(() => {
        getHomeBanners();
    }, [])
    //console.log(heroData)
    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            /*interval={10000}*/
           pause={"hover"}      >
            <CarouselIndicators items={heroData} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
    );
};
export default Hero;