import React, { useContext, useEffect, useState } from 'react'
import { Context } from "../../helpers/Context";
import { BorderWidthIcon } from '@radix-ui/react-icons';

function PayFastComplete() {

  const { CheckPaymentStatus, ResetUserCart } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState("");


  useEffect(() => {
    loadData();
  }, [loading]);

  const loadData = () => {
    CheckPaymentStatus().then(res => {
      //if (res === "success")
      ResetUserCart();

      setPaymentStatus(res);
      setLoading(false);
    });

  }

  const RenderSpinner = () => {
    return (
      <>
        <h3>Thank you your payment is currently being processed.<br />
          You will shortly be notified of the order status...<br /></h3>
        <div className="spinner-border text-primary my-3" role="status" style={{ width: "100px", height: "100px", fontWeight: 800 }}>
        </div>
        <h3>Thank you for transacting with TriggerCraft!</h3>
      </>
    )
  }

  const RenderPaymentStatus = () => {
    return (
      paymentStatus === "success" ?
        <><i className='far fa-check-circle fa-5x mb-4 mt-3 text-success'></i>
          <h3>Thank you, your payment has been received!</h3>
        </>
        :
        <><i className='far fa-triangle-exclamation fa-5x mb-4 mt-3 text-success'></i>
          <h3>Order processing,<br />please check your email for confirmation.</h3>
        </>)
  }

  const content = loading ? RenderSpinner() : RenderPaymentStatus();


  return (
    <div className='col-md-8 mx-auto '>
      <div className="card">
        <div className="card-body text-center">
          {content}
        </div>
      </div>
    </div>
  )
}

export default PayFastComplete