import React from 'react';
import { useNavigate } from "react-router-dom";

const PromotionsCard = ({ title, description, imageUrl, buttonText, code, test }) => {
    const navigate = useNavigate();
    return (
        <div className="promotions-card">
            <img src={imageUrl} alt={title} className="promotions-image" />
            <div className="promotions-content">
                <h2 className="promotions-title">{title}</h2>
                <strong>CODE: {code}</strong>
                <p className="promotions-description">{description}</p>
                <button onClick={!test ? () => navigate("/products") : console.log(test)} className="promotions-button">{buttonText}</button>
            </div>
        </div>
    );
};

export default PromotionsCard;
