/* eslint-disable no-case-declarations */
import { ColumnDirective, ColumnsDirective, Edit, GridComponent, Inject, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAuthToken } from '../../../helpers/authentication';
import { SearchGridToolbar } from '../../../helpers/Global';
import moment from 'moment';

export default function StockUpdates(props) {

  const [updateData, setUpdateData] = useState();
  const [editId, setEditId] = useState(0);

  const [dateUpdated, setDateUpdated] = useState();
  const [alertClass, setAlertClass] = useState('');

  const [editModal, setEditModal] = useState(false);

  const toggle = () => setEditModal(!editModal);


  const loadData = async () => {
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('/api/stockupdates', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setDateUpdated(data.dateUpdated);
        const status = JSON.parse(data.stockStatus);
        //console.log(status.filter((p) => p.Exists));
        setUpdateData(status.filter((p) => p.Exists));
        const timeDifference = moment().diff(moment(data.dateUpdated), 'hours');

        // Determine the alert class based on time difference
        const alert =
          timeDifference >= 2 ? 'alert-danger' :
            timeDifference >= 1 ? 'alert-warning' :
              'alert-success';
        setAlertClass(alert);
        //console.log(JSON.parse(data.stockStatus)); 
        //setDateUpdated(data.dateUpdated);
        //setUpdateData(data.stockStatus);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }


  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <div className={`alert ${alertClass} text-center shadow-3`} role="alert">
        <b>Last Stock Update: </b>
        {` ${moment(dateUpdated).format('YYYY-MM-DD HH:mm:ss')}`}
        <div className="mt-2">
          <button className="btn btn-sm btn-dark" onClick={ toggle}>View Updates</button>
        </div>
      </div>

      

      <Modal isOpen={editModal} toggle={toggle} scrollable size="lg" backdrop="static">
        <ModalHeader toggle={toggle}>Stock Updates</ModalHeader>
        <ModalBody>
          <GridComponent dataSource={updateData} allowSorting={true} toolbar={SearchGridToolbar} >
            <ColumnsDirective>
              <ColumnDirective field='Code' width='150' headerText="SKU" />
              <ColumnDirective field='Exists' width='100' headerText="Exists on Web" />
              <ColumnDirective field='Updated' width='100' headerText="Updated" />
              <ColumnDirective field='QtyOnWebOld' width='100' headerText="QTY on Web" />
              <ColumnDirective field='QtyOnSage' width='100' headerText="QTY on Sage" />

            </ColumnsDirective>
            <Inject services={[Sort, Edit, Toolbar]} />
          </GridComponent>

        </ModalBody>
        <ModalFooter>
          <Button color="dark" size="sm" onClick={toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
          
        </ModalFooter>
      </Modal>

    </>
  );
}

