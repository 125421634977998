import React, { Component } from 'react';
import { getCurrentDomain } from '../helpers/Lookup';

export class ImageThumbnails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUrls: Array.isArray(props.imageUrls) ? props.imageUrls : props.imageUrls.split(',').map(url => url.trim()).filter(url => url !== ''),
            draggingIndex: null,
        };
    }

    handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const newImageUrl = e.target.result;
                this.setState({ imageUrls: [...this.state.imageUrls, newImageUrl] });
                this.props.onUpdateImageUrls([...this.state.imageUrls, newImageUrl]);
            };
            reader.readAsDataURL(file);
        }
    };

    openImageUploader = () => {
        this.uploadInputRef.click();
    }

    deleteImage = (index) => {
        const updatedImageUrls = [...this.state.imageUrls];
        updatedImageUrls.splice(index, 1);
        this.setState({ imageUrls: updatedImageUrls });
        this.props.onUpdateImageUrls(updatedImageUrls);
    }

    handleDragStart = (index) => {
        this.setState({ draggingIndex: index });
    };

    handleDragOver = (index) => {
        if (this.state.draggingIndex !== null) {
            const draggedImage = this.state.imageUrls[this.state.draggingIndex];
            const updatedImageUrls = [...this.state.imageUrls];
            updatedImageUrls.splice(this.state.draggingIndex, 1);
            updatedImageUrls.splice(index, 0, draggedImage);
            this.setState({ imageUrls: updatedImageUrls, draggingIndex: index });
            this.props.onUpdateImageUrls(updatedImageUrls);
        }
    };

    handleDragEnd = () => {
        this.setState({ draggingIndex: null });
    };

    render() {
        const { imageUrls } = this.state;
        //console.log(this.state, imageUrls.length, this.props.isSingleImage)
        return (
            <div>
                <div className="thumbnails">
                    {imageUrls.map((imageUrl, index) => (
                        <div key={index} className="thumbnail-container" draggable="true" onDragStart={() => this.handleDragStart(index)} onDragOver={() => this.handleDragOver(index)} onDragEnd={this.handleDragEnd}>
                            <img src={imageUrl.includes("\\") ? getCurrentDomain() + imageUrl.replace(/\\/g, "/") : imageUrl} alt={`Thumbnail ${index}`} className="thumbnail" />
                            <i onClick={() => this.deleteImage(index)} className="fa-solid fa-circle-minus" title="Remove Image"></i>
                        </div>
                    ))}
                    {this.props.isSingleImage && imageUrls.length === 0 && (<button onClick={this.openImageUploader}><i className="fa-solid fa-circle-plus fs-1" title="Add New Image"></i></button>) }
                    {!this.props.isSingleImage && (<button onClick={this.openImageUploader}><i className="fa-solid fa-circle-plus fs-1" title="Add New Image"></i></button>) }
                </div>
                <div className="image-uploader thumbnail">
                    <input
                        ref={ref => this.uploadInputRef = ref}
                        type="file"
                        accept="image/*"
                        onChange={this.handleImageUpload}
                        className="upload-input"
                    />
                </div>
            </div>
        );
    }
}