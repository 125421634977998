import React, { Component } from 'react'
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, BarSeries, LineSeries, ColumnSeries, AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip, AccumulationSelection, Selection, ChartAnnotation, AccumulationAnnotation } from '@syncfusion/ej2-react-charts';

export default class ShoppingFunnel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.FunnelData,
            primaryXAxis: { valueType: 'Category' },
        }
    }

    renderCharts = (data, primaryXAxis) => {
        //console.clear();
        //console.log(data);
        return (
            <ChartComponent id='charts' primaryXAxis={primaryXAxis}>
                <Inject services={[BarSeries, Category, DataLabel]} />
                <SeriesCollectionDirective>
                    <SeriesDirective dataSource={data} xName='x' yName='y' type='Bar' fill='#74bc1f' marker={{ dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#333' } } }} />
                </SeriesCollectionDirective>
            </ChartComponent>
        )
    }
    render() {

        const content = this.renderCharts(this.state.data, this.state.primaryXAxis);
        return (
            <div className='card mb-3'>
                <div className="card-header text-center">
                    <strong>Shopping Funnel</strong>
                </div>
                <div className="card-body">
                    {content}
                </div>
            </div>
        )
    }
}
