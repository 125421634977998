import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Context } from '../helpers/Context';
import FixedMessenger from './FixedMessenger';
import { ProductCard } from './ProductCard';
import { getCurrentDomain } from '../helpers/Lookup';
import { getProductPrice } from "../helpers/Global";


const CategoryPage = () => {
  const [loading, setLoading] = useState(true);
  const [isDivVisible, setIsDivVisible] = useState(window.innerWidth >= 800);
  const [maxPrice, setMaxPrice] = useState(100000);
  //const [selectedBrands, setSelectedBrands] = useState(JSON.parse(localStorage.getItem('selectedBrands1')) || []);
  const [sort, setSort] = useState(null);
  const [showOutOfStock, setShowOutOfStock] = useState(true);
  const [selectedOption, setSelectedOption] = useState('lowest');
  const [productsPerPage] = useState(9);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const { category } = useParams();

  let FilterCatName = category.replace(/_/g, " ");
  const { products, brands, categories, subcategories, getProducts, getBrands, getCategories, getSubcategories, selectedBrands, setSelectedBrands } = useContext(Context);

  const handleBrandChange = (event) => {
    const productBrandId = parseInt(event.target.id);
    if (selectedBrands.includes(productBrandId)) {
      localStorage.setItem('selectedBrands1', JSON.stringify(selectedBrands.filter(id => id !== productBrandId)));
      setSelectedBrands(selectedBrands.filter(id => id !== productBrandId));
    } else {
      localStorage.setItem('selectedBrands1', JSON.stringify([...selectedBrands, productBrandId]));
      setSelectedBrands([...selectedBrands, productBrandId]);
    }
  };

  const handleDeselectAll = () => {
    setSelectedBrands([]);
    localStorage.removeItem('selectedBrands1');
    setMaxPrice(100000)
  };

  const parts = window.location.href.split("/");
  const lastWord = parts[parts.length - 1];

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  let filteredProducts;
  if (lastWord === 'products') {
    filteredProducts = products.filter((product) =>
      selectedBrands.length === 0 || selectedBrands.some((productBrandId) => product.productBrandId === productBrandId)
    );
  } else {
    let category = categories.find(c => c.slug === lastWord);
    if (category) {
      FilterCatName = category && category.title;
      let FilterSubcategories = subcategories ? subcategories.filter(c => c.categoryId === category.id).map(c => { return c.id; }) : [];
      filteredProducts = products?.filter((product) => {
        if (product.selectedCategories !== "" || product.selectedCategories) {
          const selectedCategoriesArr = JSON.parse(product.selectedCategories);
          if (selectedCategoriesArr || Array.isArray(selectedCategoriesArr)) {
            //console.log(selectedCategoriesArr);
            return selectedCategoriesArr?.some(id => FilterSubcategories.includes(id));
          } else {
            return false;
          }
        } else
          return false;
      });
    } else {

      category = subcategories.find(c => c.slug === lastWord);
      FilterCatName = category && category.title;
      try {
        filteredProducts = products.filter((product) => {
          if (product.selectedCategories !== "" || product.selectedCategories) {
            const selectedCategoriesArr = JSON.parse(product.selectedCategories);
            //console.log(product.title, selectedCategoriesArr.includes(category.id))
            return selectedCategoriesArr.includes(category.id);

            //selectedCategories
            //ategoryId => product.selectedCategories.includes(categoryId))
          } else
            return false;
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  filteredProducts = filteredProducts.filter(
    (product) => product.price <= maxPrice || (product.sPrice && product.sPrice <= maxPrice)
  );

  if (selectedOption === 'lowest') {
    filteredProducts = filteredProducts.sort((a, b) => {
      const aPrice = a.sPrice || a.price;
      const bPrice = b.sPrice || b.price;
      return aPrice - bPrice;
    });
  } else if (selectedOption === 'highest') {
    filteredProducts = filteredProducts.sort((a, b) => {
      const aPrice = a.sPrice || a.price;
      const bPrice = b.sPrice || b.price;
      return bPrice - aPrice;
    });
  } else if (selectedOption === 'a-z') {
    filteredProducts = filteredProducts.sort((a, b) => a.title.localeCompare(b.title));
  } else if (selectedOption === 'z-a') {
    filteredProducts = filteredProducts.sort((a, b) => b.title.localeCompare(a.title));
  }

  if (showOutOfStock) {
    filteredProducts = filteredProducts.filter(item => item.stock > 0);
  } else if (!showOutOfStock) {
    filteredProducts = filteredProducts.filter(item => item);
  }

  const ProductBrandIDs = filteredProducts.map(item => { return item.productBrandId });
  const FilteredBrands = brands.filter(c => ProductBrandIDs.includes(c.id));

  filteredProducts = filteredProducts.filter((product) =>
    selectedBrands.length === 0 || selectedBrands.some((productBrandId) => product.productBrandId === productBrandId)
  );

  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
  const visiblePageCount = 7;

  const getStartPage = () => {
    if (currentPage <= Math.ceil(visiblePageCount / 2)) {
      return 1;
    } else if (currentPage >= totalPages - Math.floor(visiblePageCount / 2)) {
      return totalPages - visiblePageCount + 1;
    } else {
      return currentPage - Math.floor(visiblePageCount / 2);
    }
  };

  const startPage = getStartPage();

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const toggleDiv = () => {
    setIsDivVisible(!isDivVisible);
  };

  const toggleStock = () => {
    setShowOutOfStock((prevShowOutOfStock) => !prevShowOutOfStock);
  };
  useEffect(() => {
    document.title = "Categories :: TriggerCraft";
    getBrands();
    getCategories();
    getSubcategories();
    getProducts().then(() => setLoading(false));

    const handleResize = () => {
      setIsDivVisible(window.innerWidth >= 768);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [])
  let selectedCategory = lastWord !== "products" && categories.find(c => c.slug === lastWord || subcategories.find(c => c.slug === lastWord)?.categoryId === c.id);
  //console.log(selectedBrands)
  return (
    <>
      <nav className="mb-3 crumbs">
        <Link to="/" className="breadcrumb-active"><small>Home</small>
        </Link>
        <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
        <Link to="/products" className="breadcrumb-active"><small>Products</small></Link>
        <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
        <Link to="/categories" className={!location.pathname.endsWith("/category") ? "breadcrumb-active" : "breadcrumb-not-active"}><small>Categories</small>
        </Link>
        {!location.pathname.endsWith("/category") && <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>}
        <small className={location.pathname === "/category" ? "breadcrumb-active" : "breadcrumb-not-active"}>{FilterCatName}</small>
      </nav>
      <div className="products">
        <button onClick={toggleDiv} className="btn mb-3">Filters</button>
        {isDivVisible && (
          <div className="product-left">
            <h1 className="mb-4">{FilterCatName}</h1>
            <h3>Filter</h3>
            <div className="filter-item">
              <button onClick={handleDeselectAll}>Reset Filters</button>
              <h5>Product Brands</h5>
              {FilteredBrands.length ? FilteredBrands.map(brand => (
                <div className="input-item" key={brand.id}>
                  <input type="checkbox" checked={selectedBrands.includes(brand.id)} id={brand.id} onChange={(e) => handleBrandChange(e)} />
                  <label htmlFor={brand.id}>{brand.title}</label>
                </div>)) : <p>No brands available</p>
              }
            </div>

            <div className="filter-item">
              <h5>Filter By Price</h5>
              <div className="range-slider">
                <span>0</span>
                <input className="rs-range" type="range" min={0} max={100000} value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} />
                <span>{maxPrice}</span>
              </div>
            </div>
          </div>)}
        <div className="product-container row product-right col-lg-9 col-md-12 px-2">
          <img
            src={selectedCategory?.categoryBanners ? getCurrentDomain() + selectedCategory.categoryBanners : "/android-chrome-192x192.png"}
            alt={selectedCategory?.title}
            className="mb-2" style={{ width: "100%", height: "250px", objectFit: "cover" }}
          />
          <div className="d-flex justify-content-between mb-2">
            <div className="toggle-container">
              <div className="d-none">
                <strong>All Products &nbsp;</strong>
                <div className="toggle-switch">
                  <input type="checkbox" className="checkbox" name="stock" id="stock" checked={showOutOfStock} onChange={toggleStock} />
                  <label className="label" htmlFor="stock">
                    <span className="inner" />
                    <span className="switch" />
                  </label>
                </div>
                <strong> &nbsp; In Stock Only</strong>
              </div>
            </div>
            <div className="text-end">
              <form action="#">
                <label htmlFor="sort">Sort:</label>
                <select name="sort" id="sort" className="sort-selection--style" onChange={handleOptionChange} >
                  <option value="lowest">Price(lowest)</option>
                  <option value="highest">Price(highest)</option>
                  <option value="a-z">Title(a-z)</option>
                  <option value="z-a">Title(z-a)</option>
                </select>
              </form>
            </div>
          </div>
          <hr />
          {loading ? <span className="text-center"><i className="fa-solid fa-spinner"></i>&nbsp; Loading...</span> : filteredProducts.length ? currentProducts.map((product) => (
            <div className="col-md-6 col-lg-4 d-flex flex-wrap justify-content-evenly mb-3" key={product.id}>
              <ProductCard
                product={product}
                id={product.id}
                status={product.stock}
                imageURL={product.images ? product.images.split(", ")[0] : "/assets/imgs/Trigger-Craft-Logo.png"}
                currPrice={getProductPrice(product?.dateSPriceStarts, product?.dateSPriceEnds, product?.sPrice, product?.price)}
                prevPrice={(getProductPrice(product?.dateSPriceStarts, product?.dateSPriceEnds, product?.sPrice, product?.price) === product?.price) ? 0 : product?.price}

                title={product.title}
                description={product.shortDescription}
              />
            </div>
          )) : <p>No Products Found</p>}
          {!loading && filteredProducts.length ? <div className="pagination-container pagination">
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="pagination-newer">
              Previous
            </button>

            {Array.from({ length: Math.min(visiblePageCount, totalPages) }).map((_, index) => {
              const pageNumber = startPage + index;
              return (
                pageNumber > 0 &&
                pageNumber <= totalPages && (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={currentPage === pageNumber}
                    className={currentPage === pageNumber ? "pagination-active ellipsis-button" : "ellipsis-button"}
                  >
                    {pageNumber}
                  </button>
                )
              );
            })}

            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="pagination-older">
              Next
            </button>
          </div> : ""}
        </div>
      </div>
      <FixedMessenger />
    </>
  );
};

export default CategoryPage;