import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar, InfiniteScroll } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { Context } from '../../helpers/Context';

export class UserData extends Component {
    static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    const userTypeData = [
      { value: 0, text: '- Select -' },
      { value: 1, text: 'Customer' },
      { value: 10, text: 'Administrator' }
    ];

    this.state = {
      editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      UserId: '',
        fUserType: 0,
      UserType: 0,
      FirstName: '',
      LastName: '',
      EmailAddress: '',
      EmailConfirmed: false,
      CurrentPassword: '',
      LastPassword: '',
      Mobile: '',
      Communication: 0,
      OldId: 0,
      Credit: 0,
      Status: 0,
      DateAdded: new Date(),
      DateModified: new Date(),
      AddedBy: getUserDetails().id,
      DateVerified: new Date(),
      LastLogin: new Date(),
      FailedLogins: 0,
      LockedOut: false,
      LockOutDate: new Date(),
      ResetPassword: false,
      LastIP: '',
      LoginCookie: '',
      importModal: false,
      excelData: [],
      userTypes: userTypeData,

    };
    this.toggle = this.toggle.bind(this);
    this.importUsersModal = this.importUsersModal.bind(this);
    this.saveBulk = this.saveBulk.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "User Data Administration";
    this.loadData();
  }

  importUsersModal = () => {
    this.setState({
      importModal: !this.state.importModal,
      excelData: []
    })
  }

  uploadExcel = async (e) => {
    // const file = e.target.files[0];
    // if (file instanceof Blob) {
    //   const reader = new FileReader();
    //   reader.readAsBinaryString(e.target.files[0])

    //   reader.onload = (e) => {
    //     const contents = e.target.result;
    //     const wb = XLSX.read(contents, { type: "binary" });
    //     const sheetName = wb.SheetNames[0];
    //     const sheet = wb.Sheets[sheetName];
    //     const newData = XLSX.utils.sheet_to_json(sheet);
    //     console.log(newData.map((idx, i) => {

    //       let userObj = {
    //         EmailAddress: i.user_email,
    //         FirstName: i.first_name,
    //         LastName: i.last_name,
    //         UserType: i.role === "customer" ? 1 : 10
    //       };

    //       if (i.billing_phone)
    //         userObj.Mobile = i.billing_phone;

    //       var UserAddress = [];
    //       // if (i.shipping_address_1) {

    //         // var address = {
    //         //   Address: i.shipping_address_2 + ", " + i.shipping_address_1,
    //         //   City: i.shipping_city,
    //         //   Province: i.shipping_state,
    //         //   Country: i.shipping_country,
    //         //   PostalCode: i.shipping_postcode
    //         // }
    //         // UserAddress.push({

    //         // })

    //       return userObj;
    //     }));
    //     this.setState({ excelData: newData })
    //     // const data = reader.result;
    //   }

    // } else {
    //   console.error('Invalid file type');
    // }
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id == id });
        this.setState({ Id: data.id, UserId: data.userId, UserType: data.userType, FirstName: data.firstName, LastName: data.lastName, EmailAddress: data.emailAddress, EmailConfirmed: data.emailConfirmed, CurrentPassword: data.currentPassword, LastPassword: data.lastPassword, Mobile: data.mobile, Communication: data.communication, Credit: data.credit, Status: data.status, DateAdded: data.dateAdded, DateModified: data.dateModified, AddedBy: data.addedBy, DateVerified: data.dateVerified, LastLogin: data.lastLogin, FailedLogins: data.failedLogins, LockedOut: data.lockedOut, LockOutDate: data.lockOutDate, ResetPassword: data.resetPassword, LastIP: data.lastIP, LoginCookie: data.loginCookie, OldId: data.oldId });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        UserId: '',
        UserType: 0,
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        EmailConfirmed: false,
        CurrentPassword: '',
        LastPassword: '',
        Mobile: '',
        Communication: 0,
        OldId: 0,
        Credit: 0,
        Status: 0,
        DateAdded: new Date(),
        DateModified: new Date(),
        AddedBy: getUserDetails().id,
        DateVerified: new Date(),
        LastLogin: new Date(),
        FailedLogins: 0,
        LockedOut: false,
        LockOutDate: new Date(),
        ResetPassword: false,
        LastIP: '',
        LoginCookie: '',
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    //valid &= fieldRequired(this.state.UserId, 'tbUserIdError', '* required');
    valid &= fieldRequired(this.state.FirstName, 'tbFirstNameError', '* required');
    valid &= fieldRequired(this.state.LastName, 'tbLastNameError', '* required');
    valid &= fieldRequired(this.state.EmailAddress, 'tbEmailAddressError', '* required');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.id == this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.state.AddedBy = data.addedBy;
        this.state.DateAdded = data.dateAdded;
        this.state.Id = data.id;
        this.state.Status = data.status;
      }
      else {
        this.state.Status = 1;
      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} enableInfiniteScrolling={true} height={500} allowPaging={true} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='firstName' width='100' headerText="First Name" />
          <ColumnDirective field='lastName' width='100' headerText="Last Name" />
          <ColumnDirective field='emailAddress' width='100' headerText="Email Address" />
          <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar, InfiniteScroll]} />
      </GridComponent>
    );
  }

    render() {
        let data = this.state.editData;
        if (this.state.fUserType !== 0) {
            data = data?.filter(item => item.userType === this.state.fUserType || (!this.state.fUserType && !item.userType));
        }
        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : UserData.renderDataTable(data, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
      const { handleNavigate } = this.context;
    console.log(this.state)
    return (
      <>
        <div className="container">
          <Row>
            <Col xs={6}>
              <h1>User Data Administration</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Button className="mx-1" color="dark" outline size="sm" data-recordid="0" onClick={e => handleNavigate(-1)}><i className="fas fa-chevron-left mr-2"></i> Back To Admin</Button>
              <Button color="success" size="sm" className="mx-1" data-recordid="0" onClick={this.importUsersModal}>Import Users <i className="fas fa-plus-circle ms-2"></i></Button>
              <Button color="primary" size="sm" data-recordid="0" onClick={this.editItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
                    <Col xs={12}>
                        <div className='card card-body shadow mb-3'>
                            <b>Filters</b>
                            <div className='row'>
                                {<div className='col-md-6'>
                                    <DropDownListComponent placeholder='User Type' dataSource={[{ text: "All", value: 0 }, ...this.state.userTypes]} fields={{ text: 'text', value: 'value' }} floatLabelType='Always' value={this.state.fUserType} change={e => this.setState({ fUserType: e.itemData ? e.itemData.value : 0 })} />
                                </div>}
                            </div>

                        </div>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit User Data</ModalHeader>
          <ModalBody>
            {/*<div className='mb-3'>*/}
            {/*<TextBoxComponent id='tbUserId' name='tbUserId' placeholder='User Id' type='text' maxLength='40' floatLabelType='Auto' showClearButton={true} value={this.state.UserId} onChange={e => this.setState({ UserId: e.target.value })} /> <div id='tbUserIdError' className='error-message' />*/}
            {/*</div>*/}

            <div className='row'>
              <div className='col-md-6 mb-3'>
                <TextBoxComponent id='tbFirstName' name='tbFirstName' placeholder='First Name' type='text' maxLength='250' floatLabelType='Auto' showClearButton={true} value={this.state.FirstName} onChange={e => this.setState({ FirstName: e.target.value })} /> <div id='tbFirstNameError' className='error-message' />
              </div>

              <div className='col-md-6 mb-3'>
                <TextBoxComponent id='tbLastName' name='tbLastName' placeholder='Last Name' type='text' maxLength='250' floatLabelType='Auto' showClearButton={true} value={this.state.LastName} onChange={e => this.setState({ LastName: e.target.value })} /> <div id='tbLastNameError' className='error-message' />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-9 mb-3'>
                <TextBoxComponent id='tbEmailAddress' name='tbEmailAddress' placeholder='Email Address' type='text' maxLength='350' floatLabelType='Auto' showClearButton={true} value={this.state.EmailAddress} onChange={e => this.setState({ EmailAddress: e.target.value })} /> <div id='tbEmailAddressError' className='error-message' />
              </div>

              <div className='col-md-3 mb-3'>
                <DropDownListComponent id='ddUserType' name='ddUserType' placeholder='User Type' dataSource={this.state.userTypes} fields={{ text: 'text', value: 'value' }} floatLabelType='Always' value={this.state.UserType} change={e => this.setState({ UserType: e.itemData.value })} />
                {/*<NumericTextBoxComponent id='tbUserType' name='tbUserType' placeholder='User Type' floatLabelType='Auto' showClearButton={true} value={this.state.UserType} onChange={e => this.setState({ UserType: e.target.value })} /> <div id='tbUserTypeError' className='error-message' />*/}
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 mb-3'>
                <TextBoxComponent id='tbCurrentPassword' type="password" name='tbCurrentPassword' placeholder='Password' maxLength='350' floatLabelType='Auto' showClearButton={true} value={this.state.CurrentPassword} onChange={e => this.setState({ CurrentPassword: e.target.value })} /> <div id='tbCurrentPasswordError' className='error-message' />
              </div>

              {/*<div className='mb-3'>*/}
              {/*<TextBoxComponent id='tbLastPassword' name='tbLastPassword' placeholder='Last Password' type='text' maxLength='350' floatLabelType='Auto' showClearButton={true} value={this.state.LastPassword} onChange={e => this.setState({ LastPassword: e.target.value })} /> <div id='tbLastPasswordError' className='error-message' />*/}
              {/*</div>*/}

              <div className='col-md-6 mb-3'>
                <TextBoxComponent id='tbMobile' name='tbMobile' placeholder='Mobile' type='text' maxLength='150' floatLabelType='Auto' showClearButton={true} value={this.state.Mobile} onChange={e => this.setState({ Mobile: e.target.value })} /> <div id='tbMobileError' className='error-message' />
              </div>
            </div>

            {/*<div className='mb-3'>*/}
            {/*<NumericTextBoxComponent  id='tbCommunication' name='tbCommunication' placeholder='Communication' floatLabelType='Auto' showClearButton={true} value={this.state.Communication} onChange={e => this.setState({ Communication: e.target.value })} /> <div id='tbCommunicationError' className='error-message' />*/}
            {/*</div>*/}

            {/*<div className='mb-3'>*/}
            {/*<NumericTextBoxComponent  id='tbCredit' name='tbCredit' placeholder='Credit' floatLabelType='Auto' showClearButton={true} value={this.state.Credit} onChange={e => this.setState({ Credit: e.target.value })} /> <div id='tbCreditError' className='error-message' />*/}
            {/*</div>*/}

            {/*<div className='mb-3'>*/}
            {/*<DatePickerComponent id='tbDateVerified' name='tbDateVerified' placeholder='Date Verified' floatLabelType='Auto' showClearButton={true}  format='yyyy-MM-dd' value={this.state.DateVerified} onChange={e => this.setState({ DateVerified: e.target.value })} /> <div id='tbDateVerifiedError' className='error-message' />*/}
            {/*</div>*/}

            {/*<div className='mb-3'>*/}
            {/*<DatePickerComponent id='tbLastLogin' name='tbLastLogin' placeholder='Last Login' floatLabelType='Auto' showClearButton={true}  format='yyyy-MM-dd' value={this.state.LastLogin} onChange={e => this.setState({ LastLogin: e.target.value })} /> <div id='tbLastLoginError' className='error-message' />*/}
            {/*</div>*/}

            {/*<div className='mb-3'>*/}
            {/*<NumericTextBoxComponent  id='tbFailedLogins' name='tbFailedLogins' placeholder='Failed Logins' floatLabelType='Auto' showClearButton={true} value={this.state.FailedLogins} onChange={e => this.setState({ FailedLogins: e.target.value })} /> <div id='tbFailedLoginsError' className='error-message' />*/}
            {/*</div>*/}
            <div className='row'>
              <div className='col-md-4 mb-3'>
                <CheckBoxComponent id='cbEmailConfirmed' name='cbEmailConfirmed' type='checkbox' label='Email Confirmed' value={this.state.EmailConfirmed} onChange={e => this.setState({ EmailConfirmed: e.target.value })} /> &nbsp; <div id='cbEmailConfirmedError' className='error-message' />
              </div>

              <div className='col-md-4 mb-3'>
                <CheckBoxComponent id='cbLockedOut' name='cbLockedOut' type='checkbox' label='Locked Out' value={this.state.LockedOut} onChange={e => this.setState({ LockedOut: e.target.value })} /> &nbsp; <div id='cbLockedOutError' className='error-message' />
              </div>

              {/*<div className='mb-3'>*/}
              {/*<DatePickerComponent id='tbLockOutDate' name='tbLockOutDate' placeholder='Lock Out Date' floatLabelType='Auto' showClearButton={true}  format='yyyy-MM-dd' value={this.state.LockOutDate} onChange={e => this.setState({ LockOutDate: e.target.value })} /> <div id='tbLockOutDateError' className='error-message' />*/}
              {/*</div>*/}

              <div className='col-md-4 mb-3'>
                <CheckBoxComponent id='cbResetPassword' name='cbResetPassword' type='checkbox' label='Reset Password' value={this.state.ResetPassword} onChange={e => this.setState({ ResetPassword: e.target.value })} /> &nbsp; <div id='cbResetPasswordError' className='error-message' />
              </div></div>

            {/*<div className='mb-3'>*/}
            {/*<TextBoxComponent id='tbLastIP' name='tbLastIP' placeholder='Last IP' type='text' maxLength='30' floatLabelType='Auto' showClearButton={true} value={this.state.LastIP} onChange={e => this.setState({ LastIP: e.target.value })} /> <div id='tbLastIPError' className='error-message' />*/}
            {/*</div>*/}

            {/*<div className='mb-3'>*/}
            {/*<TextBoxComponent id='tbLoginCookie' name='tbLoginCookie' placeholder='Login Cookie' type='text' maxLength='50' floatLabelType='Auto' showClearButton={true} value={this.state.LoginCookie} onChange={e => this.setState({ LoginCookie: e.target.value })} /> <div id='tbLoginCookieError' className='error-message' />*/}
            {/*</div>*/}


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button style={{ background: "#74bc1f", color: "#fff" }} size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.importModal}>
          <ModalHeader>Import Users</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <input placeholder='Browse excel file for users ' type="file" accept='.xlsx, .xls' onChange={(e) => this.uploadExcel(e)} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                <Button color="primary" onClick={this.importUsersModal}>Close</Button>
              </Col>
              <Col>
                <Button color="success" onClick={this.saveBulk}>Upload</Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/userdata', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        console.log(data)
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Id: this.state.Id, UserId: this.state.UserId, UserType: this.state.UserType, FirstName: this.state.FirstName, LastName: this.state.LastName, EmailAddress: this.state.EmailAddress, EmailConfirmed: this.state.EmailConfirmed, CurrentPassword: this.state.CurrentPassword, LastPassword: this.state.LastPassword, Mobile: this.state.Mobile, Communication: this.state.Communication, Credit: this.state.Credit, Status: this.state.Status, DateAdded: this.state.DateAdded, DateModified: this.state.DateModified, AddedBy: this.state.AddedBy, DateVerified: this.state.DateVerified, LastLogin: this.state.LastLogin, FailedLogins: this.state.FailedLogins, LockedOut: this.state.LockedOut, LockOutDate: this.state.LockOutDate, ResetPassword: this.state.ResetPassword, LastIP: this.state.LastIP, LoginCookie: this.state.LoginCookie, OldId: this.state.OldId }
    //console.log(data);
    try {
      const response = await fetch('api/userdata', {
        method: dataId == 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'User Data', content: 'The user data was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'User Data', content: 'There was an error saving the user data!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveBulk() {
    // console.log(this.state.excelData.map(item => ({
    //   ...item
    // })))

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/userdata/importusers', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log("lolo:", data);
        // this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/userdata/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'User Data', content: 'The user data was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'User Data', content: 'There was an error deleting the user data!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

