import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getAuthToken } from '../helpers/authentication';
import { Context } from '../helpers/Context';
import { getCurrentDomain } from '../helpers/Lookup';
import QuickViewModal from './QuickViewModal';

export class ProductBrandCard extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isQuickViewOpen: false,
            quickViewProduct: null,
        };
    }

    calculateSavingsPercentage(originalPrice, discountedPrice) {
        const savingsAmount = originalPrice - discountedPrice;
        const savingsPercentage = (savingsAmount / originalPrice) * 100;
        return savingsPercentage.toFixed(0);
    };

    view = (productData) => {
        this.setState({ isQuickViewOpen: true, quickViewProduct: productData });
    };

    toggleQuickView = () => {
        this.setState({ isQuickViewOpen: !this.state.isQuickViewOpen });
    };

    handleShare = async (productLink) => {
        try {
            await navigator.share({
                title: 'Check out this product!',
                url: productLink,
            });
            console.log('Shared successfully');
        } catch (error) {
            console.error('Error sharing:', error);
        }
    };

    render() {
        const { product, imageURL, prevPrice, currPrice, accentColour, mainColour, cardColour, textColour } = this.props;
        const { handleAddToCart, handleAddToWishlist, handleRemoveFromWishlist, isItemInWishlist } = this.context;
        const { quickViewProduct, isQuickViewOpen } = this.state;
        const productData = { ...this.props }
        let image = "/assets/imgs/Trigger-Craft-Logo.png";
        //console.log(productData.imageURL)
        if (productData && productData.imageURL) {
            let images = productData.imageURL;
            if (productData.imageURL.includes("DynamicData")) images = JSON.parse(productData.imageURL);

            if (Array.isArray(images)) {
                let pic = images[0];
                    if (pic.includes("DynamicData") && pic.includes("[")) {
                        const parsedImage = JSON.parse(pic);
                        image = getCurrentDomain() + parsedImage[0];
                    } else {
                        image = getCurrentDomain() + pic;
                    }
                
            } else if (images.includes("https") && images.includes("[")) {
                image = JSON.parse(images.split(", ")[0])[0];
            } else if (images.includes("https") || images.includes("assets")){
                image = images.split(", ")[0];
            } else {
                image = getCurrentDomain() + images;
            }
        }

        //console.log(image, productData)
        return (
            <div className="px-0 card shadow brand-card image-item h-100" style={{ backgroundColor: cardColour ? `${cardColour}` : "" }}>
                <Link to={"/products/razer-product-view/" + productData.product.sku} state={{ productData: productData }} className="product-image">
                    <img className="card-image" src={image} alt="product image" />
                </Link>
                {prevPrice && prevPrice > currPrice ? <div className="p-2 sale" style={{ width: 50, height: 50, background: accentColour }}>-{this.calculateSavingsPercentage(prevPrice, currPrice)}%</div> : ""}
                <div className="p-2 text-light brand-wishlist">
                    {/*{isItemInWishlist(product.id) ? <i onClick={() => handleRemoveFromWishlist(product.id)} className="fa-solid fa-heart text-danger fs-5" title="Remove From Wishlist"></i> : <i onClick={() => handleAddToWishlist({ product, productId: product.id, price: currPrice, img: imageURL })} className="fa-regular fa-heart fw-light fs-5" title="Add To Wishlist"></i>}*/}
                    {/*<br />*/}
                    <i className="fas fa-share-nodes fw-light fs-5" title="Share" onClick={() => this.handleShare("/products/razer-product-view/" + productData.product.sku)}></i>
                    <br />
                    <i onClick={() => this.view(productData)} className="fas fa-search fw-light fs-5" title="Quick View"></i>
                </div>
                {/*<div className="cart-btn">*/}
                {/*    {product.stock > 0 ?*/}
                {/*        <button style={{ background: accentColour }} onClick={() => { handleAddToCart({ product, productId: product.id, price: currPrice, img: imageURL }, 1) }}>Add To Cart <i className="fas fa-shopping-cart ms-2"></i></button>*/}
                {/*        :*/}
                {/*        <button style={{ background: accentColour }} >Out Of Stock</button>*/}
                {/*    }*/}
                {/*</div>*/}
                <div className='card-body pt-2 px-3 pb-0'>
                    <Link to={"/products/razer-product-view/" + productData.product.sku} state={{ productData: productData }} className="product-description text-dark">
                        <h5 style={{ color: textColour }}>{product.title}</h5>
                        <div>
                            {/*<small className="text-muted" dangerouslySetInnerHTML={{ __html: product.additionalInfo }} />*/}
                            {/*<p>{description}</p>*/}
                        </div>
                    </Link>
                </div>
                <div className="card-footer px-3">
                    {/*{prevPrice ? <del className="text-muted">R {prevPrice.toLocaleString('en-ZA')}.00</del> : ""}*/}
                    <div className="d-flex flex-column">
                        <Link to={"/products/razer-product-view/" + productData.product.sku} state={{ productData: productData }} className="details">View details</Link>
                    <strong className="price-text">{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(currPrice)}</strong>
                    </div>
                    <button onClick={() => { handleAddToCart({ product, productId: product.id, price: currPrice, img: imageURL }, 1) }}>Add To Cart</button>
                </div>
                {quickViewProduct && (
                    <QuickViewModal isOpen={isQuickViewOpen} color={cardColour && cardColour} toggle={this.toggleQuickView} productData={quickViewProduct} picture={image } />
                )}
            </div>
        );
    }


}