import React, { useContext } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../helpers/Context";
import { getCurrentDomain } from "../helpers/Lookup";
import FixedMessenger from "./FixedMessenger";
import PromotionsCard from "./PromotionsCard";

const Promotions = () => {
    const { getUpcomingPromotions, currentPromotions, getPromoCode, promoCode } = useContext(Context);
    useEffect(() => {
        document.title = "Promotions :: TriggerCraft";
        getUpcomingPromotions();
        getPromoCode();
    }, []);
    return (
        <div>
            <nav className="mb-3 crumbs">
                <Link to="/" className="breadcrumb-active"><small>Home</small>
                </Link>
                <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
                <small className="breadcrumb-not-active">Promotions</small>
            </nav>
            <h1 className="mb-4">Promotions</h1>
            <div className="promotions-page">
                {currentPromotions.length ? <div className="promotions-cards">
                    {currentPromotions.map((promo, index) => {
                        const promoCodeItem = promoCode.find(item => item.id === promo.promoCodeId);
                        const code = promoCodeItem ? promoCodeItem.code : '';
                        return (
                            <PromotionsCard
                                key={index}
                                title={promo.title}
                                description={promo.description}
                                imageUrl={getCurrentDomain() + promo.promoImg}
                                code={code}
                                buttonText="Shop Now"
                            />
                        );
                    })}
                    {/*<PromotionsCard*/}
                    {/*    title="New Arrivals"*/}
                    {/*    description="Discover our latest collection of products."*/}
                    {/*    imageUrl="https://image.shutterstock.com/image-vector/brush-sale-banner-promotion-ribbon-260nw-1182942766.jpg"*/}
                    {/*    buttonText="View Collection"*/}
                    {/*/>*/}
                </div> :
                    <span>No promotions at the moment, be on the lookout for future promotions.</span>}
            </div>

            <FixedMessenger />
        </div>
    );
};

export default Promotions;