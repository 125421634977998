// import { Category, ChartComponent, ColumnSeries, DataLabel, Inject, Legend, LineSeries, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts'
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel, BarSeries, LineSeries, ColumnSeries, AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip, AccumulationSelection, Selection, ChartAnnotation, AccumulationAnnotation, AxesDirective, AxisDirective } from '@syncfusion/ej2-react-charts';
import React, { Component } from 'react'
// import { Tooltip } from 'reactstrap'

export default class SalesGraphs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.SalesData,
            primaryXAxis: { valueType: 'Category' },
        }
    }

    renderChart() {
        return (
            <></>
            //     <ChartComponent axisLabelRender={this.axisLabelRender} id={this.props.id} primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} tooltip={{ enable: true }} title={this.props.title} legendSettings={{ visible: true }}>

            //     <Inject services={[ColumnSeries, LineSeries, Legend, Tooltip, DataLabel, Category]} />

            //     <AxesDirective>

            //         {this.props.showRightLabel && <AxisDirective rowIndex={1} name={showRightLabel} opposedPosition={true} title={this.props.yTitle2} labelFormat="#,#" majorGridLines={lines} lineStyle={lines}>

            //         </AxisDirective>}

            //         {this.props.showRightLabel2 && <AxisDirective rowIndex={1} name={showRightLabel2} opposedPosition={true} title={this.props.yTitle3} labelFormat="#,#" majorGridLines={lines} lineStyle={lines}>

            //         </AxisDirective>}

            //     </AxesDirective>

            //     <SeriesCollectionDirective columnSpacing={20}>

            //         <SeriesDirective dataSource={data} xName={xName} yName={yName} name={this.props.yTitle} type={type1} marker={marker2} fill='#0072BC' width='2' columnWidth={0.2} >

            //         </SeriesDirective>

            //         <SeriesDirective dataSource={data} xName={xName} yName={yName3} name={this.props.yTitle3} type={type3} marker={marker3} yAxisName={showRightLabel2} fill='#66c02a' width={0.8} columnWidth={0.2}>

            //         </SeriesDirective>

            //         <SeriesDirective dataSource={data} xName={xName} yName={yName2} name={this.props.yTitle2} type={type2} marker={marker} yAxisName={showRightLabel} fill='#F56818' columnWidth={0.2}>

            //         </SeriesDirective>

            //     </SeriesCollectionDirective>

            // </ChartComponent>
        )
    }

    render() {

        const lines = { width: 0 };
        console.log("Changes:", this.state.data);
        return (
            <div className='card mb-3'>
                <div className="card-header text-center">
                    <strong>Revenue Sales</strong>
                </div>
                <div className="card-body">
                    <ChartComponent id='combo' primaryXAxis={this.state.primaryXAxis} tooltip={{ enable: true }} legendSettings={{ visible: true }}>
                        <Inject services={[ColumnSeries, Category, DataLabel, Legend, LineSeries, Tooltip]} />
                        <AxesDirective>
                            <AxisDirective rowIndex={0} name='yAxis1' opposedPosition={false} title="Product revenue (R)" majorGridLines={lines} lineStyle={lines} />
                            <AxisDirective rowIndex={1} name='yAxis2' opposedPosition={true} title="Unique perchases" majorGridLines={lines} lineStyle={lines} />
                        </AxesDirective>
                        <SeriesCollectionDirective>
                            <SeriesDirective dataSource={this.state.data} xName='x' yName='y1' type='Column' name='Product revenue (R)' fill='#74bc1f' yAxisName='yAxis1' marker={{ dataLabel: { visible: false, position: 'Top', font: { fontWeight: '600', color: '#333' } } }} />
                            <SeriesDirective dataSource={this.state.data} xName='x' yName='y2' type='Line' name='Unique perchases' fill="#20FC8F" yAxisName='yAxis2'  width={2} marker={{ visible: true, width: 10, height: 10, shape: "Circle", isFilled: false }} />
                        </SeriesCollectionDirective>
                    </ChartComponent>
                </div>
            </div>
        )
    }
}
