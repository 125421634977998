import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getAuthToken } from '../helpers/authentication';
import { Context } from '../helpers/Context';
import { getCurrentDomain } from '../helpers/Lookup';

export class PreviewCards extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isQuickViewOpen: false,
            quickViewProduct: null,
        };
    }
    calculateSavingsPercentage(originalPrice, discountedPrice) {
        const savingsAmount = originalPrice - discountedPrice;
        const savingsPercentage = (savingsAmount / originalPrice) * 100;
        return savingsPercentage.toFixed(0);
    };

    render() {
        const { product, prevPrice, currPrice } = this.props;
        const productData = { ...this.props }
        let image = "/assets/imgs/Trigger-Craft-Logo.png";
        if (productData && productData.imageURL) {
            let images = productData.imageURL;
            if (productData.imageURL.includes("DynamicData")) images = JSON.parse(productData.imageURL);

            if (Array.isArray(images)) {
                images.map((pic) => {
                    if (pic.includes("DynamicData") && pic.includes("[")) {
                        const parsedImage = JSON.parse(pic);
                        image = getCurrentDomain() + parsedImage[0];
                    } else {
                        image = getCurrentDomain() + pic;
                    }
                });
            } else if (images.includes("https") && images.includes("[")) {
                image = JSON.parse(images.split(", ")[0])[0];
            } else {
                image = images.split(", ")[0];
            }
        }
        //console.log()
        return (
            <div className="d-flex gap-5 flex-wrap justify-content-center mb-3">
                <div className="px-0 card shadow text-center product-card h-100">
                    <Link className="product-image">
                        <img className="card-image" src={image} alt="product image" />
                    </Link>
                    {prevPrice ? <div className="p-2 sale" style={{ width: 50, height: 50 }}>-{this.calculateSavingsPercentage(prevPrice, currPrice)}%</div> : ""}
                    <div className="p-2 text-dark wishlist">
                        <i className="fa-regular fa-heart fw-light fs-5" title="Add To Wishlist"></i>
                        <br />
                        <i className="fas fa-share-nodes fw-light fs-5" title="Share" ></i>
                        <br />
                        <i className="fas fa-search fw-light fs-5" title="Quick View"></i>
                    </div>
                    <div className="cart-btn border-bottom">
                        {product.stock > 0 ?
                            <button >Add To Cart <i className="fas fa-shopping-cart ms-2"></i></button>
                            :
                            <button>Out Of Stock</button>
                        }
                    </div>
                    <div className='card-body pt-1'>
                        <Link className="product-description text-dark">
                            <h5>{product.title}</h5>
                            <div>
                                {/*<small className="text-muted" dangerouslySetInnerHTML={{ __html: product.additionalInfo }} />*/}
                            </div>
                        </Link>
                    </div>
                    <div className="card-footer">
                        {prevPrice ? <del className="text-muted">R {prevPrice.toLocaleString('en-ZA')}.00</del> : ""}<strong className="price-text">&nbsp; R {currPrice.toLocaleString('en-ZA')}.00</strong>
                    </div>
                </div>
            </div>
        );
    }
};