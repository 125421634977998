import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, Edit, Toolbar, ToolbarItems } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { HtmlEditor, Image, Inject, Count, Link, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { Context } from '../../helpers/Context';

export class BannerSections extends Component {
    static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.state = {
      editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      Slug: '',
      Title: '',
      AddedBy: 0,
      DateAdded: new Date(),
      ModifiedBy: 0,
      DateModified: new Date(),
      Status: 0,

    };
    this.toggle = this.toggle.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "Banner Sections :: TriggerCraft";
    this.loadData();
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id == id });
      this.setState({ Id: data.id, Slug: data.slug, Title: data.title, AddedBy: data.addedBy, DateAdded: data.dateAdded, ModifiedBy: data.modifiedBy, DateModified: data.dateModified, Status: data.status, });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Slug: '',
        Title: '',
        AddedBy: 0,
        DateAdded: new Date(),
        ModifiedBy: 0,
        DateModified: new Date(),
        Status: 0,
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.Slug, 'tbSlugError', '* required');
    valid &= fieldRequired(this.state.Title, 'tbTitleError', '* required');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.id == this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.state.AddedBy = data.addedBy;
        this.state.DateAdded = data.dateAdded;
        this.state.Id = data.id;
        this.state.Status = data.status;
      }
      else {
        this.state.Status = 1;
      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='Title' width='100' headerText="Title" />
          <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

    render() {
        const { handleNavigate } = this.context;
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : BannerSections.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);

    return (
      <>
        <div className="container">
          <Row>
            <Col xs={6}>
              <h1>Banner Sections</h1>
            </Col>
                    <Col xs={6} className="text-end align-self-center">
                        <Button className="mx-2" color="dark" outline size="sm" data-recordid="0" onClick={e => handleNavigate(-1)}><i className="fas fa-chevron-left mr-2"></i> Back To Admin</Button>
              <Button color="primary" size="sm" data-recordid="0" onClick={this.editItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Banner Sections</ModalHeader>
          <ModalBody>
            <div className='mb-3'>
              <TextBoxComponent id='tbSlug' name='tbSlug' placeholder='Slug' type='text' maxLength='150' floatLabelType='Auto' showClearButton={true} value={this.state.Slug} onChange={e => this.setState({ Slug: e.target.value })} /> <div id='tbSlugError' className='error-message' />
            </div>

            <div className='mb-3'>
              <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='150' floatLabelType='Auto' showClearButton={true} value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} /> <div id='tbTitleError' className='error-message' />
            </div>


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button style={{ background: "#74bc1f", color: "#fff" }} size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/bannersections', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Id: this.state.Id, Slug: this.state.Slug, Title: this.state.Title, AddedBy: this.state.AddedBy, DateAdded: this.state.DateAdded, ModifiedBy: this.state.ModifiedBy, DateModified: this.state.DateModified, Status: this.state.Status, }

    try {
      const response = await fetch('api/bannersections', {
        method: dataId == 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Banner Sections', content: 'The banner sections was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Banner Sections', content: 'There was an error saving the banner sections!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/bannersections/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Banner Sections', content: 'The banner sections was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Banner Sections', content: 'There was an error deleting the banner sections!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

