import React, { useContext, useEffect } from 'react';
import WishListItem from './WishListItem';
import { Context } from "../helpers/Context";
import { useNavigate, Link } from 'react-router-dom';
import FixedMessenger from './FixedMessenger';

const WishList = () => {
    const { wishlistItems } = useContext(Context);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Wish List :: TriggerCraft";
    }, []);
    //console.log(wishlistItems)
    return (
        <div className="">
            <nav className="mb-3 crumbs">
                <Link to="/" className="breadcrumb-active"><small>Home</small>
                </Link>
                <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
                <small className="breadcrumb-not-active">Wish List</small>
            </nav>
            <h1>Wishlist</h1>
            {wishlistItems.length ? wishlistItems.map((item) => (
                <WishListItem key={item.productId} item={item} />
            )) : 
                <div className="text-center empty-wish my-3">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9l2.6-2.4C267.2 438.6 256 404.6 256 368c0-97.2 78.8-176 176-176c28.3 0 55 6.7 78.7 18.5c.9-6.5 1.3-13 1.3-19.6v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5zM432 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm59.3-180.7L454.6 368l36.7 36.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L432 390.6l-36.7 36.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L409.4 368l-36.7-36.7c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L432 345.4l36.7-36.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z" /></svg>
                    <p>No Items In Wishlist</p>
                    <button className="hero-button mx-auto" onClick={() => navigate("/products")}>RETURN TO SHOP</button>
                </div>}
            <FixedMessenger />
        </div>
    );
};
export default WishList;