import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../helpers/authentication";
import { Context } from "../helpers/Context";
import { getCurrentDomain } from "../helpers/Lookup";
import CartItem from "./CartItem";

const Cart = () => {
    const [couponCode, setCouponCode] = useState('');
    const navigate = useNavigate();
    const { cartItems, setShowCart, cartSubTotal, cart, applyCoupon, couponError, appliedCoupon } = useContext(Context);


    const handlePayment = async () => {
        var bearer = 'Bearer ' + getAuthToken();
        var data = {
            Id: cart.id,
            CartTotal: cartSubTotal,
            FirstName: "",
            LastName: "",
            Email: "",
            ReturnUrl: getCurrentDomain() + "/return-payment",
            CancelUrl: getCurrentDomain() + "/cancel-payment",
            NotifyUrl: getCurrentDomain() + "/notify-payment",
        }
        try {
            const response = await fetch('/api/payfast/processpayment', {
                method: 'POST',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                console.log("done")
            } else {
                console.log("oh no")
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="cart-panel">
            <div
                className="opac-layer"
                onClick={() => setShowCart(false)}
            ></div>
            <div className="cart-content">
                <div className="cart-header">
                    <span className="heading">Shopping Cart</span>
                    <span className="close-btn" onClick={() => setShowCart(false)} >
                        <i className="fa-solid fa-xmark"></i>
                        <span className="text">close</span>
                    </span>
                </div>

                {!cartItems.length && (
                    <div className="empty-cart">
                        <i className="fa-regular fa-cart-shopping"></i>
                        <span>No products in the cart.</span>
                        <button className="return-cta" onClick={() => { navigate("/products"); setShowCart(false); }}>
                            RETURN TO SHOP
                        </button>
                    </div>
                )}

                {!!cartItems.length && (
                    <>
                        <div className="scrollable-cart">
                            <CartItem />
                        </div>
                        <div className="cart-footer">
                            <div className="subtotal">
                                <span className="text">Subtotal:</span>
                                <span className="text total">
                                    R{cartSubTotal}
                                </span>
                            </div>
                            {/*<div className="coupon-button">*/}
                            {/*    <div className="input-group mb-3">*/}
                            {/*    <input*/}
                            {/*        className="coupon-input"*/}
                            {/*        type="text"*/}
                            {/*        placeholder="Enter coupon code"*/}
                            {/*        value={couponCode}*/}
                            {/*        onChange={(e) => setCouponCode(e.target.value)}*/}
                            {/*    />*/}
                            {/*    <button className="coupon-button" onClick={() => applyCoupon(couponCode)}>Apply</button>*/}
                            {/*    </div>*/}
                            {/*    {couponError && <p className="coupon-error">{couponError}</p>}*/}
                            {/*    {appliedCoupon && (*/}
                            {/*        <div>*/}
                            {/*            <p className="coupon-message">Coupon applied: {appliedCoupon.code}</p>*/}
                            {/*            <p className="coupon-message">Discount: R {appliedCoupon.amount}</p>*/}
                            {/*        </div>*/}
                            {/*    )}*/}
                            {/*</div>*/}
                            <div className="button">
                            {/*    <form action='https://test.payu.in/_payment' method='post'>*/}
                            {/*        <input type="hidden" name="key" value="gtKFFx" />*/}
                            {/*        <input type="hidden" name="txnid" value={cart.id} />*/}
                            {/*        <input type="hidden" name="productinfo" value={ cart.id} />*/}
                            {/*        <input type="hidden" name="amount" value={cartSubTotal} />*/}
                            {/*        <input type="hidden" name="email" value="test@gmail.com" />*/}
                            {/*        <input type="hidden" name="firstname" value="Steve" />*/}
                            {/*        <input type="hidden" name="lastname" value="Test" />*/}
                            {/*        <input type="hidden" name="surl" value="https://apiplayground-response.herokuapp.com/" />*/}
                            {/*        <input type="hidden" name="furl" value="https://apiplayground-response.herokuapp.com/" />*/}
                            {/*        <input type="hidden" name="phone" value="9988776655" />*/}
                            {/*        <input type="hidden" name="hash" value="caa3b6a546c8a9e381d2898b0ce019d752e90480f5d8364daf0fc19542b00f25331edd87d2c3debb1b510bccf2749a957a84a7f08367d0a324caf6e03ee355c0" />*/}
                            {/*        <input type="submit" className="checkout-cta mb-2" value="Checkout With PayU" />*/}
                            {/*    </form>*/}

                            {/*    <form action="https://sandbox.payfast.co.za​/eng/process" method="post">*/}
                            {/*        <input type="hidden" name="merchant_id" value="10008122" />*/}
                            {/*        <input type="hidden" name="merchant_key" value="7bdkjrx500wbk" />*/}
                            {/*        <input type="hidden" name="return_url" value="https://www.example.com/success" />*/}
                            {/*        <input type="hidden" name="cancel_url" value="https://www.example.com/cancel" />*/}
                            {/*        <input type="hidden" name="notify_url" value="https://www.example.com/notify" />*/}
                            {/*        <input type="hidden" name="amount" value={cartSubTotal} />*/}
                            {/*        <input type="hidden" name="item_name" value={cart.id} />*/}

                            {/*        <input type="submit" className="checkout-cta" value="Checkout With PayFast"/>*/}
                            {/*    </form>*/}
                                {/*https://payfast.io/wp-content/themes/payfast/assets/images/logo-light.svg*/}
                                <button className="checkout-cta" onClick={() => { navigate("/checkout"); setShowCart(false); }} >Go To Checkout</button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Cart;