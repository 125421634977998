import React, { Component } from 'react'
import { Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';

export default class RevenueSources extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.RevenueData,
            currentPage: 1,
            itemsPerPage: 10,
        }
    }

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };

    render() {

        const { data, currentPage, itemsPerPage } = this.state;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
        const totalPages = Math.ceil(data.length / itemsPerPage);

        return (
            <div>
                <div className="p-4">
                    <div className="table-responsive">
                        <Table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th className="fw-bold">Product</th>
                                    <th className="fw-bold text-center">Quantity</th>
                                    <th className="fw-bold text-center">Purchases</th>
                                    <th className="fw-bold text-center">Product Revenue</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((item, idx) => (
                                    <tr key={idx}>
                                        <td>{item.product}</td>
                                        <td className="text-center">{item.quantity}</td>
                                        <td className="text-center">{item.purchases}</td>
                                        <td className="text-center">R{item.productRevenue.toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className="mb-2">Items: {currentItems.length}/{data.length}</div>
                    <Pagination className="text-center">
                        {Array.from({ length: totalPages }).map((_, index) => (
                            <PaginationItem key={index} active={index + 1 === currentPage}>
                                <PaginationLink onClick={() => this.handlePageChange(index + 1)}>
                                    {index + 1}
                                </PaginationLink>
                            </PaginationItem>
                        ))}
                    </Pagination>
                </div>
            </div>
        )
    }
}
