import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FixedMessenger from './FixedMessenger';
import { Button } from 'reactstrap';

export class FAQs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            faqItems: []
        }
    }

    componentDidMount() {
        document.title = "FAQs :: TriggerCraft";
        this.loadData();
    }

    async loadData() {
        try {
            const response = await fetch("api/faqs/GetAllFaqs");
            if (response.ok) {
                const faqItems = await response.json();
                this.setState({ faqItems, loading: false });
            } else {
                console.log(response.status + ":", response.statusText);
                this.setState({ loading: false });
            }
        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
        }
    }

    toggleAnswer = (id) => {
        const updatedFAQItems = [...this.state.faqItems];
        const index = updatedFAQItems.findIndex(c => c.id === id);
        updatedFAQItems[index].isOpen = !updatedFAQItems[index].isOpen;
        this.setState(updatedFAQItems);
    };

    RenderFAQHeadings(data) {
        const SortedHeadings = [{ id: 0, title: false }, ...data.filter(c => c.type === 1).sort((a, b) => a.displayOrder - b.displayOrder)];
        return (
            SortedHeadings.map((item, idx) =>
                <span key={idx}>{
                    item.title && (
                        <>
                            <h2>{item.title}</h2>
                            {item.copy && <span dangerouslySetInnerHTML={{ __html: item.copy }}></span>}
                            <hr />

                        </>
                    )}
                    {this.RenderFAQs(item.id, data)}
                </span>
            )
        )
    }

    RenderFAQs(HeadingId, data) {

        const HeadingQuestion = data.filter(c => c.type === 2 && c.parentId === HeadingId).sort((a, b) => a.displayOrder - b.displayOrder);
        return (
            <ul>{
                HeadingQuestion.map((item, index) =>
                    <div className="row p-2" key={index} onClick={() => this.toggleAnswer(item.id)}>
                        <strong className="col-10">{item.title}</strong>
                        <button className="text-end col-md">
                            {item.isOpen ? '-' : '+'}
                        </button>
                        {item.isOpen && <span dangerouslySetInnerHTML={{ __html: item.copy }}></span>}
                    </div>
                )
            }
            </ul>

        )
    }

    render() {

        const content = this.state.loading ? <em>Loading...</em> : this.RenderFAQHeadings(this.state.faqItems);
        return (
            <>
                <nav className="mb-3 crumbs">
                    <Link to="/" className="breadcrumb-active"><small>Home</small>
                    </Link>
                    <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
                    <small className="breadcrumb-not-active">FAQs</small>
                </nav>
                <div className="container faq">
                    <div className="row">
                        <div className="col-12">
                            <h1 className='mb-3'>FAQs</h1>
                            {content}
                        </div>
                    </div>

                    {/* <h4>Where is TriggerCraft based?</h4>
                    We are primarily an online store and offer shipping countrywide. We can also be found at conventions like ComiCon Africa and rAge Gaming Expo. Our offices are based in Northriding. In-office order pickups can be arranged.
                    <h4>Am I able to view the product before purchasing?</h4>
                    Unfortunately, no, feel free to call us for expert advice and get some reassurance on your purchase.
                    <h4>Will you be stocking any other products?</h4>
                    We are always look for the next best thing that you might need. Email us if you cannot find it on our website.
                    <h4>Do your products have a warranty?</h4>
                    The product warranty terms are specified on the product page.
                    <h4>Is TriggerCraft on Social Media?</h4>
                    Yes, you can follow us for updates or specials here (Facebook, Instagram, Twitter)– We would love to hear from you!
                    <h4>How to purchase from TriggerCraft</h4>
                    <ul>
                        <li>Select the products you wish to purchase</li>
                        <ol>
                            <li>When viewing products, if you see something you like, simply select the ‘Add to Cart’ button. If you are just browsing but would like to have the item added to your ‘wish list’ then select the heart icon.</li>
                            <li>When you are ready to pay for your order, click on the shopping cart icon, your items will be displayed..</li>
                            <li>Once your shopping cart is displayed, you can change the quantities or add and remove items. Select your shipping method and include a coupon code if available. When you are happy with your cart, select the ‘Proceed to Check out’ button.</li>
                        </ol>
                        <li>Select shipping method</li>
                        <ol>
                            <li>If you hadn’t already entered your shipping address to your profile, you can do so now, alternatively if you would like to ship to an alternative address than that already saved in your profile, you can also add that address now. Select your shipping method and include a coupon code if available.</li>
                        </ol>
                        <li>Confirm order</li>
                        <ol>
                            <li>Review your order and delivery details one last time, select your payment method, and agree to the Terms and conditions before clicking on the ‘Place Order’ button.</li>
                        </ol>
                        <li>Select payment method and pay for order</li>
                        <ol>
                            <li>If you opt to pay with a credit card or Instant EFT you will be directed to Pay Fast’s secure payment portal to complete the payment.</li>
                            <li>If you would prefer to pay via EFT click on the ‘Pay by EFT’ button and we’ll send you an order reservation email with the details of your order, remember to use your order number as the payment reference so we can allocate your payment correctly. When making your payment you should have the option to include an email address for sending proof of payment, if you put ours in, it will come straight to us <a href="mailto:finance@triggercraft.co.za">finance@triggercraft.co.za</a></li>
                        </ol>
                        <li>Order confirmation and tracking</li>
                        <ol>
                            <li>You will receive an email with your invoice and tracking details for your order to track the progress of your order until it is delivered.</li>
                        </ol>
                    </ul>
                    <h2>Coupon Codes</h2>
                    <hr />
                    <h4>Redeeming a Coupon Code</h4>
                    Once you have finished shopping and adding items to your cart, click on your ‘cart’ to view your order. At the bottom of the order there is a block to add your coupon code. Type your code in the space providing and select ‘Apply Coupon’. You can now proceed to checkout.
                    <h4>Coupon Code expirations</h4>
                    Coupon Codes are linked to promotions and specials. Each promotion will have its own terms and conditions and expiration dates, please view the origin of your code for these terms.
                    <h4>Coupon code not working</h4>
                    Please ensure you are registered and logged in when placing an order.  If the below criteria have been met and the coupon code is still not working, please email u at (<a href="mailto:support@triggercraft.co.za">support@triggercraft.co.za</a>)
                    <ul>
                        <li>Entered the right code</li>
                        <li>Met the conditions of the voucher (e.g. Only valid on orders of R150 or more)</li>
                        <li>That the coupon promotion is still valid.</li>
                    </ul>
                    <h2>Wish list</h2>
                    <hr />
                    A wish list serves as a list of products you are interested in. You can also share your list with others who may buy the products as gifts for you.
                    <h4>Creating a wish list</h4>
                    You will need to create an account or log in to your existing account to start adding items to your wish list. Whilst browsing on our shop you can simply add items to your wish list by clicking on the ‘heart’ icon on each product. Items remain in your wish list until you remove them or add them to your cart. You can easily add items to your cart by clicking ‘Add to Cart’, for your convenience your wish list will also let you know if the item is still in stock.
                    <h4>Where is your Wish list?</h4>
                    When you are logged in, you can access your wish list by clicking on the ‘My Wish list’ at the top left of your screen.
                    <ul>
                        <li>To check out click – My Account</li>
                    </ul>
                    <h2>Forgot Password</h2>
                    Please make sure you are using the correct username and/or email address you used when you registered. If you have forgotten your password, please click on the ‘Lost Password’ link, we’ll send you an email with a link to reset your password. This email may take several minutes to deliver to your inbox. Please be patient and rather not click on ‘Lost Password’ link several times as it will continue to generate new links.
                    <h4>Change your password</h4>
                    When logged in, go to ‘My Account’ and from your ‘My Account Dashboard’ click on ‘edit your password and account details’ You’ll need to enter your current password as well as the new password and confirm it. Remember to SAVE your changes.
                    <h4>Change your login email address.</h4>
                    This can also be done through your ‘My Account Dashboard’. Click on ‘Edit your password and account details’. Remember to SAVE your changes. Please note, you cannot change your original username.
                    Coupon Codes are linked to promotions and specials. Each promotion will have its own terms and conditions and expiration dates, please view the origin of your code for these terms.
                    <h2>Orders</h2>
                    <hr />
                    <h4>Tracking my order</h4>
                    When your order leaves our office, you will receive an email including the information of the courier company and the details, which will allow you to track your order.
                    <h4>Order did not arrive.</h4>
                    Please contact us at <a href="mailto:admin@triggercraft.co.za">admin@triggercraft.co.za</a> and we will track your order for you and give you an answer as soon as possible.
                    <h4>Wrong Order or Damaged/Faulty products</h4>
                    In the unlikely event that you haven’t received what you ordered, or if the product you ordered arrives damaged, please contact us as soon as possible, and no longer than 7 days after receipt of your order. We’ll make it a priority to correct and ensure it to be as hassle free as possible. Please make sure the item/s that has been shipped wrong is not opened or used as it will not be accepted upon collection.
                    <h4>Returns</h4>
                    <h4>Step 1 – Contact</h4>
                    Please email <a href="mailto:support@triggercraft.co.za">support@triggercraft.co.za</a> with the following information in the email:
                    <ol>
                        <li>Your order number (XXXXXXXX)</li>
                        <li>A contact number.</li>
                        <li>Explain the reason for the return. If the wrong order has been delivered, please do not open the product, or tamper with its original packaging. If the product has been opened, you may be liable for the purchase of the product.</li>
                        <li>If the product is damaged (or if the wrong product has been delivered) please could you include a photo of the product in concern, this would be helpful.</li>
                    </ol>
                    <h4>Step 2 – Collection / Return</h4>
                    Depending on the circumstances we may arrange a courier to collect the product concerned alternately you may be asked to return it to the TriggerCraft office (Northriding, Johannesburg).
                    Refunds may only be issued if the product(s) arrive at the TriggerCraft offices in a re saleable condition so please ensure care is taken when repacking the product(s).
                    <h4>Step 3 – Processing</h4>
                    Once the product(s) are received at TriggerCraft we will process the return. This may take up to 3 days to complete. In the case of the wrong product being delivered, your correct order will be dispatched without delay.
                    <h4>Step 4 – Refund/Replacement</h4>
                    If you are eligible for a refund, we will do so via EFT into an account of your choice. Please note EFT refunds may take up to 7 working days to reflect in your account.
                    If you are eligible for a replacement product, a new one will be shipped to you.
                    <h2>Order Cancellations</h2>
                    <hr />
                    <h4>Cancelling my order if I have already paid.</h4>
                    If you have already paid for your order and have not yet received an email notifying that your order has already been shipped, please contact us at <a href="mailto:vaughn@triggercraft.co.za">vaughn@triggercraft.co.za</a>.
                    <h4>Cancelling my order if I have not yet paid.</h4>
                    If you have not paid for your order within 7 days of receiving your order confirmation, we will send you an email notification. We’ll cancel it 48 hours thereafter if payment is not received.
                    <h2>Placing an Order</h2>
                    <hr />
                    <h4>Adding items to your cart</h4>
                    <ol>
                        <li>When viewing products, if you see something you like, simply select the ‘Add to Cart’ button. If you are just browsing but would like to have the item added to your ‘wish list’ then select the heart icon.</li>
                        <li>When you are ready to pay for your order, click on the shopping cart icon, your items will be displayed.</li>
                        <li>Once your shopping cart is displayed, you can adjust the quantities or add and remove items if necessary. Select your shipping method and include a coupon code if available. Update your Cart. When you are happy with your cart, select the ‘Proceed to Check out’ button.</li>
                        <li>If you hadn’t already entered your shipping address to your profile, you can do so now, alternatively if you would like to ship to an alternative address than that already saved in your profile, you can also add that address now. Select your shipping method and include a coupon code if available.</li>
                        <li>Review your order and delivery details one last time, select your payment method, and agree to the Terms and conditions before clicking on the ‘Place Order’ button.</li>
                        <li>If you opt to pay with a credit card or Instant EFT, you will be directed to Pay Fast’s secure payment portal to complete the payment. If you would prefer to pay via EFT, click on the ‘Pay by EFT’ button and we will send you an order reservation email with our banking details. Hold onto your order number for any questions you may have.</li>
                    </ol>
                    <h4>Placing an Order without an account</h4>
                    All purchases require you to have a TriggerCraft account, please update your details regularly, or at very least preview your details before making a purchase to ensure your delivery address is correct.
                    <h4>International Order Restrictions</h4>
                    Currently we are only shipping to locations within the borders of South Africa.
                    <h2>Editing an Order</h2>
                    <hr />
                    <h4>Editing an order once it has been paid for</h4>
                    If you wish to make changes after you have confirmed and paid for your order, you will need to contact us, so we can assist you, at <a href="mailto:vaughn@triggercraft.co.za">vaughn@triggercraft.co.za</a>. Please do so as soon as possible, as we endeavour to dispatch our orders as quickly as possible. Once the order has been dispatched, we will be unable to make changes.
                    <h4>Editing and updating your cart before payment has been made.</h4>
                    You can adjust the quantities of products in your cart, as well as removing items you no longer wish to buy. You can remove items by selecting the X next to the product in the table. Adjusting quantities is as simple. Please remember to select the ‘Update Cart’ button to affect your changes.
                    <h2>Payment</h2>
                    <hr />
                    Payment Options
                    You can pay for your order by credit card or via EFT.
                    <h4>Paying with a debit card</h4>
                    If your debit card meets the following criteria, it will be accepted.
                    <ol>
                        <li>Has the Visa or MasterCard Logo.</li>
                        <li>Is a credit, cheque, or debit card.</li>
                        <li>Cash cards will not be accepted.</li>
                        <li>Registered for 3D Secure. If receive One Time Pins (OTPs) via SMS or email from your bank, use online banking services, then you will be fine.</li>
                        <li>If not, then you will need to register with your bank either online or in the branch.</li>
                    </ol>
                    <h4>Paying with a credit card</h4>
                    <ol>
                        <li>Click “Checkout” button.</li>
                        <li>You will be directed to the PayFast secure payment gateway web page. You will be sent a one-time pin (OTP) via SMS or email from your bank.</li>
                        <li>Enter in the OTP and proceed.</li>
                        <li>Payment Success – You will be redirected back to the TriggerCraft website with a “Thank You” Message</li>
                        <li>Payment Failure – You will be redirected back to the TriggerCraft website with a “Payment Failed” Message. You can either try again or select to pay by EFT. You can try as many times as you like without having to make a new order by going through your TriggerCraft profile (members only). Click on your name in the top right corner of the website to visit your profile.</li>
                    </ol>
                    <h4>Where do I find the CVV number?</h4>
                    The CVV Number (Card Verification Value) is the last 3 digits found on the back side of your VISA® and MasterCard® branded credit and debit cards.
                    <h4>Is paying by credit card safe?</h4>
                    All credit card transactions take place on a secure page (3D Secure Site). We use PayFast as our payment gateway, who only use the strictest form of encryption, and no credit card details are stored on our website. Learn more about PayFast security here.
                    <h4>Paying via EFT</h4>
                    There are two ways to pay via EFT.
                    <ol>
                        <li>Instant EFT through PayFast: this allows instant verification of your EFT payment. If you bank with ABSA, FNB, Nedbank or Standard Bank, then you will be able to use this service. There is no delay in the checkout and no need to send proof of payment.</li>
                        <li>Pay via EFT through your bank: if you select this option, you will be sent an email to the address you supplied during the checkout process or when you signed up. Please follow the instructions in the email you receive. You will need to supply proof of payment. If you have changed your email address or do not receive the email, please email <a href="mailto:finance@triggercraft.co.za">finance@triggercraft.co.za</a></li>
                    </ol>
                    <h4>Payment on Delivery or Collection</h4>
                    All orders must be placed and paid for online before an order will be processed.
                    <h4>Proof of Payment</h4>
                    Proof of Payment will not be accepted as only the amount reflecting on our side can confirm the payment. You will be notified by our time once payment reflects.
                    <h4>Bank Details</h4>
                    TriggerCraft bank details for EFT Bank Transfer payments:
                    <ul>
                        <li>Name: TriggerCraft</li>
                        <li>BANK: ABSA</li>
                        <li>ACCOUNT NO: 4090155815</li>
                        <li>BRANCH CODE: 632005</li>
                        <li>Reference Code: (your order number KCXXXX)</li>
                        <li>Proof of Payment: Please send to <a href="mailto:finance@triggercraft.co.za">finance@triggercraft.co.za</a></li>
                        <li>To check out click here.</li>
                        <li>Payment Completion</li>
                    </ul>
                    <h4>Invoices</h4>
                    An invoice is automatically supplied for every order, it will be emailed to the address you supplied at checkout or when you signed up.
                    <h4>Payment Notifications</h4>
                    As soon as we receive your payment, we will send you an email to confirm that your payment has been processed and that all is in order.
                    <h4>Payment Issues</h4>
                    Lost connection
                    If your payment was successful, you will receive notification via email confirming your payment and order. If you do not receive confirmation, then please try again, or select to pay via EFT. Once in your profile, your order remains in your cart until checkout or until you delete it, therefore you can try again as many times as you like without having to recreate your order.
                    <h2>How it Works</h2>
                    <hr />
                    When confirming an order, you have the option to have it shipped to a destination of your choice or you can choose to collect your order from our offices in Northriding, Johannesburg.
                    We use a third-party provider to deliver our orders; we currently use the services of RAM Couriers to ship all our online national orders. The shipping cost will be automatically added to your cart unless you have chosen to collect.
                    Shipping costs are calculated according to the product dimensions and/or weight and the area it is being shipped too. Our website is integrated with RAM Courier shipping system, so you will easily be able to adjust your shipping needs.
                    To check out click here.
                    <h4>Shipping Costs</h4>
                    Standard Delivery takes 2 to 3 days to major centres and 3 to 6 days to regional areas.
                    <ul>
                        <li>Eastern Cape                                      R150</li>
                        <li>Free State                                           R150</li>
                        <li>Gauteng                                             R90</li>
                        <li>KwaZulu-Natal                                   R90</li>
                        <li>Limpopo                                            R150</li>
                        <li>Mpumalanga                                     R150</li>
                        <li>Northern Cape                                  R150</li>
                        <li>North West                                        R150</li>
                        <li>Western Cape                                    R90</li>
                    </ul>
                    <h4>International Shipping</h4>
                    International Orders and Shipping
                    If you are located outside of the borders of South Africa We can arrange for international shipping. Please email <a href="mailto:admin@triggercraft.co.za">admin@triggercraft.co.za</a> and we can get you a quotation including shipping, and the respective duties and customs that may be applicable.
                    <h2>Delivery Times</h2>
                    <hr />
                    <h4>How long will it take for my order to arrive?</h4>
                    This will be based on the shipping method you select. On average orders are dispatched from our offices within 24 hours and take 1 to 3 working days to deliver to main centres. Please note the courier company only operates during working hours, Monday to Friday.
                    <h4>When do you deliver?</h4>
                    The courier company operates during working hours, Monday to Friday.
                    <h2>Order Tracking</h2>
                    <hr />
                    <h4>Where is my order?</h4>
                    When your order leaves are offices, you are sent an email to notify you, this email will contain the waybill number and link to RAM Courier’s website for easy tracking. Please let us know if you didn’t receive this. If you would like us to track your order for you, please email us at <a href="mailto:admin@triggercraft.co.za">admin@triggercraft.co.za</a>.
                    <h4>Will the courier call before delivery?</h4>
                    We do pass on all customer requests to our courier company, but we cannot guarantee that the driver will be able to call you before he delivers your parcel. If the home address provided will not have someone there during office hours to receive the parcel, perhaps consider shipping it to your place of work.
                    <h4>Standard shipping/courier terms</h4>
                    <ul>
                        <li>These are door-to-door delivery services. We do not deliver to Post boxes.</li>
                        <li>All delivery times are estimates only.</li>
                        <li>Someone will have to sign for the parcel on delivery.</li>
                        <li>Deliveries are normally made between 9am and 5pm.</li>
                        <li>Unfortunately, it is not possible to provide a more accurate time for your delivery.</li>
                        <li>It is not possible to guarantee that a courier will contact you during the delivery cycle.</li>
                    </ul>
                    <h4>Can I change the delivery address after I have ordered?</h4>
                    Yes, if your parcel is still in the TriggerCraft warehouse then there should be no additional delay to your delivery. However, if your new shipping address is to a new centre, then additional delivery fees may apply.
                    To change the delivery address please urgently contact us at <a href="mailto:admin@triggercraft.co.za">admin@triggercraft.co.za</a> */}

                </div>
                <FixedMessenger />
            </>
        );
    }


}