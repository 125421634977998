/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useContext, useEffect, useState } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Table } from 'reactstrap';
import { Context } from "../helpers/Context";
import { ProductCard } from "./ProductCard";
import { getCurrentDomain } from "../helpers/Lookup";
import { getAuthToken, getUserDetails } from "../helpers/authentication";
import classnames from 'classnames';
import FixedMessenger from "./FixedMessenger";
import { ProductBrandCard } from "./ProductBrandCard";

function scrollToSection(sectionId) {
    const element = document.getElementById(sectionId);

    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

const RazerProductView = () => {
    const [hover, setHover] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('1');
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [newReview, setNewReview] = useState({ name: getAuthToken() ? `${getUserDetails().firstName} ${getUserDetails().lastName}` : "", rating: null, comment: '' });
    const location = useLocation();
    const { sku } = useParams();
    const navigate = useNavigate();
    const [currentImage, setCurrentImage] = useState(null);
    const [CategoryList, SetCategoryList] = useState(null);

    const { handleAddToCart, sendCustomEvent, handleRemoveFromWishlist, handleAddToWishlist, isItemInWishlist, getProducts, subcategories, products, AddProductActivity, getRandomObjectsFromArray, getSubcategories, categories, getReviews, reviews, setReviews, getBrands, brands } = useContext(Context);
    const productData = products.find(item => item.sku === sku);

    const decrement = () => {
        setQuantity((prevState) => {
            if (prevState === 1) return 1;
            return prevState - 1;
        });
    };
    const increment = () => {
        setQuantity((prevState) => prevState + 1);
    };

    const selectedBrand = brands.find(item => item.slug == "razer");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const changeImage = (newImage) => {
        setCurrentImage(newImage);
    };

    const scrollTo = (sectionId) => {
        scrollToSection(sectionId);
    };

    const addReview = async (e) => {
        var bearer = 'Bearer ' + getAuthToken();
        const data = {
            Id: 0,
            ProductId: productData.id,
            Name: newReview.name,
            Comment: newReview.comment,
            Rating: newReview.rating,
            DateAdded: new Date(),
            AddedBy: getUserDetails().id,
            Status: 1,
        }
        try {
            if (newReview.name && newReview.comment) {
                setLoading(true);
                const response = await fetch('/api/reviews', {
                    method: 'POST',
                    headers: {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });

                if (response.ok) {
                    setReviews([...reviews, newReview]);
                    sendCustomEvent("Product Review", "Reviewed A Product", `${productData.title}`)
                    setLoading(false);
                } else {

                    setLoading(false);
                }
            }
        } catch (e) {
            console.error(e);
        }
        setNewReview({ name: `${getUserDetails().firstName} ${getUserDetails().lastName}`, rating: null, comment: '' });
    };

    useEffect(() => {
        document.title = "Product View :: TriggerCraft";

        const fetchData = async () => {
            try {
                await getProducts();
                await getSubcategories();
                await getBrands();

                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, [loading]);

    useEffect(() => {
        if (productData) {
            AddProductActivity(productData.id, 1);
            getReviews(productData.id);
            setCurrentImage("/assets/imgs/Trigger-Craft-Logo.png")
            const razerProducts = products.filter(item => item.productBrandId === 17)
            const relatedProductList = getRandomObjectsFromArray(razerProducts, 4, productData)
            setRelatedProducts(relatedProductList);

            if (productData.images) {
                let images = productData.images;
                if (productData.images.includes("DynamicData")) images = JSON.parse(productData.images);
                if (Array.isArray(images)) {
                    images.map((image) => {
                        if (image.includes("DynamicData") && image.includes("[")) {
                            const parsedImage = JSON.parse(image);
                            setCurrentImage(getCurrentDomain() + parsedImage[0]);
                        } else {
                            setCurrentImage(getCurrentDomain() + image);
                        }
                    });
                } else if (images.includes("https") && images.includes("[")) {
                    setCurrentImage(JSON.parse(images.split(", ")[0])[0]);
                } else {
                    setCurrentImage(images.split(", ")[0]);
                }
            }
        }
    }, [productData]);

    const productImage = productData && productData.images ? productData.images.includes("DynamicData") ? JSON.parse(productData.images)[0].includes("\\") && JSON.parse(productData.images)[0].includes("[") ? getCurrentDomain() + JSON.parse(JSON.parse(productData.images))[0].replace(/\\/g, "/") : getCurrentDomain() + JSON.parse(productData.images)[0].replace(/\\/g, "/") : productData.images.split(", ")[0] : "/assets/imgs/Trigger-Craft-Logo.png";

    const productPrice = productData && productData.sPrice ? productData.sPrice : productData && productData.price;

    const currentBrand = productData && brands.find(item => item.id === productData.productBrandId);
    // let related = [];
    // if (relatedProducts.length) {
    //     related = relatedProducts;
    // } else {
    //     getRelatedProducts(productData, products);
    //     related = RelatedProducts;
    // }

    //const selectedCategoryNames = productData && productData.selectedCategories ? JSON.parse(productData.selectedCategories).map(categoryId => {
    //    const matchedCategory = categories.find(category => category.id === categoryId);
    //    return matchedCategory ? matchedCategory.title : "Category Not Found";
    //}) : [];
    let imageUrls = ["/assets/imgs/Trigger-Craft-Logo.png"];

    if (productData && productData.images) {
        let images = productData.images;
        if (productData.images.includes("DynamicData")) images = JSON.parse(productData.images);

        if (Array.isArray(images)) {
            imageUrls = images.map((image) => {
                if (image.includes("DynamicData") && image.includes("[")) {
                    const parsedImage = JSON.parse(image);
                    return parsedImage;
                } else {
                    return image;
                }
            });
        } else if (images.includes("https") && images.includes("[")) {
            imageUrls = JSON.parse(images.split(", ")[0]);
        } else {
            imageUrls = images.split(", ");
        }
    }
    // console.log(productData && productData);

    const RenderCategoryLink = (CategoryIDs) => {
        if (CategoryIDs === "")
            return <small className="text-muted">No Categories</small>

        CategoryIDs = JSON.parse(CategoryIDs);
        return (
            <><span className="mx-1 text-light">|</span>{CategoryIDs?.map(GetCategorySlug)}</>
        )
    }

    const GetCategorySlug = (id, idx) => {
        const cat = subcategories.find(c => c.id == id);
        return cat?.title && cat?.slug && <span key={idx}><Link style={{ color: "#44D62C" }} key={idx} to={"/categories/razer/" + (cat?.slug)}>{cat?.title}</Link><span className="mx-1 text-light">|</span></span>
    }

    const RenderTagLink = (TagLink) => {

        let tagArr = [];
        if (TagLink !== "")
            tagArr = TagLink.split(",");
        return (
            tagArr.length > 0 ?
                <><span className="mx-1 text-light">|</span>
                    {
                        tagArr.map((item, idx) =>
                            <span key={idx}>
                                <Link style={{ display: "inline-block", color: "#44D62C" }} key={idx} to={"/categories/razer/" + item.trim().replace(/\s/g, "_").toLowerCase()}>{item.trim()}</Link><span className="mx-1 text-light">|</span>
                                {(idx === 4 && tagArr.length > 5) && <><br /> <span className="mx-1">|</span></>}
                            </span>
                        )
                    }
                </>
                :
                <small className="text-muted">No tags</small>
        )
    }

    return (
        <>
            <div className="background-container"
                style={{ backgroundColor: "#555555" }}
            >
            </div>
            {!loading ? (<div>
                <nav className="mb-3 crumbs">
                    {/*<Link to="/" className="breadcrumb-active text-light"><small>Home</small>*/}
                    {/*</Link>*/}
                    {/*<span className="breadcrumb-arrow text-light"><i className="fa-solid fa-angle-right"></i></span>*/}
                    <Link to="/brands/razer" className={location.pathname.startsWith("/products") ? "breadcrumb-active text-light" : "breadcrumb-not-active"}><small>Products</small>
                    </Link>
                    <span className="breadcrumb-arrow text-light"><i className="fa-solid fa-angle-right"></i></span>
                    <small className={location.pathname === "/products/product-view" ? "breadcrumb-active" : "breadcrumb-not-active"}>{productData?.title}</small>
                </nav>
                <div className="single-product-main-content">
                    <div className="layout">
                        <div className="single-product-page">
                            <div className="brand-left">
                                <img src={currentImage} />
                                {/*<hr className="m-0"/>*/}
                                <div className="option d-flex">
                                    {imageUrls.slice(0, 6).map((imageUrl, index) => (
                                        <img
                                            key={index}
                                            src={imageUrl.includes("DynamicData") ? getCurrentDomain() + imageUrl : imageUrl}
                                            alt={`Image ${index + 1}`}
                                            className="image-item"
                                            onClick={() => changeImage(imageUrl.includes("DynamicData") ? getCurrentDomain() + imageUrl : imageUrl)}
                                        />))}
                                </div>
                            </div>
                            <div className="right text-light">
                                <span className="name">{productData.title}</span>
                                <div className="d-flex align-items-center mb-3 gap-3">
                                    <img style={{ width: "100px", cursor: "pointer" }} onClick={e => currentBrand.id ? navigate(`/brands/products/${currentBrand.slug}`) : null} src={currentBrand?.brandImages ? getCurrentDomain() + currentBrand.brandImages : "/assets/imgs/Trigger-Craft-Logo.png"} />
                                    <small className="text-light">availability: {productData.stock > 0 ? <span style={{ color: "#44D62C" }}>In Stock</span> : <span className="text-danger">Out Of Stock</span>}</small>
                                </div>
                                {reviews.length ? <span className="mb-3">< i className="fa-solid fa-star" style={{ color: "#44D62C", fontSize: "16px" }}></i>&nbsp; <strong>{productData.avgRating}</strong>&nbsp; <strong style={{ cursor: "pointer", color: "#44D62C" }} onClick={() => { handleTabClick('3'); scrollTo('reviewSection'); }}>{productData.reviews} {productData.reviews > 1 ? "REVIEWS" : "REVIEW"}</strong></span>
                                    : <span className="mb-3"><small>Be the first to</small> <strong style={{ cursor: "pointer", color: "#44D62C" }} onClick={() => { handleTabClick('3'); scrollTo('reviewSection'); }}>REVIEW</strong></span>}
                                <span className="brand-desc"><div dangerouslySetInnerHTML={{ __html: productData.shortDescription.replace(/\\n/g, '') }} /></span>

                                <span className="price">{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(productPrice)}</span>

                                <div className="cart-buttons">
                                    <div className="brand-quantity-buttons">
                                        <span onClick={decrement}>-</span>
                                        <span>{quantity}</span>
                                        <span onClick={increment}>+</span>
                                    </div>
                                    {productData.stock > 0 ?
                                        <button className="brand-add-to-cart-button" onClick={() => { handleAddToCart({ product: productData, productId: productData.id, price: productPrice, img: productImage }, quantity); setQuantity(1); }} >
                                            ADD TO CART
                                        </button> :
                                        <button className="add-to-cart-button">OUT OF STOCK</button>}

                                    {/*{isItemInWishlist(productData.id) ? <i onClick={() => handleRemoveFromWishlist(productData.id)} className="fa-solid fa-heart text-danger ms-3 fs-2" title="Remove From Wishlist"></i> : <i onClick={() => handleAddToWishlist({ product: productData, productId: productData.id, price: productPrice, img: productImage })} className="fa-regular fa-heart fw-light ms-3 fs-2" title="Add To Wishlist"></i>}*/}
                                </div>

                                <span className="divider" />
                                <div className="info-item">
                                    <span className="text-bold">
                                        <b>Category: </b> <br />{productData.selectedCategories && RenderCategoryLink(productData.selectedCategories)}
                                    </span>
                                    <span className="text-bold">
                                        <b>Tags</b><br />{RenderTagLink(productData.tags)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div id="reviewSection">
                            <Nav tabs style={{ backgroundColor: "#555555" }}>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '1' })} style={{ backgroundColor: activeTab == 1 ? "#000" : "transparent", color: "#fff", transition: "background-color 0.3s" }} onClick={() => handleTabClick('1')} >
                                        Description
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '2' })} style={{ backgroundColor: activeTab == 2 ? "#000" : "transparent", color: "#fff", transition: "background-color 0.3s" }} onClick={() => handleTabClick('2')} >
                                        Specification
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '3' })} style={{ backgroundColor: activeTab == 3 ? "#000" : "transparent", color: "#fff", transition: "background-color 0.3s" }} onClick={() => handleTabClick('3')} >
                                        Reviews
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1" className="card p-4" style={{ backgroundColor: "#000" }}>
                                    <Row>
                                        <Col sm="12">
                                            <span className="brand-desc"><div dangerouslySetInnerHTML={{ __html: productData.shortDescription.replace(/\\n/g, '') }} /></span>
                                        </Col>
                                        {productData.about && <><hr />
                                            <Col sm="12">
                                                <span className="brand-desc"><div dangerouslySetInnerHTML={{ __html: productData.about.replace(/\\n/g, '') }} /></span>
                                            </Col></>}
                                        {productData.AdditionalInfo && <><hr />
                                            <Col sm="12">
                                                <span className="brand-desc"><div dangerouslySetInnerHTML={{ __html: productData.AdditionalInfo }} /></span>
                                            </Col></>}
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2" className="card p-4 text-light" style={{ backgroundColor: "#000" }}>
                                    <Row>
                                        <Col sm="12">
                                            <Table responsive>
                                                <tbody className="table-text">
                                                    <tr>
                                                        <th scope="row" className="w-25">SKU</th>
                                                        <td><strong>{productData.sku}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row" className="w-25">GTIN</th>
                                                        <td><strong>{productData.gtin}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row" className="w-25">Width</th>
                                                        <td><strong>{productData.width}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Height</th>
                                                        <td><strong>{productData.height}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Weight</th>
                                                        <td><strong>{productData.weight}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Length</th>
                                                        <td><strong>{productData.length}</strong></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="3" className="card p-4 text-light" style={{ backgroundColor: "#000" }}>
                                    <Row>
                                        <Col sm="6">
                                            <Card body style={{ backgroundColor: "#555" }}>
                                                <CardTitle>Review Product</CardTitle>
                                                {!reviews.find(item => getAuthToken() && item.addedBy === getUserDetails().id) ? <><div className="rating">
                                                    {[...Array(5)].map((star, index) => {
                                                        const currentRating = index + 1;
                                                        return (<label key={index}>
                                                            <input type="radio" name="rating" value={currentRating} onClick={() => setNewReview({ ...newReview, rating: currentRating })} />
                                                            <i className="fa-solid fa-star fs-2 star" style={{ color: currentRating <= (hover || newReview.rating) ? "#44D62C" : "#e4e5e9" }} onMouseEnter={() => setHover(currentRating)} onMouseLeave={() => setHover(null)} ></i>
                                                        </label>);
                                                    })}
                                                </div>
                                                    <div className="mb-3">
                                                        <label className="text-light">Comment:</label>
                                                        <br />
                                                        <textarea
                                                            value={newReview.comment}
                                                            className="w-100"
                                                            onChange={(e) => setNewReview({ ...newReview, comment: e.target.value })}
                                                        />
                                                    </div>
                                                    <button className="brand-hero-button w-100" onClick={() => { getAuthToken() ? addReview() : alert("Please log in to review product") }}>Rate</button></> : <CardText>You already reviewed this product.</CardText>}
                                            </Card>
                                        </Col>
                                        <Col sm="6">
                                            <Card body style={{ backgroundColor: "#555" }}>
                                                <CardTitle>Customer Reviews</CardTitle>
                                                <div className="reviews-container">
                                                    {reviews.length ? (reviews.map((review, index) => {
                                                        const stars = Array.from({ length: review.rating }, (_, i) => i + 1);

                                                        return (
                                                            <div key={index} className="customer-review">
                                                                <div className="rating">
                                                                    {stars.map((starIndex) => (
                                                                        <span key={starIndex} className="star">&#9733;</span>
                                                                    ))}
                                                                </div>
                                                                <div className="customer-info">
                                                                    <p className="customer-name">{review.name}</p>
                                                                    <p className="review-date">{new Date(review.dateAdded).toLocaleDateString()}</p>
                                                                </div>
                                                                <p className="review-comment">{review.comment}</p>
                                                            </div>
                                                        );
                                                    })
                                                    ) : <CardText>No reviews at the moment for this product, be the first to pull the trigger.</CardText>}
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </div>
                        <hr />
                        <h2 className="text-light">Related Products</h2>
                        <div className="row px-3 mb-3">
                            {relatedProducts.map((product) => {
                                return (
                                    <div className="col-md-6 col-lg-3 d-flex justify-content-center mb-3" key={product.id}>
                                        <ProductBrandCard
                                            product={product}
                                            id={product.id}
                                            status={product.stock}
                                            accentColour={selectedBrand.accentBrandColour}
                                            mainColour={selectedBrand.secondaryBrandColor}
                                            cardColour={selectedBrand.primaryBrandColour}
                                            textColour={selectedBrand.textColour}
                                            imageURL={product.images ? product.images.split(", ")[0] : "/assets/imgs/Trigger-Craft-Logo.png"}
                                            currPrice={product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date() ? product.sPrice : product.price}
                                            prevPrice={product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date() ? product.price : product.sPrice}
                                            title={product.title}
                                            description={product.shortDescription}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>) : <div>Loading....</div>}
            <FixedMessenger />
        </>
    );
};

export default RazerProductView;