import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../helpers/Context";
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { confirm } from "react-confirm-box";
import { getCurrentDomain } from "../helpers/Lookup";
import FixedMessenger from "./FixedMessenger";
import { Button } from 'reactstrap';
import { getAuthToken } from "../helpers/authentication";

const CustomerDeliveryAddresses = () => {
    const [loading, seLoading] = useState(false);
    const navigate = useNavigate();
    const { getUserAddress, userAddresses } = useContext(Context);

    const handleDelete = async (id) => {
        const confirmStyles = {
            classNames: {
                confirmButton: 'btn btn-sm confirm-button',
                cancelButton: 'btn btn-sm cancel-button',
            }
        }
        const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
        if (result) {
        seLoading(true);
        var bearer = 'Bearer ' + getAuthToken();
        try {
            const response = await fetch('api/useraddress/' + id, {
                method: 'DELETE',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                await response.json();
                seLoading(false);
                ToastUtility.show({
                    title: 'User Address', content: 'The user address was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    navigate("/login");
            }
        } catch (e) {
            console.error(e);
            seLoading(false);
            ToastUtility.show({
                title: 'User Address', content: 'There was an error deleting the user address!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
        }
    }

    useEffect(() => {
        document.title = "Customer Delivery Addresses :: TriggerCraft";
        getUserAddress();
    }, [loading])
    console.log(userAddresses)
    return (
        <>
            <nav className="mb-3 crumbs">
                <Link to="/" className="breadcrumb-active"><small>Home</small>
                </Link>
                <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
                <small className="breadcrumb-not-active">Customer Address</small>
            </nav>
            
        <div className="col-md-8 mx-auto">
            <h1 className="mb-4">Customer Delivery Addresses</h1>
            {userAddresses && !loading && userAddresses.map((item, index) => (
            <div key={index} className="d-flex justify-content-between border-top p-2">
                <p>{item.address}, {item.postalCode}</p>
                <div className="d-flex gap-3">
                        <i onClick={() => navigate(`/delivery-details/${item.id}`)} style={{cursor: "pointer"}} className="fa-regular fa-pen-to-square text-mited"></i>
                        <i className="fa-regular fa-trash text-mited" style={{ cursor: "pointer" }} onClick={() => handleDelete(item.id)}></i>
                </div>
            </div>

            )) }

            <Button color='dark' size='sm' outline onClick={() => navigate("/delivery-details/0")} >Add Address <i className="fas fa-plus-circle ms-2"></i></Button>
            <FixedMessenger />
                </div>
            </>
    );
};

export default CustomerDeliveryAddresses;