import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, InfiniteScroll, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import { fieldRequired } from '../../helpers/validation';
import { BannerThumbnails } from '../BannerThumbnails';

export class ProductCategories extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.fullToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', '|', 'CreateLink', 'Image', '|', 'ClearFormat',
        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
      ],
      type: 'Expand'
    };

    this.simpleToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', '|', 'ClearFormat', 'Undo', 'Redo', '|', 'SourceCode'],
      type: 'Expand'
    };


    this.state = {
      editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      Slug: '',
      Title: '',
      CategoryImages: '',
      CategoryBanners: '',
      Tags: '',
      ParentId: 0,
      DisplayOrder: 0,
      IsRestricted: 0,
      ShowOnMenu: 0, 
      AddedBy: 0,
      DateAdded: new Date(),
      ModifiedBy: 0,
      DateModified: new Date(),
      Status: 0,
      orderModal: false,
      orderedData: []
    };
    this.toggle = this.toggle.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  orderModalToggle = () => {
    this.setState({
      orderModal: !this.state.orderModal
    });
  }

  componentDidMount() {
    document.title = "Product Categories :: TriggerCraft";
    this.loadData();
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id === id });
      this.setState({ Id: data.id, Slug: data.slug, Title: data.title, CategoryImages: data.categoryImages, CategoryBanners: data.categoryBanners, Tags: data.tags, ParentId: data.parentId, ShowOnMenu: data.showOnMenu, DisplayOrder: data.displayOrder, IsRestricted: data.isRestricted });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Slug: '',
        Title: '',
        CategoryImages: '',
        CategoryBanners: '',
        Tags: '',
        ShowOnMenu: 0, 
        ParentId: 0,
        DisplayOrder: 0,
        IsRestricted: 0,
        
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    //valid &= fieldRequired(this.state.Slug, 'tbSlugError', '* required');
    valid &= fieldRequired(this.state.Title, 'tbTitleError', '* required');
    

    if (valid) {
      //const data = this.state.editData.find((item) => { return item.id === this.state.editId });
      
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  onItemDrop = (e) => {
    const orderedData = [];
    e.source.currentData.map((item, idx) =>
      orderedData.push({ ...item, displayOrder: (idx + 1) })
    );

    this.setState({ orderedData })
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    //console.log(data);
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} enableInfiniteScrolling={true} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='Title' width='100' headerText="Title" />
          <ColumnDirective field='ShowOnMenu' width='50' headerText="Show On Menu" template={(props) => (props.showOnMenu === 1) ? "Yes" : "No"} />
          <ColumnDirective field='IsRestricted' width='50' headerText="Show On Home" template={(props) => props.isRestricted === 1 ? "Yes" : "No"} />
          <ColumnDirective headerText='Actions' width='50' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar, InfiniteScroll]} />
      </GridComponent>
    );
  }

  handleUpdatedBannerUrls = (updatedUrls) => {
    this.setState({ CategoryBanners: updatedUrls });
  }

  handleUpdatedImageUrls = (updatedUrls) => {
    this.setState({ CategoryImages: updatedUrls });
  }

  render() {
    const { handleNavigate } = this.context;
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : ProductCategories.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
    let banners = this.state.CategoryBanners;
    let images = this.state.CategoryImages;
    if (this.state.CategoryBanners.includes("\\\\")) {
      banners = JSON.parse(this.state.CategoryBanners)
    }
    if (this.state.CategoryImages.includes("\\\\")) {
      images = JSON.parse(this.state.CategoryImages)
    }
    //console.log(this.state)
    return (
      <>
        <div className="container">
          <Row>
            <Col xs={5}>
              <h1>Product Categories</h1>
            </Col>
            <Col xs={7} className="text-end align-self-center">
              <Button className="mx-2" color="dark" outline size="sm" data-recordid="0" onClick={e => handleNavigate(-1)}><i className="fas fa-chevron-left mr-2"></i> Back To Admin</Button>
              <Button color="primary" outline size="sm" onClick={this.orderModalToggle} className='mx-2'>Set Display Order <i className="fa-solid fa-sort ms-2"></i></Button>
              <Button color="primary" size="sm" data-recordid="0" onClick={e => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Product Categories</ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='mb-3 col-md-8'>
                <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='250' floatLabelType='Auto' showClearButton={true} value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} /> <div id='tbTitleError' className='error-message' />
              </div>
              <div className='mb-3 col-md-4'>
                <TextBoxComponent id='tbSlug' name='tbSlug' placeholder='Slug' readOnly={true } type='text' maxLength='250' floatLabelType='Auto' showClearButton={true} value={this.state.Slug} onChange={e => this.setState({ Slug: e.target.value })} /> <div id='tbSlugError' className='error-message' />
              </div>
            </div>

            <div className="row align-items-center">
              <div className='mb-3 col-md-8'>
                <TextBoxComponent id='tbTags' name='tbTags' placeholder='Tags' type='text' maxLength='500' floatLabelType='Auto' showClearButton={true} value={this.state.Tags} onChange={e => this.setState({ Tags: e.target.value })} /> <div id='tbTagsError' className='error-message' />
              </div>

              <div className='mb-3 col-md-4'>
                <CheckBoxComponent id='cbIsRestricted' name='cbIsRestricted' type='checkbox' checked={this.state.IsRestricted} label='Show On Home (limit to 6)' onChange={e => this.setState({ IsRestricted: this.state.IsRestricted < 1 ? 1 : 0 })} /> &nbsp; <div id='cbIsRestrictedError' className='error-message' />
                <CheckBoxComponent type='checkbox' checked={this.state.ShowOnMenu} label='Show On Menu' onChange={e => this.setState({ ShowOnMenu: this.state.ShowOnMenu < 1 ? 1 : 0 })} /> &nbsp; <div id='cbIsRestrictedError' className='error-message' />
              </div>
            </div>


            <div className='mb-3'>
              <Label>Category Image <small>(for menu)</small></Label>
              <BannerThumbnails bannerUrls={images} isSingleBanner={true} onUpdateBannerUrls={this.handleUpdatedImageUrls} />
            </div>

            <div className='mb-3'>
              <Label>Category Banner</Label>
              <BannerThumbnails bannerUrls={banners} isSingleBanner={true} onUpdateBannerUrls={this.handleUpdatedBannerUrls} />
            </div>

          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button style={{ background: "#74bc1f", color: "#fff" }} size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.orderModal}>
          <ModalHeader toggle={this.orderModalToggle} close={<button className="close" onClick={this.orderModalToggle}><i className="fas fa-times"></i></button>}>Order Risk Statuses</ModalHeader>
          <ModalBody>
            <i style={{ color: "grey", fontSize: 12 }}>Drag and Drop</i>
            <ListBoxComponent dataSource={this.state.editData} allowDragAndDrop={true} fields={{ text: "title" }} drop={this.onItemDrop} />
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.orderModalToggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveOrder}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

      </>

    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/productcategories', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Slug: this.state.Slug, Title: this.state.Title, ShowOnMenu: this.state.ShowOnMenu, CategoryImages: Array.isArray(this.state.CategoryImages) ? this.state.CategoryImages.join(",") : this.state.CategoryImages, CategoryBanners: Array.isArray(this.state.CategoryBanners) ? this.state.CategoryBanners.join(",") : this.state.CategoryBanners, Tags: this.state.Tags, ParentId: this.state.ParentId, DisplayOrder: this.state.DisplayOrder, IsRestricted: this.state.IsRestricted, SubCategoryIds: [] }
    //console.log(getAuthToken());
    //console.log(data);
    try {
      const response = await fetch('api/productcategories', {
        method: dataId === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Product Categories', content: 'The product categories was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        //console.error(response.status + ": " + response.statusText);
        console.error(response);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Product Categories', content: 'There was an error saving the product categories!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveOrder() {
    //const { handleNavigate } = this.context;
    //this.orderModalToggle()
    this.setState({ orderModal: false, loading: true, showError: false, showSuccess: false });

    const data = this.state.orderedData.map(item => {
      return {
        id: item.id,
        displayOrder: item.displayOrder
      };
    });

//    var data = JSON.stringify(this.state.orderedData);
    
    var bearer = 'Bearer ' + getAuthToken();
    //console.log(data);
    try {
      const response = await fetch('api/productcategories/order', {
        method: 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Product Categories', content: 'The display order was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Product Categories', content: 'There was an error saving the display order!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }


  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/productcategories/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Product Categories', content: 'The product categories was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Product Categories', content: 'There was an error deleting the product categories!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

