import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
import { Context } from "../helpers/Context";
import { getAuthToken } from '../helpers/authentication';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import CartItem from "./CartItem";

export class TestPayments extends Component {
  
  constructor(props) {
    super(props);

    //const { cartItems, setShowCart, cartSubTotal, cart } = useContext(Context);
    //console.log(Context.)
  }

  componentDidMount() {
    document.title = "Test Payments :: TriggerCraft";
  }

  saveItem = async (event) => {
    event.stopPropagation();
    this.submitCart(19);
    
  }

  async submitCart(cartId) {

    //this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    //var data = { id: this.state.id, id: this.state.id, createdAt: this.state.createdAt, updatedAt: this.state.updatedAt, version: this.state.version, company: this.state.company, source: this.state.source, categoryCode: this.state.categoryCode, categoryName: this.state.categoryName, categoryDescription: this.state.categoryDescription, deleted: this.state.deleted, }


    /*const response = await fetch('api/cart', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
            }); */

    try {
      const response = await fetch('api/cart/ProcessPayment/' + cartId, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        
        console.log(data);
        //this.props.history.push(data.redirect);
        document.location.href = data.redirect;
        //this.loadData();
        //ToastUtility.show({
        //  title: 'ME T_cap Categories', content: 'The me t_cap categories was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        //});
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'ME T_cap Categories', content: 'There was an error saving the me t_cap categories!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }


  render() {
    return (
      <>
        <h1>Test Payments</h1>
        <p></p>
        <Button color="success" size="sm" onClick={this.saveItem}>Submit <i className="far fa-check-circle ms-2"></i></Button>
      </>
    );
  }


}