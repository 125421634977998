import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Context } from "../helpers/Context";
import { getCurrentDomain } from "../helpers/Lookup";

const OrderHistoryItem = ({ items }) => {
    const [isOpen, setIsOpen] = useState(false);
    //console.log(items)
    const allExtractedData = [];
    const { allProducts, getAllProducts, oldItems, getOldCartItems } = useContext(Context);
    //for (let i = 1; i <= oldCartItems.length; i++) {
    //    const item = oldCartItems[i];

    //    //if (item) {
    //    //    const data = item.split('|');
    //    //    const extractedData = {};

    //    //    data.forEach(pair => {
    //    //        const [key, value] = pair.split(':');
    //    //        extractedData[key] = value;
    //    //    });

    //    allExtractedData.push(item);
    //    //}
    //}

    const toggle = () => {
        getOldCartItems(items.id, () => {
            setIsOpen(!isOpen);
        });
    };

    const getImage = (productId) => {
        const selectedProduct = allProducts.find(item => item.id == productId);
        return selectedProduct && selectedProduct.images ? selectedProduct.images.includes("DynamicData") ? JSON.parse(selectedProduct.images)[0].includes("\\") && JSON.parse(selectedProduct.images)[0].includes("[") ? getCurrentDomain() + JSON.parse(JSON.parse(selectedProduct.images))[0].replace(/\\/g, "/") : getCurrentDomain() + JSON.parse(selectedProduct.images)[0].replace(/\\/g, "/") : selectedProduct.images.split(", ")[0] : "/assets/imgs/Trigger-Craft-Logo.png";
};

    useEffect(() => {
        getAllProducts();
        //getOldCartItems(items.id);
    }, []);
    console.log(oldItems)
    return (
        <>
        <div className="order-item">
                <div className="col-md-1"><strong>Order Number:  &nbsp;</strong><small>{"TC" + items.orderId.toString().padStart(4, '0')}</small></div>
                <div className="col-md-2"><strong>Order Date:  &nbsp;</strong><small>{new Date(items.dateSubmitted).toDateString()}</small></div>
                <div className="col-md-2"><strong>Paid Date:  &nbsp;</strong><small>{items.dateComplete ? new Date(items.dateComplete).toDateString() : ""}</small></div>
                <div className="col-md-2"><strong>Status:  &nbsp;</strong><small>{items.statusMessage}</small></div>
            <div className="col-md-2"><strong>Payment Method:  &nbsp;</strong><small>{items.paymentMethodTitle}</small></div>
                <div className="col-md-1"><strong>Items:  &nbsp;</strong><small>{items.numItems}</small></div>
            <div className="col-md"><strong>Total:  &nbsp;</strong><small>R {items.amount}</small></div>
            <span onClick={toggle}><i className="fa-solid fa-eye"></i></span>
        </div>
        <Modal isOpen={isOpen} toggle={toggle} centered>
                <ModalHeader toggle={toggle}>Product Information</ModalHeader>
                <ModalBody>
                    {oldItems && oldItems.map((data, index) => (
                        <div className="history" key={index}>
                            <div className="image-container mb-3">
                                <img src={getImage(data.productId)} alt={data.title} />
                            </div>
                            <p>Product Name: <small>{data.title}</small></p>
                            <p>Product ID: {data.productId}</p>
                            <p>SKU: {data.sku}</p>
                            <p>Quantity: <small>{data.qty}</small></p>
                            <p>Sub Total: <small>R {data.priceCharged }</small></p>
                            <p>Total: <small>R {data.totalCharged}</small></p>
                            {/*<p>Tax: <small>R {data.tax}</small></p>*/}
                    <hr/>
                        </div>
                    ))}
                </ModalBody>
        </Modal>
        </>
    );
};

export default OrderHistoryItem;