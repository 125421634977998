import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Count, HtmlEditor, Inject, QuickToolbar, RichTextEditorComponent, Toolbar as rteToolbar } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { Context } from '../../helpers/Context';

export class AuditLog extends Component {
    static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.fullToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', '|', 'CreateLink', 'Image', '|', 'ClearFormat',
        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
      ],
      type: 'Expand'
    };

    this.simpleToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', '|', 'ClearFormat', 'Undo', 'Redo', '|', 'SourceCode'],
      type: 'Expand'
    };


    this.state = {
      editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      Action: '',
      URL: '',
      SKU: '',
      Success: false,
      Meta: '',
      ActionDate: new Date(),

    };
    this.toggle = this.toggle.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "Audit Log :: TriggerCraft";
    this.loadData();
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id == id });
      this.setState({ Id: data.id, Action: data.action, URL: data.URL, SKU: data.SKU, Success: data.success, Meta: data.meta, ActionDate: data.actionDate,  });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Action: '',
        URL: '',
        SKU: '',
        Success: false,
        Meta: '',
        ActionDate: new Date(),
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.Action, 'tbActionError', '* required');
    valid &= fieldRequired(this.state.URL, 'tbURLError', '* required');
    valid &= fieldRequired(this.state.Success, 'tbSuccessError', '* required');
    valid &= fieldRequired(this.state.ActionDate, 'tbActionDateError', '* required');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.id == this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.state.AddedBy = data.addedBy;
        this.state.DateAdded = data.dateAdded;
        this.state.Id = data.id;
        this.state.Status = data.status;
      }
      else {
        this.state.Status = 1;
      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='Action' width='100' headerText="Action" />
          <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : AuditLog.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
      const { handleNavigate } = this.context;
    return (
      <>
        <div className="container">
          <Row>
            <Col xs={6}>
              <h1>Audit Log</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
                <Button className="mx-2" color="dark" outline size="sm" data-recordid="0" onClick={e => handleNavigate(-1)}><i className="fas fa-chevron-left mr-2"></i> Back To Admin</Button>
              <Button color="primary" size="sm" data-recordid="0" onClick={this.editItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Audit Log</ModalHeader>
          <ModalBody>
            <div className='mb-3'>
              <TextBoxComponent id='tbAction' name='tbAction' placeholder='Action' type='text' maxLength='50' floatLabelType='Auto' showClearButton={true} value={this.state.Action} onChange={e => this.setState({ Action: e.target.value })} /> <div id='tbActionError' className='error-message' />
            </div>

            <div className='mb-3'>
              <TextBoxComponent id='tbURL' name='tbURL' placeholder='URL' type='text' maxLength='100' floatLabelType='Auto' showClearButton={true} value={this.state.URL} onChange={e => this.setState({ URL: e.target.value })} /> <div id='tbURLError' className='error-message' />
            </div>

            <div className='mb-3'>
              <TextBoxComponent id='tbSKU' name='tbSKU' placeholder='SKU' type='text' maxLength='100' floatLabelType='Auto' showClearButton={true} value={this.state.SKU} onChange={e => this.setState({ SKU: e.target.value })} /> <div id='tbSKUError' className='error-message' />
            </div>

            <div className='mb-3'>
              <CheckBoxComponent id='cbSuccess' name='cbSuccess' type='checkbox' label='Success' value={this.state.Success} onChange={e => this.setState({ Success: e.target.value })} /> &nbsp; <div id='cbSuccessError' className='error-message' />
            </div>

            <div className='mb-3'>
              <Label for='tbMeta'>Meta</Label>
              <RichTextEditorComponent id='tbMeta' name='tbMeta' placeholder='Meta' toolbarSettings={this.simpleToolbarSettings} showCharCount={true} value={this.state.Meta} change={e => this.setState({ Meta: e.value })} >
                <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Count]} />
              </RichTextEditorComponent><div id='tbMetaError' className='error-message' />
            </div>

            <div className='mb-3'>
              <DatePickerComponent id='tbActionDate' name='tbActionDate' placeholder='Action Date' floatLabelType='Auto' showClearButton={true} format='yyyy-MM-dd' value={this.state.ActionDate} onChange={e => this.setState({ ActionDate: e.target.value })} /> <div id='tbActionDateError' className='error-message' />
            </div>


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button style={{ background: "#74bc1f", color: "#fff" }} size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/auditlog', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Id: this.state.Id, Action: this.state.Action, URL: this.state.URL, SKU: this.state.SKU, Success: this.state.Success, Meta: this.state.Meta, ActionDate: this.state.ActionDate, }

    try {
      const response = await fetch('api/auditlog', {
        method: dataId == 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Audit Log', content: 'The audit log was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Audit Log', content: 'There was an error saving the audit log!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/auditlog/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Audit Log', content: 'The audit log was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Audit Log', content: 'There was an error deleting the audit log!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

