import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, Edit, Toolbar, InfiniteScroll, ToolbarItems } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { HtmlEditor, Image, Inject, Count, Link, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { ImageThumbnails } from '../ImageThumbnails';
import { BannerThumbnails } from '../BannerThumbnails';
import { Context } from '../../helpers/Context';

export class ProductBrands extends Component {
    static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.fullToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', '|', 'CreateLink', 'Image', '|', 'ClearFormat',
        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
      ],
      type: 'Expand'
    };

    this.simpleToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', '|', 'ClearFormat', 'Undo', 'Redo', '|', 'SourceCode'],
      type: 'Expand'
    };


    this.state = {
      editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      Slug: '',
      Title: '',
      Description: '',
      PrimaryBrandColour: '',
      SecondaryBrandColor: '',
      AccentBrandColour: '',
      TextColour: '',
      ShowOnHome: false,
      IsRestricted: 0,
      BrandImages: [],
      BrandBannerImages: [],
      DisplayOrder: 0,
      AddedBy: 0,
      DateAdded: new Date(),
      ModifiedBy: 0,
      DateModified: new Date(),
      Status: 0,

    };
    this.toggle = this.toggle.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "Product Brands :: TriggerCraft";
    this.loadData();
  }

    editItem = (id) => {
      console.log(id)
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id == id });
        this.setState({ Id: data.id, Slug: data.slug, Title: data.title, Description: data.description, ShowOnHome: data.showOnHome, IsRestricted: data.isRestricted, PrimaryBrandColour: data.primaryBrandColour, SecondaryBrandColor: data.secondaryBrandColor, AccentBrandColour: data.accentBrandColour, TextColour: data.textColour, BrandImages: data.brandImages, BrandBannerImages: data.brandBannerImages, DisplayOrder: data.displayOrder, AddedBy: data.addedBy, DateAdded: data.dateAdded, ModifiedBy: data.modifiedBy, DateModified: data.dateModified, Status: data.status, });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Slug: '',
        Title: '',
        Description: '',
        PrimaryBrandColour: '',
        SecondaryBrandColor: '',
        AccentBrandColour: '',
        TextColour: '',
        ShowOnHome: false,
        IsRestricted: 0,
        BrandImages: '',
        BrandBannerImages: '',
        DisplayOrder: 0,
        AddedBy: 0,
        DateAdded: new Date(),
        ModifiedBy: 0,
        DateModified: new Date(),
        Status: 0,
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.Slug, 'tbSlugError', '* required');
    valid &= fieldRequired(this.state.Title, 'tbTitleError', '* required');
    //valid &= fieldRequired(this.state.DisplayOrder, 'tbDisplayOrderError', '* required');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.id == this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.state.AddedBy = data.addedBy;
        this.state.DateAdded = data.dateAdded;
        this.state.Id = data.id;
        this.state.Status = data.status;
      }
      else {
        this.state.Status = 1;
      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} enableInfiniteScrolling={true}  height={500} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='Title' width='100' headerText="Title" />
          <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar, InfiniteScroll]} />
      </GridComponent>
    );
  }

    handleUpdatedImageUrls = (updatedUrls) => {
        this.setState({ BrandImages: updatedUrls });
    }

    handleUpdatedBannerUrls = (updatedUrls) => {
        this.setState({ BrandBannerImages: updatedUrls });
    }

    render() {
        const { handleNavigate } = this.context;
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : ProductBrands.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
      console.log(this.state)
      let images = this.state.BrandImages;
      let banners = this.state.BrandBannerImages;
      if (this.state.BrandImages.includes("\\\\")) {
          images = JSON.parse(this.state.BrandImages)
      }
      if (this.state.BrandBannerImages.includes("\\\\")) {
          banners = JSON.parse(this.state.BrandBannerImages)
      }
      console.log(this.state.BrandBannerImages)
    return (
      <>
        <div className="container">
          <Row>
            <Col xs={6}>
              <h1>Product Brands</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Button className="mx-2" color="dark" outline size="sm" data-recordid="0" onClick={e => handleNavigate(-1)}><i className="fas fa-chevron-left mr-2"></i> Back To Admin</Button>
              <Button color="primary" size="sm" data-recordid="0" onClick={e => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Product Brands</ModalHeader>
          <ModalBody>
            <div className="row">
                <div className='mb-3 col-md-8'>
                  <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} /> <div id='tbTitleError' className='error-message' />
                </div>

                <div className='mb-3 col-md-4'>
                  <TextBoxComponent id='tbSlug' name='tbSlug' placeholder='Slug' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.Slug} onChange={e => this.setState({ Slug: e.target.value })} /> <div id='tbSlugError' className='error-message' />
                </div>
            </div>

            <div className='mb-3'>
              <Label for='tbDescription'>Description</Label>
              <RichTextEditorComponent id='tbDescription' name='tbDescription' placeholder='Description' toolbarSettings={this.simpleToolbarSettings} showCharCount={true} value={this.state.Description} change={e => this.setState({ Description: e.value })} >
                <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Count]} />
              </RichTextEditorComponent><div id='tbDescriptionError' className='error-message' />
            </div>

                    <div className="row">
                        <div className='mb-3 col-md-6'>
                            <TextBoxComponent id='tbPrimaryBrandColour' name='tbPrimaryBrandColour' placeholder='Background Brand Colour' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.PrimaryBrandColour} onChange={e => this.setState({ PrimaryBrandColour: e.target.value })} /> <div id='tbPrimaryBrandColourError' className='error-message' />
                        </div>

                        <div className='mb-3 col-md-6'>
                            <TextBoxComponent id='tbSecondaryBrandColor' name='tbSecondaryBrandColor' placeholder='Card Background Brand Colour (optional)' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.SecondaryBrandColor} onChange={e => this.setState({ SecondaryBrandColor: e.target.value })} /> <div id='tbSecondaryBrandColorError' className='error-message' />
                        </div>
                    </div>

                    <div className="row">
                        <div className='mb-3 col-md-6'>
                            <TextBoxComponent id='tbAccentBrandColour' name='tbAccentBrandColour' placeholder='Accent Brand Colour' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.AccentBrandColour} onChange={e => this.setState({ AccentBrandColour: e.target.value })} /> <div id='tbAccentBrandColourError' className='error-message' />
                        </div>

                        <div className='mb-3 col-md-6'>
                            <TextBoxComponent id='tbTextColour' name='tbTextColour' placeholder='Text Colour' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.TextColour} onChange={e => this.setState({ TextColour: e.target.value })} /> <div id='tbTextColourError' className='error-message' />
                        </div>
                    </div>

            <div className="row align-items-center">
                <div className='mb-3 col-md-4'>
                    <CheckBoxComponent id='cbShowOnHome' name='cbShowOnHome' type='checkbox' checked={this.state.ShowOnHome} label='Show On Home' onChange={e => this.setState({ ShowOnHome: e.target.checked })} /> &nbsp; <div id='cbShowOnHomeError' className='error-message' />
                </div>

                {/*<div className='mb-3 col-md-4'>*/}
                {/*  <NumericTextBoxComponent id='tbIsRestricted' name='tbIsRestricted' placeholder='Is Restricted' floatLabelType='Auto' showClearButton={true} value={this.state.IsRestricted} onChange={e => this.setState({ IsRestricted: e.target.value })} /> <div id='tbIsRestrictedError' className='error-message' />*/}
                {/*</div>*/}
            </div>

            <div className='mb-3'>
                <Label>Brand Banner</Label>
                <BannerThumbnails bannerUrls={banners} isSingleBanner={true} onUpdateBannerUrls={this.handleUpdatedBannerUrls} />
            </div>

            <div className='mb-3'>
                <Label>Brand Images</Label>
                <ImageThumbnails imageUrls={images} isSingleImage={ true} onUpdateImageUrls={this.handleUpdatedImageUrls} />
            </div>

            {/*<div className='mb-3'>*/}
            {/*  <NumericTextBoxComponent id='tbDisplayOrder' name='tbDisplayOrder' placeholder='Display Order' floatLabelType='Auto' showClearButton={true} value={this.state.DisplayOrder} onChange={e => this.setState({ DisplayOrder: e.target.value })} /> <div id='tbDisplayOrderError' className='error-message' />*/}
            {/*</div>*/}


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button style={{ background: "#74bc1f", color: "#fff" }} size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/productbrands', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
      var data = { Id: this.state.Id, Slug: this.state.Slug, Title: this.state.Title, Description: this.state.Description, AccentBrandColour: this.state.AccentBrandColour, TextColour: this.state.TextColour, PrimaryBrandColour: this.state.PrimaryBrandColour, SecondaryBrandColor: this.state.SecondaryBrandColor, ShowOnHome: this.state.ShowOnHome, IsRestricted: this.state.IsRestricted, BrandBannerImages: Array.isArray(this.state.BrandBannerImages) ? this.state.BrandBannerImages.join(", ") : this.state.BrandBannerImages, BrandImages: Array.isArray(this.state.BrandImages) ? this.state.BrandImages.join(", ") : this.state.BrandImages, DisplayOrder: this.state.DisplayOrder, AddedBy: this.state.AddedBy, DateAdded: this.state.DateAdded, ModifiedBy: this.state.ModifiedBy, DateModified: this.state.DateModified, Status: 1, }

    try {
      const response = await fetch('api/productbrands', {
        method: dataId == 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Product Brands', content: 'The product brands was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Product Brands', content: 'There was an error saving the product brands!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/productbrands/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Product Brands', content: 'The product brands was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Product Brands', content: 'There was an error deleting the product brands!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

