import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, Edit, Toolbar, ToolbarItems } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { HtmlEditor, Image, Inject, Count, Link, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { BannerThumbnails } from '../BannerThumbnails';
import { Context } from '../../helpers/Context';
import PromotionsCard from '../PromotionsCard';
import { getCurrentDomain } from '../../helpers/Lookup';

export class Promotions extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        const commandTemplate = [
            { type: 'Preview', buttonOption: { cssClass: 'e-flat', iconCss: 'e-eye e-icons' } },
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
        ];

        this.state = {
            editData: [], loading: true, editModal: false, previewModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
            Id: 0,
            PromoCodeId: 0,
            PromoImg: '',
            Title: '',
            Description: '',
            PromoStartDate: new Date(),
            PromoEndDate: new Date(),
            DateAdded: new Date(),
            AddedBy: 0,
            Status: 0,
            PrevId: 0,
            PromoCodeList: [],

        };
        this.toggle = this.toggle.bind(this);
        this.previewToggle = this.previewToggle.bind(this);
    }

    toggle() {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    previewToggle() {
        this.setState({
            previewModal: !this.state.previewModal,
            PrevId: 0,
        });
    }

    componentDidMount() {
        document.title = "Promotions Administration";
        this.loadData();
    }

    editItem = (id) => {
        if (id > 0) {
            const data = this.state.editData.find((item) => { return item.id == id });
            this.setState({ Id: data.id, PromoCodeId: data.promoCodeId, PromoImg: data.promoImg, Title: data.title, Description: data.description, PromoStartDate: data.promoStartDate, PromoEndDate: data.promoEndDate, DateAdded: data.dateAdded, AddedBy: data.addedBy, Status: data.status, });
        }
        else {
            //clear state fields
            this.setState({
                Id: 0,
                PromoCodeId: 0,
                PromoImg: '',
                Title: '',
                Description: '',
                PromoStartDate: new Date(),
                PromoEndDate: new Date(),
                DateAdded: new Date(),
                AddedBy: 0,
                Status: 0,
            });
        }
        this.setState({ editId: id, editModal: true });
    };

    saveItem = async (event) => {
        event.stopPropagation();
        var valid = true;

        if (valid) {
            const data = this.state.editData.find((item) => { return item.id == this.state.editId });
            if (this.state.editId > 0) { //do not overwrite the following fie when updating
                this.state.AddedBy = data.addedBy;
                this.state.DateAdded = data.dateAdded;
                this.state.Id = data.id;
                this.state.Status = data.status;
            }
            else {
                this.state.Status = 1;
            }
            this.saveData(this.state.editId);
            this.setState({ editModal: false });
        }
    }

    deleteItem = async (id) => {
        const confirmStyles = {
            classNames: {
                confirmButton: 'btn btn-sm confirm-button',
                cancelButton: 'btn btn-sm cancel-button',
            }
        }
        const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
        if (result) {
            this.deleteData(id);
        }
    }

    previewItem = (id) => {
        let promoCodeId = 0;
        if (id > 0) {
            const data = this.state.editData.find((item) => { return item.id == id });
            promoCodeId = data.promoCodeId;
            this.setState({ Id: data.id, PromoCodeId: data.promoCodeId, PromoImg: data.promoImg, Title: data.title, Description: data.description, PromoStartDate: data.promoStartDate, PromoEndDate: data.promoEndDate, DateAdded: data.dateAdded, AddedBy: data.addedBy, Status: data.status, });
        }

        this.setState({ PrevId: promoCodeId, previewModal: true });
    }

    onGridCommand = (args) => {
        switch (args.commandColumn.type) {
            case 'Preview':
                this.previewItem(args.rowData.id);
                break;
            case 'Edit':
                this.editItem(args.rowData.id);
                break;
            case 'Delete':
                this.deleteItem(args.rowData.id);
                break;
        }
    }

    static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
        return (
            <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
                <ColumnsDirective>
                    <ColumnDirective field='Title' width='100' headerText="Promotion Name" />
                    <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
                </ColumnsDirective>
                <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
            </GridComponent>
        );
    }

    handleUpdatedBannerUrls = (updatedUrls) => {
        this.setState({ PromoImg: updatedUrls });
    }

    render() {
        const { handleNavigate } = this.context;
        let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : Promotions.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
        let banners = this.state.PromoImg;
        if (this.state.PromoImg.includes("\\\\")) {
            banners = JSON.parse(this.state.PromoImg)
        }
        console.log(this.state)
        return (
            <>
                <div className="container">
                    <Row>
                        <Col xs={6}>
                            <h1>Promotions Administration</h1>
                        </Col>
                        <Col xs={6} className="text-end align-self-center">
                            <Button className="mx-2" color="dark" outline size="sm" data-recordid="0" onClick={e => handleNavigate(-1)}><i className="fas fa-chevron-left mr-2"></i> Back To Admin</Button>
                            <Button color="primary" size="sm" data-recordid="0" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {contents}
                        </Col>
                    </Row>
                </div>

                <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
                    <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Promotions</ModalHeader>
                    <ModalBody>

                        <div className='row'>
                        <div className='mb-3 col-md-8'>
                            <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='200' floatLabelType='Auto' showClearButton={true} value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} /> <div id='tbTitleError' className='error-message' />
                        </div>

                            <div className='mb-3 col-md-4'>
                            <DropDownListComponent id='ddPromoCodeId' name='ddPromoCodeId' placeholder='Select Promo Code' dataSource={this.state.PromoCodeList} fields={{ text: 'description', value: 'id' }} floatLabelType='Always' value={this.state.PromoCodeId} change={e => this.setState({ PromoCodeId: e.itemData.id })} />
                            <div id='tbPromoCodeIdError' className='error-message' />
                        </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6 mb-3'>
                                <DatePickerComponent id='tbPromoStartDate' name='tbPromoStartDate' placeholder='Promotion Start Date' floatLabelType='Auto' showClearButton={true} format='yyyy-MM-dd' value={this.state.PromoStartDate} onChange={e => this.setState({ PromoStartDate: e.target.value })} /> <div id='tbPromoStartDateError' className='error-message' />
                            </div>

                            <div className='col-md-6 mb-3'>
                                <DatePickerComponent id='tbPromoEndDate' name='tbPromoEndDate' placeholder='Promotion End Date' floatLabelType='Auto' showClearButton={true} format='yyyy-MM-dd' value={this.state.PromoEndDate} onChange={e => this.setState({ PromoEndDate: e.target.value })} /> <div id='tbPromoEndDateError' className='error-message' />
                            </div>
                        </div>

                        <div className='mb-3'>
                            <TextBoxComponent id='tbDescription' name='tbDescription' placeholder='Description' type='text' maxLength='500' floatLabelType='Auto' showClearButton={true} value={this.state.Description} onChange={e => this.setState({ Description: e.target.value })} /> <div id='tbDescriptionError' className='error-message' />
                        </div>
                        {/*<div className='mb-3'>*/}
                        {/*    <TextBoxComponent id='tbPromoImg' name='tbPromoImg' placeholder='Promo Img' type='text' maxLength='200' floatLabelType='Auto' showClearButton={true} value={this.state.PromoImg} onChange={e => this.setState({ PromoImg: e.target.value })} /> <div id='tbPromoImgError' className='error-message' />*/}
                        {/*</div>*/}
                        <div className='mb-3'>
                            <Label>Promo Banner</Label>
                            <BannerThumbnails bannerUrls={banners} isSingleBanner={true} onUpdateBannerUrls={this.handleUpdatedBannerUrls} />
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                        <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal>

                {<Modal isOpen={this.state.previewModal} toggle={this.previewToggle} className={this.props.className} scrollable size="lg" backdrop="static">
                    <ModalHeader toggle={this.previewToggle} close={<button className="close" onClick={this.previewToggle}><i className="fas fa-times"></i></button>}>Product Preview</ModalHeader>
                    <ModalBody>
                        <PromotionsCard
                            title={this.state.Title}
                            description={this.state.Description}
                            imageUrl={getCurrentDomain() + this.state.PromoImg}
                            code={this.state.PromoCodeList.find(item => item.id === this.state.PrevId)?.code}
                            buttonText="Shop Now"
                            test={true}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="dark" size="sm" onClick={this.previewToggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal>}
            </>

        );
    }

    async loadData() {

        var bearer = 'Bearer ' + getAuthToken();
        try {
            const response = await fetch('api/promotions', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ editData: data});
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }

        try {
            const response = await fetch('api/promocodes', {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ PromoCodeList: data, loading: false });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
        }
    }

    async saveData(dataId) {

        this.setState({ loading: true, showError: false, showSuccess: false });

        var bearer = 'Bearer ' + getAuthToken();
        var data = { Id: this.state.Id, PromoCodeId: this.state.PromoCodeId, PromoImg: Array.isArray(this.state.PromoImg) ? this.state.PromoImg.join(", ") : this.state.PromoImg, Title: this.state.Title, Description: this.state.Description, PromoStartDate: this.state.PromoStartDate, PromoEndDate: this.state.PromoEndDate, DateAdded: this.state.DateAdded, AddedBy: this.state.AddedBy, Status: this.state.Status, }

        try {
            const response = await fetch('api/promotions', {
                method: dataId == 0 ? 'POST' : 'PUT',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                await response.json();
                this.loadData();
                ToastUtility.show({
                    title: 'Promotions', content: 'The promotions was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }

        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            ToastUtility.show({
                title: 'Promotions', content: 'There was an error saving the promotions!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }

    async deleteData(dataId) {
        this.setState({ loading: true });

        var bearer = 'Bearer ' + getAuthToken();
        try {
            const response = await fetch('api/promotions/' + dataId, {
                method: 'DELETE',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                await response.json();
                this.loadData();
                ToastUtility.show({
                    title: 'Promotions', content: 'The promotions was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
                });
            }
            else {
                console.log(response.status + ": " + response.statusText);
                if (response.status === 401)
                    this.props.history.push("/login");
            }
        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            ToastUtility.show({
                title: 'Promotions', content: 'There was an error deleting the promotions!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
        }
    }
}

