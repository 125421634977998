import { DropDownListComponent, ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import { fieldRequired } from '../../helpers/validation';
import { BannerThumbnails } from '../BannerThumbnails';

export class Banners extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    const linkTypeData = [
      { value: 0, text: '- Select -' },
      { value: 1, text: 'Brand' },
      { value: 2, text: 'Category' }
    ];

    this.state = {
      editData: [], orderData: [], loading: true, editModal: false, orderModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      SiteSection: 0,
      Title: '',
      Filename: '',
      StartDate: new Date(),
      EndDate: new Date(),
      ActionType: '',
      ActionData: '',
      CategoryLink: '',
      DisplayOrder: 0,
      AddedBy: 0,
      DateAdded: new Date(),
      ModifiedBy: 0,
      DateModified: new Date(),
      Status: 0,
      BrandList: [],
      CategoryList: [],
      LinkTypes: linkTypeData,

    };
    this.toggle = this.toggle.bind(this);
    this.toggleOrderModal = this.toggleOrderModal.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  toggleOrderModal() {
    this.setState({
      orderModal: !this.state.orderModal
    });
  }

  componentDidMount() {
    document.title = "Banners :: TriggerCraft";
    this.loadData();
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id === id });
      this.setState({ Id: data.id, SiteSection: data.siteSection, Title: data.title, Filename: data.filename, StartDate: data.startDate, EndDate: data.endDate, ActionType: data.actionType, ActionData: data.actionData, DisplayOrder: data.displayOrder, AddedBy: data.addedBy, DateAdded: data.dateAdded, ModifiedBy: data.modifiedBy, DateModified: data.dateModified, Status: data.status, CategoryLink: data.categoryLink });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        SiteSection: 0,
        Title: '',
        Filename: '',
        StartDate: new Date(),
        EndDate: new Date(),
        ActionType: '',
        ActionData: '',
        CategoryLink: '',
        DisplayOrder: 0,
        AddedBy: 0,
        DateAdded: new Date(),
        ModifiedBy: 0,
        DateModified: new Date(),
        Status: 0,
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.Title, 'tbTitleError', '* required');
    valid &= fieldRequired(this.state.Filename, 'tbFilenameError', '* required');

    if (valid) {
      const data = this.state.editData.find((item) => { return item.id == this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.state.AddedBy = data.addedBy;
        this.state.DateAdded = data.dateAdded;
        this.state.Id = data.id;
        this.state.Status = data.status;
      }
      else {
        this.state.Status = 1;
      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='Title' width='100' headerText="Title" />
          <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  handleUpdatedBannerUrls = (Filename) => {
    this.setState({ Filename: Filename });
  }

  onItemDrop = (e) => {
    console.log(e)
    const orderData = [];
    e.source.currentData.map((item, idx) =>
      orderData.push({ ...item, displayOrder: (idx + 1) })
    );
    this.setState({ orderData })
  }

  capitalizeKeys = (obj) => {
    const newObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
        newObj[capitalizedKey] = obj[key];
      }
    }
    return newObj;
  }

  render() {
    const { handleNavigate } = this.context;
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : Banners.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
    let banner = this.state.Filename;
    if (this.state.Filename.includes("\\\\")) {
      banner = JSON.parse(this.state.Filename)
    }
    //console.log(this.state, banner)
    return (
      <>
        <div className="container">
          <Row>
            <Col xs={6}>
              <h1>TriggerCraft Banners</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Button className="mx-2" color="dark" outline size="sm" data-recordid="0" onClick={e => handleNavigate(-1)}><i className="fas fa-chevron-left mr-2"></i> Back To Admin</Button>
              <Button className="me-2 btn-dark" size="sm" data-recordid="0" onClick={this.toggleOrderModal}>Order Data <i className="fa-solid fa-sort ms-2"></i></Button>
              <Button color="primary" size="sm" data-recordid="0" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Banners</ModalHeader>
          <ModalBody>
            
            <div className='mb-3'>
              <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='350' floatLabelType='Always' showClearButton={true} value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} /> <div id='tbTitleError' className='error-message' />
            </div>

            
            <div className='mb-3'>
              <TextBoxComponent multiline={true} id='tbActionData' name='tbActionData' placeholder='Body Text (optional if image has text)' type='text' maxLength='350' floatLabelType='Always' showClearButton={true} value={this.state.ActionData} onChange={e => this.setState({ ActionData: e.target.value })} /> <div id='tbActionDataError' className='error-message' />
            </div>

            <div className='mb-3'>
              <DropDownListComponent id='ddLinkId' name='ddLinkId' placeholder='Link By' dataSource={this.state.LinkTypes} fields={{ text: 'text', value: 'value' }} floatLabelType='Always' value={this.state.SiteSection} change={e => this.setState({ ActionType: "", SiteSection: e.itemData.value })} />
              <div id='tbLinkIdError' className='error-message' />
            </div>

            {this.state.SiteSection === 1 ?
              <div className='mb-3'>
                <DropDownListComponent id='ddProductBrandId' name='ddProductBrandId' placeholder='Brand Link (slug)' dataSource={[{ title: '- Select -', slug: '' }, ...this.state.BrandList]} fields={{ text: 'title', value: 'slug' }} floatLabelType='Always' value={this.state.ActionType} change={e => this.setState({ ActionType: e.itemData.slug })} />
                <div id='tbProductBrandIdError' className='error-message' />
              </div> :
              this.state.SiteSection === 2 ?
                <div className='mb-3'>
                  <DropDownListComponent id='ddProductCategoryId' name='ddProductCategoryId' placeholder='Category Link (slug)' dataSource={[{ title: '- Select -', slug: '' }, ...this.state.CategoryList]} fields={{ text: 'title', value: 'slug' }} floatLabelType='Always' value={this.state.ActionType} change={e => this.setState({ ActionType: e.itemData.slug })} />
                  <div id='tbProductCategoryIdError' className='error-message' />
                </div> :
                null
            }

            <div className='mb-3'>
              <Label>Home Banner (best fit 1200 x 600)</Label>
              <BannerThumbnails bannerUrls={banner} isSingleBanner={true} onUpdateBannerUrls={this.handleUpdatedBannerUrls} /><div id='tbFilenameError' className='error-message' />
            </div>



          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button style={{ background: "#74bc1f", color: "#fff" }} size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.orderModal} toggle={this.toggleOrderModal} scrollable size='lg' backdrop="static">
          <ModalHeader toggle={this.toggleOrderModal} close={<button className='btn-close' onClick={this.toggleOrderModal}></button>}>Set Display Order</ModalHeader>

          <ModalBody>
            <ListBoxComponent dataSource={this.state.orderData} allowDragAndDrop={true} fields={{ text: "title" }} drop={this.onItemDrop} />
          </ModalBody>
          <ModalFooter>
            <Button color='dark' size='sm' onClick={this.toggleOrderModal}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color='success' size='sm' onClick={() => this.saveOrder()}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();

    try {
      const response = await fetch('api/productbrands', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ BrandList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/productcategories', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data)
        this.setState({ CategoryList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/banners', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, orderData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Id: this.state.Id, SiteSection: this.state.SiteSection, Title: this.state.Title, Filename: Array.isArray(this.state.Filename) ? this.state.Filename.join(", ") : this.state.Filename, StartDate: this.state.StartDate, EndDate: this.state.EndDate, ActionType: this.state.ActionType, ActionData: this.state.ActionData, DisplayOrder: this.state.DisplayOrder, AddedBy: this.state.AddedBy, DateAdded: this.state.DateAdded, ModifiedBy: this.state.ModifiedBy, DateModified: this.state.DateModified, Status: this.state.Status, CategoryLink: this.state.CategoryLink }

    try {
      const response = await fetch('api/banners', {
        method: dataId == 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Banners', content: 'The banners was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Banners', content: 'There was an error saving the banners!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async saveOrder() {
    //console.log(this.state.orderData)
    var bearer = 'Bearer ' + getAuthToken();
    const capitalizedOrderData = this.state.orderData.map(item => this.capitalizeKeys(item));
    const data = JSON.stringify(capitalizedOrderData);
    this.setState({ orderModal: false, loading: true })
    try {
      const response = await fetch("api/banners/SaveDisplayOrder", {
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          "Authorization": bearer,
          "Content-Type": "application/json"
        },
        body: data
      });

      if (response.ok) {
        ToastUtility.show({
          title: 'Banners', content: 'The display order was successfully updated!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
        this.loadData();
      }

    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/banners/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Banners', content: 'The banners was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Banners', content: 'There was an error deleting the banners!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

