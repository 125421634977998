import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../helpers/authentication";
import { Context } from "../helpers/Context";
import { getCurrentDomain } from "../helpers/Lookup";

const WishListItem = ({ item }) => {
    const { handleAddToCart, handleRemoveFromWishlist } = useContext(Context);
    const navigate = useNavigate();
    let imageUrl = ""
    if (item.img) {
        let images = item.img;
        if (item.img.includes("DynamicData")) images = JSON.parse(item.img);

        if (Array.isArray(images)) {
            images.map((pic) => {
                if (pic.includes("DynamicData") && pic.includes("[")) {
                    const parsedImage = JSON.parse(pic);
                    imageUrl = getCurrentDomain() + parsedImage[0];
                } else {
                    imageUrl = getCurrentDomain() + pic;
                }
            });
        } else if (images.includes("https") && images.includes("[")) {
            imageUrl = JSON.parse(images.split(", ")[0])[0];
        } else {
            imageUrl = images.split(", ")[0];
        }
    }
    //console.log(item)
    return (
        <div className="wishlist-item">
            <i className="fas fa-xmark mx-2 xmark p-2" onClick={() => handleRemoveFromWishlist(item.productId)}></i>
            <div className="d-flex align-items-center col-md product" style={{ cursor: "pointer" }} onClick={() => navigate(`/products/product-view/${item.product.sku}`)}>
                <div className="image-container">
                    <img src={imageUrl} alt={item.product.title} />
                </div>
                <h3>{item.product.title}</h3>
            </div>
            <p className="col-md-2">R{item.price}.00</p>
            <p className={item.product.stock > 0 ? "text-success col-md-2" : "text-danger col-md-2"}>{item.product.stock > 0 ? "In Stock" : "Out Of Stock"}</p>
            {item.product.stock > 0 ?
                <button className="col-md-2" onClick={() => { handleAddToCart({ product: item.product, productId: item.id, price: item.price, img: item.img, status: item.product.stock, title: item.product.title }, 1) }}>Add To Cart <i className="fas fa-shopping-cart ms-2"></i></button>
                : <div className="col-md-2"></div>}
        </div>
    );
};

export default WishListItem;