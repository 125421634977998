import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getAuthToken } from '../helpers/authentication';
import { Context } from '../helpers/Context';
import { getCurrentDomain } from '../helpers/Lookup';
import QuickViewModal from './QuickViewModal';

export class ProductCard extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isQuickViewOpen: false,
            quickViewProduct: null,
        };
    }

    calculateSavingsPercentage(originalPrice, discountedPrice) {
        const savingsAmount = originalPrice - discountedPrice;
        const savingsPercentage = Math.abs((savingsAmount / originalPrice) * 100);
        return savingsPercentage.toFixed(0);
    };

    view = (productData) => {
        this.setState({ isQuickViewOpen: true, quickViewProduct: productData });
    };

    toggleQuickView = () => {
        this.setState({ isQuickViewOpen: !this.state.isQuickViewOpen });
    };

    handleShare = async (productLink) => {
        try {
            await navigator.share({
                title: 'Check out this product!',
                url: productLink,
            });
            console.log('Shared successfully');
        } catch (error) {
            console.error('Error sharing:', error);
        }
    };

    render() {
        const { product, imageURL, prevPrice, currPrice } = this.props;
        const { handleAddToCart, handleAddToWishlist, handleRemoveFromWishlist, isItemInWishlist } = this.context;
        const { quickViewProduct, isQuickViewOpen } = this.state;
        const productData = { ...this.props }
        let image = "/assets/imgs/Trigger-Craft-Logo.png";
        if (imageURL) {
            let images = productData.imageURL;
            if (productData.imageURL.includes("DynamicData")) images = JSON.parse(productData.imageURL);
            
            if (Array.isArray(images)) {
                let pic = images[0]
                    if (pic.includes("DynamicData") && pic.includes("[")) {
                        const parsedImage = JSON.parse(pic);
                        image = getCurrentDomain() + parsedImage[0];
                    } else {
                        image = getCurrentDomain() + pic;
                    }
            } else if (images.includes("https") && images.includes("[")) {
                image = JSON.parse(images.split(", ")[0])[0];
            } else {
                image = images.split(", ")[0];
            }
        }
        //console.log(prevPrice)
        return (
            <div className="px-0 card text-center product-card h-100">
                <Link to={"/products/product-view/" + productData.product.sku} state={{ productData: productData }} className="product-image">
                    <img className="card-image" src={image} alt="product image" />
                </Link>
                {prevPrice && prevPrice > currPrice ? <div className="p-2 sale" style={{ width: 50, height: 50 }}>-{this.calculateSavingsPercentage(prevPrice, currPrice)}%</div> : ""}
                <div className="p-2 text-dark wishlist">
                    {isItemInWishlist(product.id) ? <i onClick={() => handleRemoveFromWishlist(product.id)} className="fa-solid fa-heart text-danger fs-5" title="Remove From Wishlist"></i> : <i onClick={() => handleAddToWishlist({ product, productId: product.id, price: currPrice, img: imageURL })} className="fa-regular fa-heart fw-light fs-5" title="Add To Wishlist"></i>}
                    <br />
                    <i className="fas fa-share-nodes fw-light fs-5" title="Share" onClick={() => this.handleShare("/products/product-view/" + productData.product.sku)}></i>
                    <br />
                    <i onClick={() => this.view(productData)} className="fas fa-search fw-light fs-5" title="Quick View"></i>
                </div>
                <div className="cart-btn border-bottom">
                    {product.stock > 0 ?
                        <button onClick={() => { handleAddToCart({ product, productId: product.id, price: currPrice, img: imageURL }, 1) }}>Add To Cart <i className="fas fa-shopping-cart ms-2"></i></button>
                        :
                        <button>Out Of Stock</button>
                    }
                </div>
                <div className='card-body pt-1'>
                    <Link to={"/products/product-view/" + productData.product.sku} state={{ productData: productData }} className="product-description text-dark">
                        <h5>{product.title}</h5>
                        <div>
                            {/*<small className="text-muted" dangerouslySetInnerHTML={{ __html: product.additionalInfo }} />*/}
                            {/*<p>{description}</p>*/}
                        </div>
                    </Link>
                </div>
                <div className="card-footer">
                    {prevPrice && prevPrice > currPrice ? <del className="text-muted">R {prevPrice.toLocaleString('en-ZA')}.00</del> : ""}<strong className="price-text">&nbsp; R {currPrice.toLocaleString('en-ZA')}.00</strong>
                </div>
                {quickViewProduct && (
                    <QuickViewModal isOpen={isQuickViewOpen} toggle={this.toggleQuickView} productData={quickViewProduct} picture={image } />
                )}
            </div>
        );
    }


}