import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Label, Input, Row, Col } from 'reactstrap';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Sort, Search, ExcelExport, Edit, Toolbar, ToolbarItems } from '@syncfusion/ej2-react-grids';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { HtmlEditor, Image, Inject, Count, Link, Toolbar as rteToolbar, QuickToolbar, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { confirm } from "react-confirm-box";
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import { fieldRequired } from '../../helpers/validation';
import { Context } from '../../helpers/Context';
import { BannerThumbnails } from '../BannerThumbnails';
import { IsDevEnv } from '../../helpers/Lookup';

export class PageContent extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
    ];

    this.fullToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', '|', 'CreateLink', 'Image', '|', 'ClearFormat',
        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
      ],
      type: 'Expand'
    };

    this.simpleToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', '|', 'ClearFormat', 'Undo', 'Redo', '|', 'SourceCode'],
      type: 'Expand'
    };


    this.state = {
      editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      Slug: '',
      AdminTitle: '',
      Title: '',
      Banners: '',
      Copy: '',
      MetaKeywords: '',
      MetaDescription: '',
      AddedBy: 0,
      DateAdded: new Date(),
      ModifiedBy: 0,
      DateModified: new Date(),
      Status: 0,
      NewBannerData: ""

    };
    this.toggle = this.toggle.bind(this);
    this.handleUpdatedBannerUrls = this.handleUpdatedBannerUrls.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "Page Content :: TriggerCraft";
    this.loadData();
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id === id });
      this.setState({ Id: data.id, Title: data.title, Slug: data.slug, Banners: data.banners, Copy: data.copy, MetaKeywords: data.metaKeywords, MetaDescription: data.metaDescription, AddedBy: data.addedBy, DateAdded: data.dateAdded, ModifiedBy: data.modifiedBy, DateModified: data.dateModified, Status: data.status, });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Slug: '',
        AdminTitle: '',
        Title: '',
        Banners: '',
        Copy: '',
        MetaKeywords: '',
        MetaDescription: '',
        AddedBy: getUserDetails().id,
        DateAdded: new Date(),
        ModifiedBy: getUserDetails().id,
        DateModified: new Date(),
        Status: 0,
      });
    }
    this.setState({ editId: id, editModal: true, NewBannerData: "" });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    // valid &= fieldRequired(this.state.Slug, 'tbSlugError', '* required');
    // valid &= fieldRequired(this.state.AdminTitle, 'tbAdminTitleError', '* required');
    valid &= fieldRequired(this.state.Title, 'tbTitleError', '* required');
    // valid &= fieldRequired(this.state.Banners, 'tbBannersError', '* required');
    valid &= fieldRequired(this.state.Copy, 'tbCopyError', '* required');

    if (valid) {
      // const data = this.state.editData.find((item) => { return item.id == this.state.editId });
      // if (this.state.editId > 0) { //do not overwrite the following fie when updating
      //   this.state.AddedBy = data.addedBy;
      //   this.state.DateAdded = data.dateAdded;
      //   this.state.Id = data.id;
      //   this.state.Status = data.status;
      // }
      // else {
      //   this.state.Status = 1;
      // }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='Title' width='100' headerText="Title" />
          <ColumnDirective field='Slug' width='100' headerText="Slug" />
          <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : PageContent.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);
    const { handleNavigate } = this.context;
    return (
      <>
        <div className="container">
          <Row className='mb-3'>
            <Col xs={6}>
              <h1>Page Content</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Button className="mx-1" color="dark" outline size="sm" data-recordid="0" onClick={e => handleNavigate(-1)}><i className="fas fa-chevron-left mr-2"></i> Back To Admin</Button>
              <Button color="primary" size="sm" data-recordid="0" onClick={this.editItem} hidden={!IsDevEnv()}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Page Content</ModalHeader>
          <ModalBody>
            <Row>
              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbTitle' name='tbTitle' placeholder='Title' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.Title} onChange={e => this.setState({ Title: e.target.value })} /> <div id='tbTitleError' className='error-message' />
              </div>
              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbSlug' name='tbSlug' placeholder='Slug' type='text' enabled={false} maxLength='50' floatLabelType='Always' showClearButton={true} value={this.state.Slug} onChange={e => this.setState({ Slug: e.target.value })} /> <div id='tbSlugError' className='error-message' />
              </div>

              {/* <div className='mb-3'>
              <Label for='tbBanners'>Banners</Label>
              <RichTextEditorComponent id='tbBanners' name='tbBanners' placeholder='Banners' toolbarSettings={this.simpleToolbarSettings} showCharCount={true} value={this.state.Banners} change={e => this.setState({ Banners: e.value })} >
                <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Count]} />
              </RichTextEditorComponent><div id='tbBannersError' className='error-message' />
            </div> */}

              <div className='mb-3 col-12'>
                <Label for='tbCopy'>Copy</Label>
                <RichTextEditorComponent id='tbCopy' name='tbCopy' placeholder='Copy' height={500} toolbarSettings={this.fullToolbarSettings} showCharCount={true} value={this.state.Copy} change={e => this.setState({ Copy: e.value })} >
                  <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Count]} />
                </RichTextEditorComponent><div id='tbCopyError' className='error-message' />
              </div>

              <div className='mb-3 col-12'>
                <TextBoxComponent id='tbMetaKeywords' name='tbMetaKeywords' placeholder='Meta Keywords' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.MetaKeywords} onChange={e => this.setState({ MetaKeywords: e.target.value })} /> <div id='tbMetaKeywordsError' className='error-message' />
              </div>

              <div className='mb-3 col-12'>
                <TextBoxComponent id='tbMetaDescription' name='tbMetaDescription' placeholder='Meta Description' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={this.state.MetaDescription} onChange={e => this.setState({ MetaDescription: e.target.value })} /> <div id='tbMetaDescriptionError' className='error-message' />
              </div>

              <div className='mb-3 col-12'>
                <Label>Page Banner</Label>
                <BannerThumbnails bannerUrls={this.state.Banners} isSingleBanner={true} onUpdateBannerUrls={this.handleUpdatedBannerUrls} />
              </div>
            </Row>

          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button style={{ background: "#74bc1f", color: "#fff" }} size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }


  handleUpdatedBannerUrls = (updatedUrls) => {
    console.log(updatedUrls);
    this.setState({ NewBannerData: updatedUrls[0] });
  }


  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/pagecontent', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Slug: this.state.Slug, Title: this.state.Title, Copy: this.state.Copy, MetaKeywords: this.state.MetaKeywords, MetaDescription: this.state.MetaDescription, AddedBy: this.state.AddedBy, DateAdded: this.state.DateAdded, ModifiedBy: this.state.ModifiedBy, DateModified: this.state.DateModified, Status: this.state.Status, NewBannerData: this.state.NewBannerData }

    try {
      const response = await fetch('api/pagecontent', {
        method: 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Page Content', content: 'The page content was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
        else
          ToastUtility.show({
            title: 'Page Content', content: 'There was an error saving the page content!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
          });
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Page Content', content: 'There was an error saving the page content!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/pagecontent/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Page Content', content: 'The page content was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Page Content', content: 'There was an error deleting the page content!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

