import React, { useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Context } from "../helpers/Context";
import { getCurrentDomain } from "../helpers/Lookup";
import FixedMessenger from "./FixedMessenger";

const Categories = () => {
    const navigate = useNavigate();
    const { getCategories, categories, getSubcategories, subcategories, setSelectedBrands } = useContext(Context);
    const goToCategory = (data) => {
        navigate(data);
    };
    useEffect(() => {
        document.title = "Categories :: TriggerCraft";
        getCategories();
        getSubcategories();
    }, [])
    const categoriesWithSubcategories = categories.filter((category) =>
        subcategories.some((sub) => sub.categoryId === category.id)
    );
    //console.log(categories, subcategories, categoriesWithSubcategories)
    return (
        <div>
            <nav className="mb-3 crumbs">
                <Link to="/" className="breadcrumb-active"><small>Home</small>
                </Link>
                <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
                <small className="breadcrumb-not-active">Categories</small>
            </nav>
            <h1 className="mb-4">Categories</h1>
            <hr className="bg-success" />
            <div className="d-flex flex-wrap justify-content-evenly gap-5 mb-3">
                {categoriesWithSubcategories.map((category) => (
                    <div
                        className="col-md-6 col-lg-3 d-flex flex-column align-items-center mb-3 card category-container"
                        key={category.id}
                    >
                        <div className="card-header w-100 text-center" style={{ cursor: "pointer" }} onClick={() => { goToCategory(`/products/category/${category.title.trim().replace(" ", "_").toLowerCase()}`); localStorage.removeItem('selectedBrands1'); setSelectedBrands([]); }}>
                        <img
                            src={category.categoryBanners ? getCurrentDomain() + category.categoryBanners : "/android-chrome-192x192.png"}
                            alt={category.title}
                            className="category-image mb-3"
                        />
                            <div className="category-title">{category.title}</div>
                        </div>
                        <div className="subcategories card-body">
                            {subcategories
                                .filter((sub) => sub.categoryId === category.id)
                                .map((sub) => (
                                    <div
                                        className="subcategory-title"
                                        onClick={() => { goToCategory(`/products/category/${sub.slug}`); localStorage.removeItem('selectedBrands1'); setSelectedBrands([]); }}
                                        key={sub.id}
                                    >
                                        {sub.title}
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
            <FixedMessenger />
        </div>
    );
};

export default Categories;