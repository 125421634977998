import React, { Component } from 'react';
import jsonp from 'jsonp';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import GreptchaHelper from '../../helpers/GreptchaHelper';
import { fieldRequired } from '../../helpers/validation';

export class BrandFooter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
            subSubscribeEmail: "",
            CaptchaToken: false
        };
        //this.toggleNavbar = this.toggleNavbar.bind(this);
        this.handleSubscribe = this.handleSubscribe.bind(this);
        this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
    }

    toggleNavbar() {
        //this.setState({
        //  collapsed: !this.state.collapsed
        //});
    }

    handleSubscribe() {
        const url = 'https://triggercraft.us18.list-manage.com/subscribe/post-json?u=6c3c5d86e0bcce73964102b87&amp;id=9a9eac6a55&amp;f_id=009d0de7f0'

        let IsValid = true;

        IsValid &= fieldRequired(this.state.subSubscribeEmail, "errSubscribeEmail", "* Required");
        IsValid &= fieldRequired(this.state.CaptchaToken, "errSubscribeCaptcha", "* Required");

        if (IsValid) {
            const errorCallback = () => {
                ToastUtility.show({
                    title: 'Newsletter',
                    content: 'An error occurred while subscribing to the newsletter.',
                    timeOut: 5000,
                    position: { X: 'Right', Y: 'Top' },
                    showCloseButton: true,
                    cssClass: 'toast-danger'
                });
                this.setState({ subSubscribeEmail: "" })
            };

            jsonp(`${url}&EMAIL=${this.state.subSubscribeEmail}`, { param: 'c' }, (_, { msg, result }) => {
                if (result !== 'success') {
                    errorCallback();
                    this.setState({ subSubscribeEmail: "" })
                } else {
                    ToastUtility.show({
                        title: 'Newsletter',
                        content: msg,
                        timeOut: 5000,
                        position: { X: 'Right', Y: 'Top' },
                        showCloseButton: true,
                        cssClass: 'toast-success'
                    });
                    this.setState({ subSubscribeEmail: "" })
                }
            });
        }
    };


    handleCaptchaChange(CaptchaToken) {
        this.setState({ CaptchaToken }, () => fieldRequired(this.state.CaptchaToken, "errSubscribeCaptcha", "* Required"));
    }
    render() {
        var currentYear = new Date().getFullYear();

        return (
            <footer style={{backgroundColor: "#000", borderTop: "2px solid #bbb"} }>
                <div className="container">
                    <div className="row">
                        <div className="col-12 pb-3 text-center brand-social-icons">

                            <a className="fab fa-facebook" target="_blank" href="https://www.facebook.com/RazerSouthAfrica/"></a>
                            <a className="fab px-1" target="_blank" href="https://twitter.com/Razer?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"><svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg></a>
                            {/*<a className="fab fa-whatsapp mobile" target="_blank" href="whatsapp://send?phone=919876543210"></a>*/}
                            {/*<a className="fab fa-whatsapp desktop" target="_blank" href="https://web.whatsapp.com/send?phone=919876543210"></a>*/}
                            <a className="fab fa-linkedin" target="_blank" href="https://www.linkedin.com/company/razer"></a>
                            <a className="fab fa-instagram" target="_blank" href="https://instagram.com/razer/?hl=en"></a>
                        </div>
                        <div className="col-md-4 text-light">
                            <h3 className="brand-heading">About</h3>
                            <p>Powered by TriggerCraft.</p>
                            <div className=" mt-auto">
                                &copy; {currentYear} TriggerCraft. All Rights Reserved.
                            </div>
                        </div>
                        <div className="col-md-4 text-light">
                            <h3 className="brand-heading">Newsletter</h3>
                            <p>Subscribe to the Razer South Africa Newsletter to get special offers and once-in-a-lifetime deals but also the latest news and updates.</p>

                            <div className=' mb-3'>
                                <div className="input-group">
                                    <input type="text" className="form-control" value={this.state.subSubscribeEmail} placeholder="Enter email address..." onChange={e => this.setState({ subSubscribeEmail: e.target.value }, () => fieldRequired(this.state.subSubscribeEmail, "errSubscribeEmail", "* Required"))} aria-describedby="basic-addon" />
                                    <span className="input-group-text bg-dark text-light" style={{ cursor: "pointer" }} onClick={() => this.handleSubscribe()} id="basic-addon">SUBSCRIBE</span>
                                </div>
                                <div id="errSubscribeEmail" className="ErrorText"></div>
                            </div>
                            <div className='mb-3'>
                                <GreptchaHelper HandleChangeMethod={this.handleCaptchaChange} CaptchaToken={this.state.CaptchaToken} />
                                <div id="errSubscribeCaptcha" className="ErrorText"></div>
                            </div>
                        </div>
                        <div className="col-md-4 text-light">
                            <h3 className="brand-heading">Contact Us</h3>
                            <p>Support Hotline: <a target="_blank" href="tel:+27 10 220 5737" style={{ textDecoration: 'none' }} >+27 10 220 5737</a> <br />Email: <a href="mailto:support@triggercraft.co.za" style={{ textDecoration: 'none' }}>support@triggercraft.co.za</a></p>
                            {/*<p>Email support@triggercraft.co.za</p>*/}
                            <p>Stand 359, Olympic Duel Avenue, Northlands Business Park, 29, New Market, Road, Northriding, Randburg, 2162</p>
                        </div>


                    </div>
                </div>
            </footer>
        );
    }
}
