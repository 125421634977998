import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Footer } from './nav/Footer';
import ScrollToTopButton from './ScrollToTopButton';
import FixedMessenger from './FixedMessenger';
import { BrandNavMenu } from './BrandNavMenu';
import { BrandFooter } from './nav/BrandFooter';

export class BrandLayout extends Component {
    static displayName = BrandLayout.name;

    render() {
        return (
            <div>
                <BrandNavMenu />
                <Container>
                    {this.props.children}
                </Container>
                <ScrollToTopButton />
                {/*<FixedMessenger />*/}
                <BrandFooter />
            </div>
        );
    }
}
